import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';

import { OpenQuestion } from './OpenQuestion';

type SurveyCommentsProps = {
  comments: string;
  onClickSend: (givenComment: string) => void;
};

const keyPrefix = 'components.incentives.SurveyComments';

export const SurveyComments: FC<SurveyCommentsProps> = ({
  comments,
  onClickSend,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  const [givenComment, setGivenComment] = useState<string>('');
  const [canSend, setCanSend] = useState<boolean>(false);

  const onChangeGivenComment = (newValue: string) => {
    setCanSend(!!newValue);
    setGivenComment(newValue);
  };

  const handleClick = () => {
    onClickSend(givenComment);
  };

  return (
    <Stack spacing={1}>
      <Typography variant='body1' pt={2} pb={4}>
        {comments}
      </Typography>

      <Stack>
        <OpenQuestion onChange={onChangeGivenComment} />
        <Box width='370px' margin='32px auto'>
          <Button
            variant='contained'
            fullWidth
            disabled={!canSend}
            onClick={handleClick}
            sx={{
              '&.Mui-disabled': {
                color: 'white',
                backgroundColor: '#ec342f',
                opacity: 0.4,
              },
            }}
          >
            {t('send')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
