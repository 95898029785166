import React, { FC } from 'react';

type IconLikeSvgProps = {
  on: boolean;
};

export const IconLikeSvg: FC<
  React.SVGProps<SVGSVGElement> & IconLikeSvgProps
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 34' {...props}>
      <g data-name='icon_me gusta_off'>
        <g data-name='Grupo 112'>
          <path fill='none' d='M0 0h34v34H0z' data-name='Rect\xE1ngulo 71' />
        </g>
        <g data-name='Grupo 116'>
          <g data-name='Grupo 114'>
            <g data-name='Grupo 113'>
              <path
                fill={props.on ? '#2789ec' : '#455d7a'}
                d='M9.698 27.584H7.061a2.523 2.523 0 01-2.522-2.521V14.747a2.526 2.526 0 012.522-2.524h2.637a2.526 2.526 0 012.522 2.524v10.316a2.523 2.523 0 01-2.522 2.521zM7.061 14.316a.429.429 0 00-.427.43v10.316a.427.427 0 00.427.427h2.637a.427.427 0 00.427-.427V14.746a.429.429 0 00-.427-.43z'
                data-name='Trazado 47'
              />
            </g>
          </g>
          <g data-name='Grupo 115'>
            <path
              fill={props.on ? '#0d51cb' : '#455d7a'}
              d='M23.517 27.582h-8.782a3.574 3.574 0 01-2.609-1.135l-1.721-1.852a1.047 1.047 0 01.053-1.479 1.043 1.043 0 011.48.054l1.722 1.85a1.466 1.466 0 001.074.468h8.782a1.473 1.473 0 001.409-1.06l1.9-6.594a1.463 1.463 0 00-.191-1.223l-.458-.687a1.465 1.465 0 00-1.219-.648h-4.324a1.047 1.047 0 01-1.047-1.047V9.661a1.461 1.461 0 00-.332-.927 1.289 1.289 0 00-1.085-.5 1.366 1.366 0 00-1.067.536l-5.1 6.636a1.048 1.048 0 01-1.469.192 1.048 1.048 0 01-.193-1.469l5.1-6.636a3.447 3.447 0 012.691-1.354 3.581 3.581 0 012.737 1.264 3.559 3.559 0 01.811 2.26v3.521h3.276a3.553 3.553 0 012.959 1.579l.46.687a3.555 3.555 0 01.462 2.968l-1.9 6.592a3.575 3.575 0 01-3.419 2.572z'
              data-name='Trazado 48'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
