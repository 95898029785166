import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardMedia, Container, Stack, Typography } from '@mui/material';

import { FullModalLayout } from '../../layouts';
import { Incentive } from '../../types';
import { getMediaImage, TriviaResponseCard } from '../../components';

type TriviaResultsModalProps = {
  trivia: Incentive;
  givenAnswers: number[];
  onClickOk: () => void;
  onCalculateScore: (score: number) => void;
};

const keyPrefix = 'modals.TriviaResultsModal';

export const TriviaResultsModal: FC<TriviaResultsModalProps> = ({
  trivia,
  givenAnswers,
  onClickOk,
  onCalculateScore,
}): JSX.Element => {
  const [hits, setHits] = useState<number>(0);

  const { backCoverDescription, backCoverTextButton, media, questions } =
    trivia;
  const coverImg = getMediaImage(media!, 2);

  const noop = () => {};

  const calculateHits = useCallback(() => {
    var newHits = givenAnswers.reduce(
      (accumulator, selectedAnswerId, questionId) => {
        const { correct } = questions![questionId].answers[selectedAnswerId];
        return correct ? accumulator + 1 : accumulator;
      },
      0
    );

    setHits(newHits);
    onCalculateScore(newHits);
    // eslint-disable-next-line
  }, [givenAnswers, questions]);

  useEffect(calculateHits, [calculateHits]);

  return (
    <FullModalLayout onClose={noop}>
      <Container maxWidth='lg'>
        <Stack direction='row' width='100%' spacing={5} alignItems='flex-start'>
          <TriviaScore
            coverImg={coverImg}
            backCoverDescription={backCoverDescription}
            hits={hits}
            total={questions!.length}
          />
          <TriviaResponseCard
            backCoverTextButton={backCoverTextButton}
            questions={questions!}
            givenAnswers={givenAnswers}
            onClickOk={onClickOk}
          />
        </Stack>
      </Container>
    </FullModalLayout>
  );
};

type TriviaScoreProps = {
  backCoverDescription?: string;
  coverImg: string | null;
  hits: number;
  total: number;
};

const TriviaScore: FC<TriviaScoreProps> = ({
  backCoverDescription,
  coverImg,
  hits,
  total,
}): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.TriviaScoreProps`,
  });
  return (
    <Stack
      width={372}
      justifyContent='center'
      flexDirection='column'
      rowGap={2}
    >
      <Typography variant='body1' textAlign='center'>
        {t('got')}
      </Typography>
      <Typography variant='h4' textAlign='center' fontWeight={800}>
        {`${hits}/${total}`}
      </Typography>
      <Typography variant='body1' textAlign='center'>
        {backCoverDescription}
      </Typography>

      {!!coverImg && (
        <CardMedia
          component='img'
          image={coverImg}
          sx={{
            borderRadius: '12px !important',
            cursor: 'pointer',
            maxWidth: '328px',
            margin: '0 auto',
          }}
        />
      )}
      {/* <Box width='370px' margin='0 auto' pt={3}>
        <Button
          variant='outlined'
          color='secondary'
          onClick={onClickQuit}
          fullWidth
        >
          {t('cancel')}
        </Button>
      </Box> */}
    </Stack>
  );
};
