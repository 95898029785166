import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { ModalLayout } from '../layouts';
import { Stack } from '@mui/system';

type MessageModalProps = {
  message: string;
  okLabel: string;
  showOkIcon?: boolean;
  title?: string | null;
  onClickOk: () => void;
  onClose?: () => void;
};

export const MessageModal: FC<MessageModalProps> = ({
  message,
  okLabel,
  showOkIcon = false,
  title,
  onClose,
  onClickOk,
}): JSX.Element => {
  return (
    <ModalLayout onClose={onClose} keepOpened>
      <Stack
        display='grid'
        direction='row'
        minHeight='150px'
        sx={{ gridTemplateRows: '1fr 60px' }}
        zIndex={90000}
      >
        {showOkIcon && (
          <CheckCircleOutlineIcon
            color='success'
            sx={{ fontSize: '54px', marginBottom: 4 }}
          />
        )}
        {title && (
          <Typography variant='h5' fontSize='28px' pb={1}>
            {title}
          </Typography>
        )}
        <Typography fontSize='26px'>{message}</Typography>
        <Box
          display='flex'
          justifyContent='center'
          // justifyItems='flex-end'
          // alignItems='flex-ends'
          alignSelf='end'
          mt={4}
        >
          <Button
            variant='contained'
            onClick={onClickOk}
            sx={{ minWidth: 275 }}
          >
            {okLabel}
          </Button>
        </Box>
      </Stack>
    </ModalLayout>
  );
};
