import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Content,
  FavoritesApiResponse,
  PromosApiResponse,
  Reaction,
  ReactionsApiResponse,
} from '../../types';
import { favoritesApi, promosApi, reactionsApi } from '../services';
import { RootState } from '../store';

export interface promosState {
  contents: Content[];
  favorites: Content[];
  reactions: Reaction[];
}

const initialState: promosState = {
  contents: [],
  favorites: [],
  reactions: [],
};

export const promosSlice = createSlice({
  name: 'promos',
  initialState,
  reducers: {
    addOrChangeReaction: (state, action: PayloadAction<Reaction>) => {
      const newReaction = action.payload;
      const actualReactions = [...state.reactions];

      if (newReaction.reactionType === null) {
        const filteredActualReactions = actualReactions.filter(
          ({ idContent }) => idContent !== newReaction.idContent
        );

        state.reactions = [...filteredActualReactions];
        return;
      }

      let isNewReaction = true;
      for (let i = 0; i < actualReactions.length; i++) {
        const { idContent } = actualReactions[i];
        if (idContent === newReaction.idContent) {
          actualReactions[i] = newReaction;
          isNewReaction = false;
          break;
        }
      }

      isNewReaction && actualReactions.push(newReaction);

      state.reactions = actualReactions;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        promosApi.endpoints.getPromos.matchFulfilled,
        (state, action: PayloadAction<PromosApiResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 200) {
            const { payload } = action;

            // console.log('payload LENGTH', payload.contents.length);

            state.contents = payload.contents;
          }
        }
      )
      .addMatcher(
        favoritesApi.endpoints.getFavorites.matchFulfilled,
        (state, action: PayloadAction<FavoritesApiResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 200) {
            const { payload } = action;
            const newFavorites: Content[] = [];
            const actualContent = [...state.contents];

            // console.log('PROMO SLICE actualContent', actualContent.length);

            payload.favorites.forEach(({ id }) => {
              const favoriteContent = actualContent.find(
                ({ id: contentId }) => contentId === id
              );
              !!favoriteContent && newFavorites.push(favoriteContent);
            });

            state.favorites = [...newFavorites];
            // state.contents = [...actualContent];
          }
        }
      )
      .addMatcher(
        favoritesApi.endpoints.addFavorite.matchFulfilled,
        (state, action: PayloadAction<FavoritesApiResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 200) {
            const { payload } = action;
            const newFavorites: Content[] = [];
            const actualContent = [...state.contents];

            payload.favorites.forEach(({ id }) => {
              const favoriteContent = actualContent.find(
                ({ id: contentId }) => contentId === id
              );
              !!favoriteContent && newFavorites.push(favoriteContent);
            });

            state.favorites = [...newFavorites];
          }
        }
      )
      .addMatcher(
        favoritesApi.endpoints.removeFavorite.matchFulfilled,
        (state, action: PayloadAction<FavoritesApiResponse>) => {
          const { responseCode } = action.payload.result;
          if (responseCode === 200) {
            const { payload } = action;
            const newFavorites: Content[] = [];
            const actualContent = [...state.contents];

            payload.favorites.forEach(({ id }) => {
              const favoriteContent = actualContent.find(
                ({ id: contentId }) => contentId === id
              );
              !!favoriteContent && newFavorites.push(favoriteContent);
            });

            state.favorites = [...newFavorites];
          }
        }
      )
      .addMatcher(
        reactionsApi.endpoints.getReactionList.matchFulfilled,
        (state, action: PayloadAction<ReactionsApiResponse>) => {
          const {
            result: { responseCode },
            reactions,
          } = action.payload;
          if (responseCode === 200) {
            state.reactions = reactions || [];
          }
        }
      )
      .addMatcher(
        favoritesApi.endpoints.removeFavorite.matchFulfilled,
        (state, action: PayloadAction<FavoritesApiResponse>) => {
          // const { responseCode } = action.payload.result;

          console.log('action.payload.result', action.payload.result);

          // if (responseCode === 200) {
          //   const { payload } = action;
          //   const newFavorites: Content[] = [];
          //   const actualContent = [...state.contents];

          //   payload.favorites.forEach(({ id }) => {
          //     const favoriteContent = state.contents.find(
          //       ({ id: contentId }) => contentId === id
          //     );
          //     !!favoriteContent && newFavorites.push(favoriteContent);
          //   });

          //   state.favorites = [...newFavorites];
          //   state.contents = [...actualContent];
          // }
        }
      );
  },
});

export const promosReducer = promosSlice.reducer;

export const { addOrChangeReaction } = promosSlice.actions;

export const selectContents = (state: RootState) => state.promos.contents;
export const selectFavorites = (state: RootState) => state.promos.favorites;
export const selectReactions = (state: RootState) => state.promos.reactions;
