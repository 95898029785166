import { FC } from 'react';
import { useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';

import { Menu } from '../components';
import { useLoadPropertiesAndPromos, useRequestContent } from '../hooks';
import { selectActivePage } from '../redux';
import {
  AboutPage,
  FAQPage,
  ReportPage,
  SuggestionsPage,
  TCPage,
} from '../pages';

export const AnonymousLayout: FC = (): JSX.Element => {
  // const { loading } = useLoadPropertiesAndPromos();
  useLoadPropertiesAndPromos();
  useRequestContent();
  const { activePage } = useSelector(selectActivePage);

  return (
    <Div100vh>
      {/* {loading && <BackdropLoading />} */}
      <Menu isAnonymous />
      {
        {
          //Help section
          about: <AboutPage />,
          faq: <FAQPage />,
          report: <ReportPage />,
          suggest: <SuggestionsPage />,
          tc: <TCPage />,
        }[activePage]
      }
    </Div100vh>
  );
};
