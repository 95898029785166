import { Box, Stack } from '@mui/material';
import { FC } from 'react';

type IndexIndicatorProps = {
  total: number;
  idx: number;
};

export const IndexIndicator: FC<IndexIndicatorProps> = ({
  total,
  idx,
}): JSX.Element => {
  return (
    <Stack direction='row' gap={1}>
      {new Array(total).fill(null).map((v, i) => (
        <Indicator key={i} filled={i <= idx} />
      ))}
    </Stack>
  );
};

type IndicatorProps = {
  filled: boolean;
};

const Indicator: FC<IndicatorProps> = ({ filled = false }): JSX.Element => {
  return (
    <Box
      sx={{
        borderRadius: 50,
        width: filled ? '12px' : '14px',
        height: filled ? '12px' : '14px',
        backgroundColor: filled ? 'cyan' : 'white',
        boxShadow: `0 2px 4px rgba(0, 0, 0, 0.${filled ? 2 : 4})`,
        border: '1px solid cyan',
      }}
    ></Box>
  );
};
