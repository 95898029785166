import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';

import { selectActivePage } from '../../redux';
import { AnonymousHelpModal } from '../../modals';
import { useNavigate } from 'react-router-dom';

const keyPrefix = 'components.AnonymousMenuItems';

export const AnonymousMenuItems: FC = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const navigation = useNavigate();
  const { searchingContent, activePage } = useSelector(selectActivePage);
  const [showHelpMenuModal, setShowHelpMenuModal] = useState<boolean>(false);

  const [value, setValue] = useState(() => {
    switch (activePage) {
      case 'login':
        return 1;
      case 'about':
      case 'faq':
      case 'report':
      case 'suggest':
      case 'tc':
        return 2;
      default:
        return 0;
    }
  });

  const toggleShowHelpMenuModal = () => {
    setShowHelpMenuModal(value => !value);
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const showHomePage = (): void => {
    navigation('/login');
  };

  const goRegister = () => {
    navigation('/signUp');
  };

  return (
    <Box width='600px' display='flex' justifyContent='flex-end'>
      {showHelpMenuModal && (
        <AnonymousHelpModal onClose={toggleShowHelpMenuModal} />
      )}
      <Tabs
        value={value}
        onChange={handleChange}
        textColor='secondary'
        indicatorColor='secondary'
        sx={{ display: !!searchingContent ? 'none' : 'block' }}
      >
        <Tab label={t('items.login.label')} onClick={showHomePage} />
        <Tab label={t('items.signUp.label')} onClick={goRegister} />
        <Tab label={t('items.help.label')} onClick={toggleShowHelpMenuModal} />
      </Tabs>
      {/* <DropdownMenuProfile
        anchorRef={profileAnchorRef}
        open={openProfile}
        setOpen={setOpenProfile}
      /> */}
    </Box>
  );
};
