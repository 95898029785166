import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { Session } from '../../types';
import { getAppInfo } from './appInfo';

export const incentivesApi = createApi({
  reducerPath: 'incentivesApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getIncentives: builder.mutation({
      query: ({ sessionToken, userId }: Session) => {
        return {
          url: 'v1/incentives',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
          },
        };
      },
    }),
  }),
});

export const { useGetIncentivesMutation } = incentivesApi;
