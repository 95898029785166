import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { HelpParams } from '../../types';
import { getAppInfo } from './appInfo';

export const helpApi = createApi({
  reducerPath: 'helpApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    reportIssue: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        comments,
        email,
        categoryDescription,
      }: HelpParams) => {
        return {
          url: 'v1/help',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            categoryDescription,
            comments,
            email,
            type: 1,
          },
        };
      },
    }),
    makeSuggestion: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        comments,
        email,
      }: HelpParams) => {
        return {
          url: 'v1/help',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            comments,
            email,
            type: 2,
          },
        };
      },
    }),
  }),
});

export const { useReportIssueMutation, useMakeSuggestionMutation } = helpApi;
