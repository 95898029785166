import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Popover, Stack } from '@mui/material';
import { orange, grey, lightBlue, purple } from '@mui/material/colors';

import {
  IconLikeSvg,
  IconRecommendSvg,
  IconRestoreSvg,
  IconUtilSvg,
} from '../../assets';
import { ReactionType } from '../../enums';
import {
  addOrChangeReaction,
  selectReactions,
  selectSession,
  useAddOrChangeReactionMutation,
  useRestoreReactionMutation,
} from '../../redux';

const keyPrefix = 'components.content.ReactionButton';

type ReactionButtonProps = {
  justifyContent?: 'flex-start' | 'ceneter';
  idContent: number;
};

export const ReactionButton: FC<ReactionButtonProps> = ({
  justifyContent = 'center',
  idContent,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reactionSelected, setReactionSelected] = useState<ReactionType | null>(
    null
  );

  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const reactions = useSelector(selectReactions);

  const [
    addOrChange,
    { isError: isErrAoC, isLoading: loadingAoC, error: errAoC, data: dataAoC },
  ] = useAddOrChangeReactionMutation();

  const [
    restore,
    {
      isError: isErrRestore,
      isLoading: loadingRestore,
      error: errRestore,
      data: dataRestore,
    },
  ] = useRestoreReactionMutation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectReaction = (selectedReaction: ReactionType | null) => {
    selectedReaction === null
      ? restore({ session, idContent })
      : addOrChange({ session, idContent, reactionType: selectedReaction });

    setReactionSelected(selectedReaction);
    dispatch(
      addOrChangeReaction({
        date: new Date().toISOString(),
        idContent,
        reactionType: selectedReaction,
      })
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    for (let i = 0; i < reactions.length; i++) {
      const { idContent: reactionId, reactionType } = reactions[i];
      if (reactionId === idContent) {
        setReactionSelected(reactionType);
        break;
      }
    }
  }, [reactions]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        color='inherit'
        sx={{
          minWidth: '238px',
          textTransform: 'none',
          fontSize: '20px',
          color: getTextColor(reactionSelected),
          fontWeight: !!reactionSelected ? 'bold' : 'normal',
          justifyContent,
        }}
        onClick={handleClick}
        startIcon={<ReactionIcon reaction={reactionSelected} />}
      >
        <Box sx={{ paddingTop: '6px' }}>{t(`label.${reactionSelected}`)}</Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{
          sx: { borderRadius: 30, minWidth: 282, padding: '16px 28px' },
        }}
      >
        <Stack direction='row' justifyContent='space-between'>
          <IconLikeSvg
            on={true}
            width={32}
            height={32}
            cursor='pointer'
            onClick={() => selectReaction(ReactionType.LIKE)}
          />
          <IconUtilSvg
            width={32}
            height={32}
            cursor='pointer'
            onClick={() => selectReaction(ReactionType.UTIL)}
          />
          <IconRecommendSvg
            width={32}
            height={32}
            cursor='pointer'
            onClick={() => selectReaction(ReactionType.RECOMMENDED)}
          />
          <IconRestoreSvg
            width={32}
            height={32}
            cursor='pointer'
            onClick={() => selectReaction(null)}
          />
        </Stack>
      </Popover>
    </>
  );
};

const getTextColor = (reaction: ReactionType | null): string => {
  switch (reaction) {
    case ReactionType.LIKE:
      return lightBlue[700];
    case ReactionType.UTIL:
      return orange[600];
    case ReactionType.RECOMMENDED:
      return purple[800];
    default:
      return grey[600];
  }
};

type ReactionIconProps = {
  reaction: ReactionType | null;
};

const ReactionIcon: FC<ReactionIconProps> = ({ reaction }) => {
  switch (reaction) {
    case ReactionType.LIKE:
      return <IconLikeSvg on={true} width={34} height={34} />;
    case ReactionType.UTIL:
      return <IconUtilSvg width={34} height={34} />;
    case ReactionType.RECOMMENDED:
      return <IconRecommendSvg width={34} height={34} />;
    default:
      return <IconLikeSvg on={false} width={34} height={34} />;
  }
};
