import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_BASE_URL } from "../../config";

export const propertiesApi = createApi({
  reducerPath: "propertiesApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: (url) => {
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useLazyGetPropertiesQuery, useGetPropertiesQuery } =
  propertiesApi;
