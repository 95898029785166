import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeActiveContent,
  changeActiveMenuContent,
  // selectContents,
  selectSession,
  useGetPromosMutation,
} from '../redux';
import { useProducts } from './useProducts';
import { Content, PromosApiResponse } from '../types';
import { MediaType } from '../enums';
import { useContentBadges } from './useContentBadges';

export const useRequestContent = () => {
  // const contents = useSelector(selectContents);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const productList = useProducts();
  const { removeBadges } = useContentBadges();
  const session = useSelector(selectSession);

  const [availability, setAvailability] = useState<boolean | null>(null);
  const [needToWait, setNeedToWait] = useState<boolean>(false);

  const [getPromos] = useGetPromosMutation();


  const logic = async () => {
    const { search } = location;

    if (!search) return;

    const query = new URLSearchParams(search);
    const notificationTag = query.get('nt');

    if (notificationTag === 'user_visible_auto_notification') {
      navigate('/');
      return;
    }

    const [contentType, subContentType, contentId] = notificationTag?.split(
      '-'
    ) || [100, 100, 100];

    setNeedToWait(true);
    const res = (await getPromos(session)) as { data: PromosApiResponse };
    const contents = res.data.contents;

    const selectedContent: Content = contents.filter(
      ({ contentType: ct, id }) =>
        ct.toString() === contentType.toString() &&
        id.toString() === contentId.toString()
    )[0];

    //  console.log('selectedContent', selectedContent)

    if (!!selectedContent) {
      setAvailability(true);

      const activePage =
        selectedContent.mediaType === MediaType.VIDEO ? 'video' : 'magazine';

      if (
        contentType === '100' ||
        contentType === '101' ||
        productList.length === 0
      ) {
        dispatch(changeActiveContent({ activePage, selectedContent }));
      } else {
        productList.forEach(({ id, sons }) => {
          if (id.toString() === contentType.toString()) {
            sons.forEach(son => {
              // console.log('son.id', son.id)
              // console.log('subContentType', subContentType)
              if (son.id.toString() === subContentType.toString()) {
                // console.log('got it son ->', son)
                dispatch(
                  changeActiveMenuContent({
                    activePage,
                    selectedMenuItem: son,
                    selectedContent,
                  })
                );
                return;
              }
            });
            return;
          }
        });
      }
    } else {
      removeBadges(parseInt(contentId.toString()));
      setAvailability(false);
    }

    setNeedToWait(false);
    navigate('/');

  }

  useEffect(() => {
    logic();
    // eslint-disable-next-line
  }, [location, productList, setNeedToWait]);

  const restoreAvailability = () => {
    // console.log('Restoring availability');
    setAvailability(null);
  };

  return { availability, needToWait, restoreAvailability };
};
