import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAppInfo } from './appInfo';
import { API_BASE_URL } from '../../config';
import {
  ReportActivityParams,
  ReportContentDownloadedParams,
  ReportContentWatchedParams,
  ReportIncentiveParams,
} from '../../types';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    reportActivity: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        activity,
      }: ReportActivityParams) => {
        return {
          url: 'v5/activity',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            activity,
          },
        };
      },
    }),
    reportContentDownloaded: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        contentId,
      }: ReportContentDownloadedParams) => {
        return {
          url: 'v1/report/content/download',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            contentId,
          },
        };
      },
    }),
    reportContentWatched: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        contentId,
      }: ReportContentWatchedParams) => {
        return {
          url: 'v4/watched',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            contentId,
          },
        };
      },
    }),
    reportIncentive: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        incentive,
      }: ReportIncentiveParams) => {
        return {
          url: 'v1/report/incentives',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            incentive,
          },
        };
      },
    }),
  }),
});

export const {
  useReportActivityMutation,
  useReportContentDownloadedMutation,
  useReportContentWatchedMutation,
  useReportIncentiveMutation,
} = reportsApi;
