import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PublicationCoverModal } from '../../../modals';
import { CancelIncentiveConfirmationModal } from '../../../modals/incentives/CancelIncentiveConfirmationModal';
import { parsePublicationIncentive } from '../../../redux';
import { Incentive } from '../../../types';

type PublicationViewerProps = {
  publication: Incentive;
  onClickQuit: () => void;
  onFinishPublication: () => void;
};

export const PublicationViewer: FC<PublicationViewerProps> = ({
  publication,
  onClickQuit,
  onFinishPublication,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [showPublicity, setShowPublicity] = useState<boolean>(true);
  const [showCancelConfirmationIncentive, setShowCancelConfirmationIncentive] =
    useState<boolean>(false);

  const finishPublicity = async () => {
    dispatch(parsePublicationIncentive());
    onFinishPublication();
  };

  const clickQuit = () => {
    setShowPublicity(false);
    setShowCancelConfirmationIncentive(true);
  };

  const continueIncentive = () => {
    setShowPublicity(true);
    setShowCancelConfirmationIncentive(false);
  };

  const handleLeaveIncentiveConfirmationModal = () => {
    setShowPublicity(false);
    setShowCancelConfirmationIncentive(false);
    onClickQuit();
  };

  return (
    <>
      {showPublicity && (
        <PublicationCoverModal
          publication={publication}
          onClickQuit={clickQuit}
          onFinishPublication={finishPublicity}
        />
      )}

      {showCancelConfirmationIncentive && (
        <CancelIncentiveConfirmationModal
          onClickNo={continueIncentive}
          onClickYes={handleLeaveIncentiveConfirmationModal}
        />
      )}
    </>
  );
};
