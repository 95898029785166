import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClickAwayListener,
  MenuList,
  MenuItem as MUIMenuItem,
  Paper,
  Grow,
  Popper,
  Typography,
  Chip,
  Box,
  Badge,
} from '@mui/material';
import { Stack } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { useContentBadges, useProducts } from '../../hooks';
import { MenuItem } from '../../types';
import {
  changeActiveMenuItem,
  selectContentTypeBadges,
  selectSubContentTypeBadges,
} from '../../redux';

type DropdownMenuContentProps = {
  anchorRef: RefObject<HTMLAnchorElement>;
  open: boolean;
  setOpen(open: boolean): void;
};

export const DropdownMenuContent: FC<DropdownMenuContentProps> = ({
  anchorRef,
  open,
  setOpen,
}): JSX.Element => {
  const productList = useProducts();
  const [itemList, setItemList] = useState<MenuItem[]>([]);
  const [selectedItemLabel, setSelectedItemLabel] = useState<string | null>(
    null
  );

  const { updateBadges } = useContentBadges();
  const dispatch = useDispatch();
  const contentTypesBadges = useSelector(selectContentTypeBadges);
  const subContentTypesBadges = useSelector(selectSubContentTypeBadges);

  const handleSelectItem = (selectedItem: MenuItem) => {
    const { label, sons } = selectedItem;

    if (sons.length > 0) {
      setItemList(sons);
      setSelectedItemLabel(label);
      return;
    }

    setItemList(productList);
    setSelectedItemLabel(null);
    setOpen(false);
    dispatch(
      changeActiveMenuItem({
        activePage: 'products',
        selectedMenuItem: selectedItem,
      })
    );
  };

  const handleMenuBack = () => {
    setItemList(productList);
    setSelectedItemLabel(null);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;
    setItemList(productList);
    setSelectedItemLabel(null);
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    updateBadges();
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (itemList.length === 0) {
      setItemList(productList);
    } else {
      productList.length === itemList.length && setItemList(productList);
    }
    // eslint-disable-next-line
  }, [productList]);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      sx={{ zIndex: 1 }}
      placement='bottom-start'
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper
            sx={{
              minWidth: 339,
              padding: '0 6px',
              marginTop: 1,
              borderRadius: '11px',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                {!!selectedItemLabel && (
                  <MUIMenuItem onClick={handleMenuBack} sx={{ width: '100%' }}>
                    <Stack direction='row' alignItems='center' width='100%'>
                      <ArrowBackIcon
                        sx={{ fontSize: '1.3rem', marginRight: 2 }}
                      />
                      <Typography fontWeight='bold' fontSize={26}>
                        {selectedItemLabel}
                      </Typography>
                    </Stack>
                  </MUIMenuItem>
                )}
                {itemList.map(item => {
                  const { id, label } = item;
                  const contentBadge = contentTypesBadges[id.toString()];
                  const subContentBadge = subContentTypesBadges[id.toString()];

                  return (
                    <MUIMenuItem
                      key={id}
                      onClick={() => {
                        handleSelectItem(item);
                      }}
                      sx={{ width: '100%' }}
                    >
                      <Stack direction='row' alignItems='center' width='100%'>
                        <Box
                          marginLeft={'-32px'}
                          justifyContent={'space-between'}
                          paddingLeft={4}
                        >
                          {selectedItemLabel ? (
                            <Box marginRight='12px'>
                              {subContentBadge > 0 ? (
                                <Chip
                                  label={subContentBadge}
                                  color='error'
                                  sx={{
                                    width: '100%',
                                    height: '16px',
                                    fontSize: '12px',
                                    'span':{
                                      width: '31px',
                                      height: '18px'
                                    }
                                  }}
                                />
                              ) : (
                                <Box></Box>
                              )}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                //  marginBottom: '-6px',
                               marginRight: '12px' }}
                            >
                              <Badge
                                badgeContent={`+${contentBadge}`}
                                invisible={!contentBadge}
                                color='error'
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                  '.MuiBadge-badge': {
                                    minWidth: '15px',
                                    width: '15px',
                                    height: '15px',
                                    margin: '3px',
                                    fontSize: '0.5rem',
                                  },
                                }}
                              >
                                {contentBadge ? (
                                  <NotificationsNoneIcon
                                    height='18px'
                                    color='secondary'
                                  />
                                ) : (
                                  <Box minWidth='25px'></Box>
                                )}
                              </Badge>
                            </Box>
                          )}
                        </Box>
                        {label}
                        {!selectedItemLabel && (
                          <Box
                            width='100%'
                            display='flex'
                            justifyContent='flex-end'
                          >
                            <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
                          </Box>
                        )}
                      </Stack>
                    </MUIMenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
