import { useTranslation } from 'react-i18next';
import { ResetPasswordForm } from '../forms';
import { InitialPagesLayout } from '../layouts/InitialPagesLayout';

const keyPrefix = 'pages.ResetPasswordPage';

export const ResetPasswordPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <InitialPagesLayout title={t('title')} instructions={t('instructions')}>
      <ResetPasswordForm />
    </InitialPagesLayout>
  );
};
