import { FC } from 'react';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { ModalLayout } from '../../layouts';
import { Incentive } from '../../types';
import { useTranslation } from 'react-i18next';
import { getMediaImage } from '../../components';

type TriviaCoverModalProps = {
  trivia: Incentive;
  onClickYes: () => void;
  onClickQuit: () => void;
  onClose: () => void;
};

const keyPrefix = 'modals.TriviaCoverModal';

export const TriviaCoverModal: FC<TriviaCoverModalProps> = ({
  trivia,
  onClose,
  onClickQuit,
  onClickYes,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { coverTitle, coverDescription, coverTextButton, media } = trivia;

  const coverImg = getMediaImage(media!, 1);

  return (
    <ModalLayout onClose={onClose} keepOpened>
      <Stack justifyContent='center' mx='29px' rowGap={3}>
        <Typography variant='h3' textAlign='center'>
          {coverTitle}
        </Typography>
        <Typography variant='body1' textAlign='center' lineHeight='33px'>
          {coverDescription}
        </Typography>
        {!!coverImg && (
          <CardMedia
            component='img'
            image={coverImg}
            sx={{
              borderRadius: '12px !important',
              cursor: 'pointer',
              maxWidth: '328px',
              margin: '0 auto',
            }}
          />
        )}
        <Box width='370px' margin='0 auto'>
          <Button variant='contained' onClick={onClickYes} fullWidth>
            {coverTextButton}
          </Button>
        </Box>
        <Box width='370px' margin='0 auto'>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClickQuit}
            fullWidth
          >
            {t('cancel')}
          </Button>
        </Box>
      </Stack>
    </ModalLayout>
  );
};
