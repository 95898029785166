import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClickAwayListener,
  MenuList,
  MenuItem as MUIMenuItem,
  Paper,
  Grow,
  Popper,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import { MenuItem } from '../../types';
import { Stack } from '@mui/system';

import {
  changeActivePage,
  selectSession,
  useGetFavoritesMutation,
  useLogoutMutation,
  useRemoveFavoriteMutation,
} from '../../redux';
import { BackdropLoading } from '../basics';
import { YesNoQuestionModal } from '../../modals';
import { DownloadContentButton } from '../content';
import { Content } from '../../types';
import { IconTrashSvg } from '../../assets';
import { grey } from '@mui/material/colors';

type DropdownFavoriteMenuProps = {
  anchorRef: RefObject<HTMLAnchorElement>;
  content: Content;
  open: boolean;
  setOpen(open: boolean): void;
};

const keyPrefix = 'components.menu.DropdownFavoriteMenu';

export const DropdownFavoriteMenu: FC<DropdownFavoriteMenuProps> = ({
  anchorRef,
  content,
  open,
  setOpen,
}): JSX.Element => {
  const dispatch = useDispatch();
  const prevOpen = useRef(open);
  const session = useSelector(selectSession);
  const [remove, { isLoading: loadingRemove, data }] =
    useRemoveFavoriteMutation();
  const [getPromos, { isLoading: loadingPromos }] = useGetFavoritesMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const [downloadUrl, setDownloadUrl] = useState<string | null>(
    content!.urlPDF
  );
  const { t } = useTranslation('translation', { keyPrefix });

  const [showConfirmationLogoutModal, setShowConfirmationLogoutModal] =
    useState<boolean>(false);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // const goFavorites = (): void => {
  //   setOpen(false);
  //   dispatch(changeActivePage({ activePage: 'favorites' }));
  // };

  const removeFromFavorites = async () => {
    setOpen(false);
    await remove({ idContent: content.id, session });
    await getPromos(session);
  };

  // useEffect(() => {
  //   getPromos(session);
  // }, [data]);

  useEffect(() => {
    setLoading(loadingPromos || loadingRemove);
  }, [loadingPromos, loadingRemove]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      {/* {showConfirmationLogoutModal && (
        <YesNoQuestionModal
          message={t('modals.logout.message')}
          noLabel={t('modals.logout.no')}
          yesLabel={t('modals.logout.yes')}
          onClickYes={logout}
          onClickNo={() => {
            setShowConfirmationLogoutModal(false);
          }}
          onClose={() => {
            setShowConfirmationLogoutModal(false);
          }}
        />
      )} */}
      {loading && <BackdropLoading />}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{ zIndex: 1 }}
        placement='bottom-end'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                minWidth: 339,
                padding: '0 6px',
                marginTop: 1,
                borderRadius: '11px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  <MUIMenuItem
                    sx={{ width: '100%' }}
                    onClick={removeFromFavorites}
                  >
                    <ItemLabel text={t('delete')} />
                  </MUIMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const ItemLabel: FC<{ text: string }> = ({ text }) => {
  return (
    <Stack
      direction='row'
      // justifyContent='flex-start'
      alignItems='center'
      width='100%'
      paddingLeft={0}
    >
      <IconTrashSvg width={34} />
      <Typography variant='h6' color='#455d7a' fontWeight={600} ml={2}>
        {text}
      </Typography>
    </Stack>
  );
};
