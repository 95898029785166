import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import {
  AddOrChangeReactionsParams,
  RestoreReactionParams,
  Session,
} from '../../types';
import { getAppInfo } from './appInfo';

export const reactionsApi = createApi({
  reducerPath: 'reactionsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getReactionList: builder.mutation({
      query: ({ sessionToken, userId }: Session) => {
        return {
          url: 'v1/reactions',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            processType: 0,
          },
        };
      },
    }),
    addOrChangeReaction: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        idContent,
        reactionType,
      }: AddOrChangeReactionsParams) => {
        return {
          url: 'v1/reactions',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            idContent,
            processType: 1,
            reactionType,
          },
        };
      },
    }),
    restoreReaction: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        idContent,
      }: RestoreReactionParams) => {
        return {
          url: 'v1/reactions',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            idContent,
            reactionType: 0,
            processType: 2,
          },
        };
      },
    }),
  }),
});

export const {
  useGetReactionListMutation,
  useAddOrChangeReactionMutation,
  useRestoreReactionMutation,
} = reactionsApi;
