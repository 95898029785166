import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectProperties, useGetLocalsMutation } from '../../../redux';
import { useFormContext } from 'react-hook-form';

type ValueType = {
  value: string | number;
  label: string;
};

export const useSignUpSecondStepFormManagement = () => {
  const {
    channels,
    departments,
    districts,
    locals,
    modeSales,
    regions,
    provinces,
    sellerModes,
    sellerTypes,
  } = useSelector(selectProperties);

  const [geLocals] = useGetLocalsMutation();

  const [channelValues, setChannelValues] = useState<ValueType[]>([]);
  const [departmentValues, setDepartmentValues] = useState<ValueType[]>([]);
  const [districtValues, setDistrictValues] = useState<ValueType[]>([]);
  const [localsValues, setLocalsValues] = useState<ValueType[]>([]);
  const [modeSaleValues, setModeSaleValues] = useState<ValueType[]>([]);
  const [provinceValues, setProvinceValues] = useState<ValueType[]>([]);
  const [regionValues, setRegionValues] = useState<ValueType[]>([]);
  const [typeSellerValues, setTypeSellerValues] = useState<ValueType[]>([]);
  const [modeSellerValues, setModeSellerValues] = useState<ValueType[]>([]);

  const [selectedDistrictId, setSelectedDistrictId] = useState<string | number>(
    0
  );

  const { resetField } = useFormContext();

  // Clear Inputs methods
  const clearDeparmentInput = (values: ValueType[] = []) => {
    setDepartmentValues(values);
    resetField('department');
  };
  const clearProvinceInput = (values: ValueType[] = []) => {
    setProvinceValues(values);
    resetField('province');
  };
  const clearDistrictInput = (values: ValueType[] = []) => {
    setDistrictValues(values);
    resetField('district');
  };
  const clearChannelInput = (values: ValueType[] = []) => {
    setChannelValues(values);
    resetField('channel');
  };

  const clearModeSaleInput = (values: ValueType[] = []) => {
    setModeSaleValues(values);
    resetField('modeSale');
  };

  const clearModeSellerInput = (values: ValueType[] = []) => {
    setModeSellerValues(values);
    resetField('modeSeller');
  };

  const clearTypeSellerInput = (values: ValueType[] = []) => {
    setTypeSellerValues(values);
    resetField('typeSeller');
  };

  const clearLocalsInput = (values: ValueType[] = []) => {
    setLocalsValues(values);
    resetField('localId');
  };

  // Populate methods

  const populateRegionValues = () => {
    const values: ValueType[] = [];
    regions.forEach(({ id, name }) => {
      values.push({ value: id, label: name });
    });
    setRegionValues(values);
  };

  const populateDepartmentValues = (selectedIdRegion: string | number) => {
    const values: ValueType[] = [];
    departments.forEach(({ id, idRegion, name }) => {
      if (idRegion === selectedIdRegion)
        values.push({ value: id, label: name });
    });
    clearDeparmentInput(values);
    clearProvinceInput();
    clearDistrictInput();
    clearChannelInput();
    clearModeSaleInput();
    clearModeSellerInput();
    clearTypeSellerInput();
    clearLocalsInput();
    // console.log('populateDepartmentValues',getValues('department'));
  };

  const populateProvinceValues = (selectedIdDepartment: string | number) => {
    const values: ValueType[] = [];
    provinces.forEach(({ id, idDepartment, name }) => {
      if (idDepartment === selectedIdDepartment)
        values.push({ value: id, label: name });
    });
    clearProvinceInput(values);
    clearDistrictInput();
    clearChannelInput();
    clearModeSaleInput();
    clearModeSellerInput();
    clearTypeSellerInput();
    clearLocalsInput();

    // console.log('populateProvinceValues',getValues('department'));
  };

  const populateDistrictValues = (selectedIdProvince: string | number) => {
    const values: ValueType[] = [];
    districts.forEach(({ id, idProvince, name }) => {
      if (idProvince === selectedIdProvince)
        values.push({ value: id, label: name });
    });
    clearDistrictInput(values);
    clearChannelInput();
    clearModeSaleInput();
    clearModeSellerInput();
    clearTypeSellerInput();
    clearLocalsInput();
  };

  const populateChannelValues = (selectedIdDistrict: string | number) => {
    const values: ValueType[] = [];
    channels.forEach(({ id, name }) => {
      values.push({ value: id, label: name });
    });
    clearChannelInput(values);
    setSelectedDistrictId(selectedIdDistrict);
    clearModeSaleInput();
    clearModeSellerInput();
    clearTypeSellerInput();
    clearLocalsInput();
  };

  const populateModeSalesValues = (selectedIdChannel: string | number) => {
    const valuesMS: ValueType[] = [];
    modeSales.forEach(({ id, channelId, name }) => {
      if (channelId === selectedIdChannel)
        valuesMS.push({ value: id, label: name });
    });
    clearModeSaleInput(valuesMS);
  };

  const populateTpeSellerValues = (selectedIdChannel: string | number) => {
    const valuesTS: ValueType[] = [];
    sellerTypes.forEach(({ id, channelId, name }) => {
      if (channelId === selectedIdChannel)
        valuesTS.push({ value: id, label: name });
    });
    clearTypeSellerInput(valuesTS);
  };

  const populateModeSellerValues = (selectedIdChannel: string | number) => {
    const valuesSM: ValueType[] = [];
    !!sellerModes &&
      sellerModes.forEach(({ id, channelId, name }) => {
        if (channelId === selectedIdChannel)
          valuesSM.push({ value: id, label: name });
      });
    clearModeSellerInput(valuesSM);
  };

  const populateLocalsValues = () => {
    const values: ValueType[] = [];
    locals.forEach(({ id, name }) => {
      values.push({ value: id, label: name });
    });
    clearLocalsInput(values);
  };

  //onSelect methods

  const selectRegion = (selectedIdRegion: string | number) =>
    populateDepartmentValues(selectedIdRegion);

  const selectDepartment = (selectedIdDepartment: string | number) =>
    populateProvinceValues(selectedIdDepartment);

  const selectProvince = (selectedIdProvince: string | number) =>
    populateDistrictValues(selectedIdProvince);

  const selectDistrict = (selectedIdDistrict: string | number) =>
    populateChannelValues(selectedIdDistrict);

  const selectChannel = (selectedIdChannel: string | number) => {
    populateModeSalesValues(selectedIdChannel);
    populateTpeSellerValues(selectedIdChannel);
    populateModeSellerValues(selectedIdChannel);
    clearLocalsInput();
    geLocals({
      channelId: selectedIdChannel.toString(),
      districtId: selectedDistrictId.toString(),
    });
  };

  // Use Effect methods

  useEffect(populateRegionValues, [regions]);

  useEffect(populateLocalsValues, [locals]);

  return {
    channelValues,
    departmentValues,
    districtValues,
    localsValues,
    modeSaleValues,
    modeSellerValues,
    provinceValues,
    regionValues,
    typeSellerValues,
    selectChannel,
    selectDepartment,
    selectDistrict,
    selectProvince,
    selectRegion,
  };
};
