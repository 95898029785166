import React from 'react';

export const IconRecommendSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
      <g data-name='Grupo 336'>
        <g data-name='Grupo 318'>
          <g data-name='Grupo 317'>
            <path
              fill='none'
              d='M.001 0h32v32h-32z'
              data-name='Rect\xE1ngulo 102'
            />
          </g>
        </g>
        <g data-name='Grupo 327'>
          <g data-name='Grupo 397'>
            <g data-name='Grupo 319'>
              <path
                fill='#ffca65'
                d='M21.571 4.806a1 1 0 01-.709-1.712l1.449-1.449a1.003 1.003 0 011.419 1.417l-1.449 1.45a1 1 0 01-.71.294z'
                data-name='Trazado 149'
              />
            </g>
            <g data-name='Grupo 320'>
              <path
                fill='#ffca65'
                d='M12.207 4.691a1 1 0 01-.709-.294L10.16 3.059a1.003 1.003 0 111.419-1.417l1.338 1.337a1 1 0 01-.71 1.712z'
                data-name='Trazado 150'
              />
            </g>
            <g data-name='Grupo 321'>
              <path
                fill='#ffca65'
                d='M16.945 4.234a1 1 0 01-1-1V1.34a1.004 1.004 0 012.007 0v1.894a1 1 0 01-1.007 1z'
                data-name='Trazado 151'
              />
            </g>
          </g>
          <g data-name='Grupo 322'>
            <path
              fill='#6905a7'
              d='M20.436 31.234a10.917 10.917 0 01-1.295-.078 1.003 1.003 0 11.241-1.991 8.571 8.571 0 009.444-10.194l-1.66-8.306a1.076 1.076 0 00-2.131.21v1.984a1.004 1.004 0 01-2.007 0v-1.984a3.083 3.083 0 016.105-.6l1.66 8.306a10.578 10.578 0 01-10.358 12.658z'
              data-name='Trazado 152'
            />
          </g>
          <g data-name='Grupo 323'>
            <path
              fill='#6905a7'
              d='M15.046 12.167a1 1 0 01-.709-.295 3.01 3.01 0 014.257-4.256l2.432 2.432a1.003 1.003 0 01-1.418 1.418l-2.433-2.431a1.003 1.003 0 00-1.418 1.42 1 1 0 01-.71 1.712z'
              data-name='Trazado 153'
            />
          </g>
          <g data-name='Grupo 324'>
            <path
              fill='#9d47d2'
              d='M15.923 31.77a10.519 10.519 0 01-7.487-3.1l-6.937-6.937a3.012 3.012 0 010-4.257 3.081 3.081 0 014.255 0 1.003 1.003 0 11-1.418 1.418 1.026 1.026 0 00-1.418 0 1 1 0 000 1.421l6.939 6.936A8.581 8.581 0 0024.339 19.5l-1.5-7.485a1.076 1.076 0 00-2.131.21v3.6a1 1 0 01-1.712.71l-8.041-8.04a1.003 1.003 0 00-1.419 1.419 1.003 1.003 0 01-1.418 1.42 3.01 3.01 0 014.255-4.259l6.329 6.329v-1.175a3.082 3.082 0 016.1-.6l1.5 7.485A10.59 10.59 0 0115.92 31.78z'
              data-name='Trazado 154'
            />
          </g>
          <g data-name='Grupo 325'>
            <path
              fill='#9d47d2'
              d='M9.305 23.443a1 1 0 01-.71-.293l-6.147-6.149a3.01 3.01 0 014.255-4.256 1.003 1.003 0 01-1.418 1.419 1.003 1.003 0 10-1.419 1.418l6.148 6.149a1 1 0 01-.709 1.712z'
              data-name='Trazado 155'
            />
          </g>
          <g data-name='Grupo 326'>
            <path
              fill='#9d47d2'
              d='M12.143 20.604a1 1 0 01-.71-.293l-8.038-8.039A3.01 3.01 0 117.65 8.015l8.041 8.04a1.003 1.003 0 11-1.419 1.419L6.233 9.435a1.003 1.003 0 10-1.419 1.418l8.039 8.039a1 1 0 01-.709 1.712z'
              data-name='Trazado 156'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
