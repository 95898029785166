import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClickAwayListener,
  MenuList,
  MenuItem as MUIMenuItem,
  Paper,
  Grow,
  Popper,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import { MenuItem } from '../../types';
import { Stack } from '@mui/system';

import {
  changeActivePage,
  selectSession,
  useLogoutMutation,
} from '../../redux';
import { BackdropLoading } from '../basics';
import { YesNoQuestionModal } from '../../modals';

type DropdownMenuProfileProps = {
  anchorRef: RefObject<HTMLAnchorElement>;
  open: boolean;
  setOpen(open: boolean): void;
};

const keyPrefix = 'components.menu.DropdownMenuProfile';

export const DropdownMenuProfile: FC<DropdownMenuProfileProps> = ({
  anchorRef,
  open,
  setOpen,
}): JSX.Element => {
  const dispatch = useDispatch();
  const prevOpen = useRef(open);
  const session = useSelector(selectSession);

  const { t } = useTranslation('translation', { keyPrefix });
  const [logoutMutation, { isLoading }] = useLogoutMutation();

  const [showConfirmationLogoutModal, setShowConfirmationLogoutModal] =
    useState<boolean>(false);

  const logout = async (): Promise<void> => {
    await logoutMutation(session.username!);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const goChangePassword = (): void => {
    setOpen(false);
    dispatch(changeActivePage({ activePage: 'changePassword' }));
  };

  const goToUserInfo = (): void => {
    setOpen(false);
    dispatch(changeActivePage({ activePage: 'userInfo' }));
  };
  
    const goReactions = (): void => {
      setOpen(false);
      dispatch(changeActivePage({ activePage: 'reactions' }));
    };

  const goFavorites = (): void => {
    setOpen(false);
    dispatch(changeActivePage({ activePage: 'favorites' }));
  };

  const showCloseAlert = () => {
    setOpen(false);
    setShowConfirmationLogoutModal(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      {showConfirmationLogoutModal && (
        <YesNoQuestionModal
          message={t('modals.logout.message')}
          noLabel={t('modals.logout.no')}
          yesLabel={t('modals.logout.yes')}
          onClickYes={logout}
          onClickNo={() => {
            setShowConfirmationLogoutModal(false);
          }}
          onClose={() => {
            setShowConfirmationLogoutModal(false);
          }}
        />
      )}
      {isLoading && <BackdropLoading />}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{ zIndex: 1 }}
        placement='bottom-start'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                minWidth: 339,
                paddingTop: 0,
                paddingBottom: '6px',
                paddingRight: '6px',
                paddingLeft: '6px',
                marginTop: 1,
                borderRadius: '11px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  <MUIMenuItem
                    sx={{ width: '100%', paddingBottom: 0 }}
                    disableTouchRipple
                    autoFocus={false}
                  >
                    <ItemLabel
                      text={session.username!}
                      withArrow={false}
                      isTitle={true}
                    />
                  </MUIMenuItem>
                  {/* <MUIMenuItem
                    sx={{ width: '100%', paddingBottom: 0 }}
                    disableTouchRipple
                    autoFocus={false}
                  >
                    <ItemLabel
                      text={session.name!}
                      withArrow={false}
                      isData={true}
                    />
                  </MUIMenuItem> */}
                  {/* <MUIMenuItem
                    sx={{ width: '100%', paddingBottom: 0, paddingTop: 0 }}
                    disableTouchRipple
                    autoFocus={false}
                  >
                    <ItemLabel
                      text={session.firstLastName!}
                      withArrow={false}
                      isData={true}
                    />
                  </MUIMenuItem> */}
                  <MUIMenuItem
                    sx={{
                      width: '100%',
                      // paddingBottom: 0
                    }}
                    disableTouchRipple
                    autoFocus={false}
                  >
                    <ItemLabel
                      text={session.email!}
                      withArrow={false}
                      isData={true}
                    />
                  </MUIMenuItem>
                  {/* <MUIMenuItem
                    sx={{ width: '100%', paddingBottom: 0 }}
                    disableTouchRipple
                    autoFocus={false}
                  >
                    <ItemLabel
                      text={session.channel!}
                      withArrow={false}
                      isData={true}
                    />
                  </MUIMenuItem> */}
                  <MUIMenuItem sx={{ width: '100%' }} onClick={goToUserInfo}>
                    <ItemLabel text={t('userInfo')} />
                  </MUIMenuItem>
                  <MUIMenuItem
                    sx={{ width: '100%' }}
                    onClick={goChangePassword}
                  >
                    <ItemLabel text={t('changePassword')} />
                  </MUIMenuItem>
                  <MUIMenuItem sx={{ width: '100%' }} onClick={goReactions}>
                    <ItemLabel text={t('reactions')} />
                  </MUIMenuItem>
                  <MUIMenuItem sx={{ width: '100%' }} onClick={goFavorites}>
                    <ItemLabel text={t('favorites')} />
                  </MUIMenuItem>
                  <MUIMenuItem sx={{ width: '100%' }} onClick={showCloseAlert}>
                    <ItemLabel text={t('logout')} />
                  </MUIMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const ItemLabel: FC<{
  text: string;
  withArrow?: boolean;
  isTitle?: boolean;
  isData?: boolean;
}> = ({ text, withArrow = true, isTitle = false, isData = false }) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      paddingLeft={0}
      paddingRight={-1}
    >
      <>
        {isTitle ? (
          <Typography variant='h6' fontWeight='600'>
            {text}
          </Typography>
        ) : isData ? (
          <Typography variant='subtitle1' fontWeight='500'>
            {text}
          </Typography>
        ) : (
          <> {text}</>
        )}
        {withArrow && <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />}
      </>
    </Stack>
  );
};
