import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  MenuList,
  MenuItem as MUIMenuItem,
  Modal,
  Typography,
  Stack,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { IconCloseSvg } from '../assets';
import { useDispatch } from 'react-redux';
import { changeActivePage } from '../redux';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 513,
  bgcolor: 'background.paper',
  borderRadius: '41px',
  boxShadow: 24,
  p: 4,
  padding: '40px 16px',
};

type AnonymousHelProps = {
  onClose: () => void;
};

const keyPrefix = 'modals.AnonymousHelpModal';

export const AnonymousHelpModal: FC<AnonymousHelProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { t } = useTranslation('translation', { keyPrefix });

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      onClose();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  const goToAnonymousPage = (pageName: string) => {
    dispatch(changeActivePage({ activePage: pageName }));
    navigation('/help');
    onClose();
  };

  const goAnonymousFAQ = (): void => goToAnonymousPage('faq');
  const goReportIssue = (): void => goToAnonymousPage('report');
  const goSuggestions = (): void => goToAnonymousPage('suggest');
  const goTC = (): void => goToAnonymousPage('tc');
  const goAbout = (): void => goToAnonymousPage('about');

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={style}>
        <Box
          display='grid'
          gridTemplateColumns='24px 1fr'
          alignItems='center'
          margin='0 14px'
        >
          <IconCloseSvg green onClick={onClose} style={{ cursor: 'pointer' }} />
          <Typography variant='h5' fontSize='32px' textAlign='center'>
            {t('title')}
          </Typography>
        </Box>
        <Divider
          sx={{
            borderBottomWidth: '3px',
            width: '100%',
            marginTop: '24px',
            marginBottom: '32px',
          }}
        />
        <Box margin='0 32px'>
          <MenuList autoFocusItem={true} onKeyDown={handleListKeyDown}>
            <MUIMenuItem sx={{ width: '100%' }} onClick={goAnonymousFAQ}>
              <ItemLabel text={t('faq')} />
            </MUIMenuItem>
            <MUIMenuItem sx={{ width: '100%' }} onClick={goReportIssue}>
              <ItemLabel text={t('report')} />
            </MUIMenuItem>
            <MUIMenuItem sx={{ width: '100%' }} onClick={goSuggestions}>
              <ItemLabel text={t('suggestions')} />
            </MUIMenuItem>
            <MUIMenuItem sx={{ width: '100%' }} onClick={goTC}>
              <ItemLabel text={t('tc')} />
            </MUIMenuItem>
            <MUIMenuItem sx={{ width: '100%' }} onClick={goAbout}>
              <ItemLabel text={t('about')} />
            </MUIMenuItem>
          </MenuList>
        </Box>
      </Box>
    </Modal>
  );
};
export const ItemLabel: FC<{ text: string }> = ({ text }) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      paddingLeft={0}
      fontSize='24px'
    >
      {text}
      <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
    </Stack>
  );
};
