import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectIsAuthenticated } from '../redux';

const PublicOutlet: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const isAuth = useSelector(selectIsAuthenticated);

  return !isAuth ? (
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to='/' replace />
  );
};

export default PublicOutlet;
