import { FC } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';

import {
  Face1Svg,
  Face2Svg,
  Face3Svg,
  Face4Svg,
  Face5Svg,
  Face6Svg,
  Face7Svg,
  Face8Svg,
} from '../../../assets';
import { Answer } from '../../../types';

type SurveyAnswerButtonProps = {
  answer: Answer;
  selected?: boolean;
  onClick: () => void;
};

export const SurveyAnswerButton: FC<SurveyAnswerButtonProps> = ({
  answer,
  selected = false,
  onClick,
}): JSX.Element => {
  const { answer: text, iconId } = answer;

  const getSelectedColor = (): string => {
    switch (iconId) {
      case 1:
        return '#3d7617';
      case 2:
        return '#809300';
      case 3:
        return '#fa7e02';
      case 4:
        return '#fc0808';
      case 5:
        return '#ef1600';
      case 6:
        return '#1e878c';
      case 7:
        return '#1badb4';
      case 8:
        return '#048970';
      default:
        return '#1badb4';
    }
  };

  return (
    <Card
      elevation={4}
      style={{
        width: 99,
        height: 166,
        borderRadius: 8,
        backgroundColor: selected ? getSelectedColor() : '#F8F8F8',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Stack>
        <Card
          elevation={4}
          sx={{
            width: 99,
            height: 89,
            borderRadius: 2,
          }}
        >
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            height='100%'
            border='6px solid white'
            borderBottom='6px solid white'
            borderRadius='2px'
            sx={{
              backgroundColor: selected ? `${getSelectedColor()}40` : 'white',
            }}
          >
            {(() => {
              switch (iconId) {
                case 1:
                  return <Face1Svg width='42px' />;
                case 2:
                  return <Face2Svg width='42px' />;
                case 3:
                  return <Face3Svg width='42px' />;
                case 4:
                  return <Face4Svg width='42px' />;
                case 5:
                  return <Face5Svg width='42px' />;
                case 6:
                  return <Face6Svg width='42px' />;
                case 7:
                  return <Face7Svg width='42px' />;
                case 8:
                  return <Face8Svg width='42px' />;
                default:
                  return null;
              }
            })()}
          </Box>
        </Card>
        <Box position='relative' minHeight='89px'>
          <Typography
            color={selected ? 'white' : getSelectedColor()}
            position='absolute'
            display='flex'
            justifyContent='center'
            alignItems='center'
            top='-6px'
            variant='body2'
            sx={{
              width: '100%',
              height: '83px',
              border: '3px solid white',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
          >
            {text}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
