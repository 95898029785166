import React from 'react';

export const IconPDFSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={34} height={34} {...props}>
      <g data-name='Grupo 7284'>
        <path fill='none' d='M0 0h34v34H0z' data-name='Rect\xE1ngulo 4398' />
      </g>
      <path
        fill='#455d7a'
        d='M29 2H11a3.009 3.009 0 0 0-3 3v18a3.01 3.01 0 0 0 3 3h18a3.009 3.009 0 0 0 3-3V5a3.009 3.009 0 0 0-3-3ZM16.249 13.249a2.246 2.246 0 0 1-2.243 2.251H12.5v3h-2.25v-9H14a2.246 2.246 0 0 1 2.25 2.244v1.505Zm7.5 3a2.246 2.246 0 0 1-2.243 2.251H17.75v-9h3.75a2.246 2.246 0 0 1 2.25 2.244v4.505Zm6-4.5H27.5v1.5h2.25V15.5H27.5v3h-2.25v-9h4.5Zm-17.249 1.5H14v-1.5h-1.5ZM5 8H2v21a3.01 3.01 0 0 0 3 3h21v-3H5Zm15 8.249h1.5v-4.5H20Z'
        data-name='Trazado 5657'
      />
    </svg>
  );
};
