import React from 'react';

export const IconDownloadSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 34' {...props}>
      <g data-name='Grupo 7283'>
        <g data-name='Grupo 7279'>
          <path
            fill='#455d7a'
            d='M17.003 25.231a1.063 1.063 0 01-1.062-1.062V4.334a1.063 1.063 0 011.063-1.062 1.063 1.063 0 011.063 1.063V24.17a1.063 1.063 0 01-1.064 1.061z'
            data-name='Trazado 5672'
          />
        </g>
        <g data-name='Grupo 7280'>
          <path
            fill='#455d7a'
            d='M24.083 30.896H9.916a6.737 6.737 0 01-6.729-6.729 1.063 1.063 0 011.063-1.062 1.063 1.063 0 011.063 1.063 4.609 4.609 0 004.6 4.6h14.17a4.609 4.609 0 004.6-4.6 1.063 1.063 0 011.063-1.062 1.063 1.063 0 011.063 1.063 6.737 6.737 0 01-6.726 6.727z'
            data-name='Trazado 5673'
          />
        </g>
        <g data-name='Grupo 7282'>
          <g data-name='Grupo 7281'>
            <path
              fill='#455d7a'
              d='M17 25.231a1.06 1.06 0 01-.751-.312l-7.085-7.085a1.061 1.061 0 010-1.5 1.061 1.061 0 011.5 0l6.334 6.334 6.332-6.334a1.061 1.061 0 011.5 0 1.061 1.061 0 010 1.5l-7.083 7.085a1.058 1.058 0 01-.747.312z'
              data-name='Trazado 5674'
            />
          </g>
        </g>
      </g>
      <g data-name='Grupo 7284'>
        <path fill='none' d='M0 0h34v34H0z' data-name='Rect\xE1ngulo 4398' />
      </g>
    </svg>
  );
};
