import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectContents,
  selectSession,
  updateBagesByTags,
  useGetPromosMutation,
} from '../redux';
import { PromosApiResponse } from '../types';

const CACHE_REQUEST = 'tag';

export const useContentBadges = () => {
  const dispatch = useDispatch();
  // const actualContents = useSelector(selectContents);
  const session = useSelector(selectSession);
  const [getPromos] = useGetPromosMutation();

  const getCacheStorage = async () => await caches.open('vwa-notify');

  const getTagsFromCache = async (): Promise<[string[], Cache]> => {
    const cacheStorage = await getCacheStorage();
    const cachedResponse = await cacheStorage.match(`/${CACHE_REQUEST}`);
    const data = await cachedResponse?.text();

    if (!data) return [[], cacheStorage];

    const newData = await verifyContentExistence(data);
    // const newData = data;

    return [newData.length === 0 ? [] : newData.split(','), cacheStorage];
  };

  const verifyContentExistence = async (data: String): Promise<String> => {
    const actualTags = data.split(',');

    const res = (await getPromos(session)) as { data: PromosApiResponse };
    const actualContents = res.data.contents;

    // console.log('actualTags', actualTags);

    // console.log(
    //   'lastActualContents ',
    //   actualContents[actualContents.length - 1]
    // );

    // console.log('actualContents.LENGTH', actualContents.length);

    let newData: string = '';
    if (data.length > 0) {
      // O(n^2)
      for (let ic = actualContents.length - 1; ic >= 0; ic--) {
        for (let it = actualTags.length - 1; it >= 0; it--) {
          const { id, contentType, subContentType } = actualContents[ic];
          const [atCT, atSCT, atId] = actualTags[it].split('-');

          if (
            id === parseInt(atId) &&
            contentType === parseInt(atCT) &&
            subContentType === parseInt(atSCT)
          ) {
            newData =
              newData.length > 0
                ? `${actualTags[it]},${newData}`
                : actualTags[it];
            // actualTags.pop();
            // console.log('newData', newData);
            break;
          }
        }
      }

      // console.log('verifyContentExistence - init data', data);
      // console.log('verifyContentExistence - final newData', newData);
      newData.length > 0 && dispatch(updateBagesByTags(newData.split(',')));
      // if (newData.length !== data.length) {
      //   const cacheStorage = await getCacheStorage();
      //   await cacheStorage.put(CACHE_REQUEST, new Response(newData));
      //   // dispatch(updateBagesByTags(filterTags));
      // }
    }

    return newData;
  };

  const updateBadgesWithTag = async (newTag: string) => {
    const [tags, cacheStorage] = await getTagsFromCache();
    // console.log('updateBadgesWithTag - tags', tags);
    // console.log('updateBadgesWithTag - newTag', newTag);
    if (tags.includes(newTag)) return;

    tags.push(newTag);
    await cacheStorage.put(CACHE_REQUEST, new Response(tags.join(',')));
    dispatch(updateBagesByTags(tags));
  };

  const updateBadges = async () => {
    const [tags] = await getTagsFromCache();
    // console.log('updateBadges - tags', tags);
    dispatch(updateBagesByTags(tags));
  };

  useEffect(() => {
    updateBadges();
    // eslint-disable-next-line
  }, []);

  const removeBadges = async (contenIdToRemove: number) => {
    const [tags, cacheStorage] = await getTagsFromCache();

    if (tags.length === 0) return;

    const filterTags = tags.filter(tag => {
      const [, , contentId] = tag?.split('-');
      return contentId !== contenIdToRemove.toString();
    });

    console.log(
      `removing ${contenIdToRemove} from tags [${tags}] --> filterTags [${filterTags}]`
    );

    await cacheStorage.put(CACHE_REQUEST, new Response(filterTags.join(',')));
    dispatch(updateBagesByTags(filterTags));
  };

  return { removeBadges, updateBadges, updateBadgesWithTag };
};
