import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { ActivityStatus } from '../../enums';
import { Activity } from '../../types';
import { RootState } from '../store';

export interface ReportState {
  activity: Activity[];
  startTime: number;
  state: ActivityStatus | null;
}

const initialState: ReportState = {
  activity: [],
  startTime: 0,
  state: null,
};

const getTimes = (): [string, number] => {
  const date = moment().format('DD/MM/YYYY HH:mm:ss.SSS');
  const dateMilliSeconds = moment().valueOf();
  return [date, dateMilliSeconds];
};

export const reportsSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    startActive: state => {
      const [date, dateMilliSeconds] = getTimes();
      const actualState = state.state;
      const actualActivity = state.activity;

      if (actualState === ActivityStatus.INACTIVE) {
        const activityToBeAdded = {
          date,
          status: ActivityStatus.INACTIVE,
          totalTime: dateMilliSeconds - state.startTime,
        } as Activity;
        state.activity = [...actualActivity, activityToBeAdded];
      }
      state.startTime = dateMilliSeconds;
      state.state = ActivityStatus.ACTIVE;
    },
    startInactive: state => {
      const [date, dateMilliSeconds] = getTimes();
      const actualState = state.state;
      const actualActivity = state.activity;

      if (actualState === ActivityStatus.ACTIVE) {
        const activityToBeAdded = {
          date,
          status: ActivityStatus.ACTIVE,
          totalTime: dateMilliSeconds - state.startTime,
        } as Activity;
        state.activity = [...actualActivity, activityToBeAdded];
      }
      state.startTime = dateMilliSeconds;
      state.state = ActivityStatus.INACTIVE;
    },
  },
});

export const { startActive, startInactive } = reportsSlice.actions;

export const selectActivity = (state: RootState) => state.reports.activity;

export const reportsReducer = reportsSlice.reducer;
