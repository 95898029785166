import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

export const BackdropLoading: React.FC = (): JSX.Element => {
  return (
    <Backdrop open={true} sx={{ zIndex: 90001 }}>
      <CircularProgress size={62} />
    </Backdrop>
  );
};
