import { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

type ComponentProps = {
  error?: boolean;
  focus?: boolean;
  mb?: number;
};

type Props = ComponentProps & PropsWithChildren;

export const LabelText: React.FC<Props> = ({
  children,
  error = false,
  focus = false,
  mb,
}): JSX.Element => {
  return (
    <Typography
      color={error ? 'error' : focus ? 'secondary' : 'GrayText'}
      mb={mb}
      variant='caption'
      fontSize={20}
      fontWeight={error ? '500' : '300'}
    >
      {children}
    </Typography>
  );
};
