import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Fab, Tooltip } from '@mui/material';
import { IconMagazineSvg, IconVideoSvg } from '../../assets';

type CallToActionButtonProps = {
  onClick: () => void;
  type: 'video' | 'magazine';
};

const keyPrefix = 'components.content.CallToActionButton';

export const CallToActionButton: FC<CallToActionButtonProps> = ({
  onClick,
  type,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Tooltip title={t(`tooltip.${type === 'video' ? 'video' : 'magazine'}`)}>
      <Fab
        onClick={onClick}
        sx={{
          svg: {
            width: '29px',
            height: '29px',
          },
        }}
      >
        {
          {
            magazine: <IconMagazineSvg />,
            video: <IconVideoSvg />,
          }[type]
        }
      </Fab>
    </Tooltip>
  );
};
