import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { LoginForm } from '../forms';
import { InitialPagesLayout } from '../layouts/InitialPagesLayout';
import { AnonymousHelpModal } from '../modals';
import { useClearLoginInfo } from '../hooks';

const keyPrefix = 'pages.LoginPage';

export const LoginPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });
  const navigation = useNavigate();
  const { clearDataLogin } = useClearLoginInfo();

  const [showHelpMenuModal, setShowHelpMenuModal] = useState<boolean>(false);

  const goRegister = () => {
    navigation('/signUp');
  };

  const toggleShowHelpMenuModal = () => {
    setShowHelpMenuModal(value => !value);
  };

  useEffect(() => {
    clearDataLogin();

    return () => {
      clearDataLogin();
    };
  }, [])
  

  return (
    <Stack sx={{ marginBottom: 8, marginTop: 8 }}>
    <InitialPagesLayout title={t('title')}>
      {showHelpMenuModal && (
        <AnonymousHelpModal onClose={toggleShowHelpMenuModal} />
      )}
      <LoginForm />
      <Typography
        variant='h6'
        color='secondary'
        display='flex'
        justifyContent='center'
        fontWeight={800}
        paddingTop={3}
      >
        <Link
          to='/recoverPassword'
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          {t('passRecovery')}
        </Link>
      </Typography>
      <Box display='flex' justifyContent='center' py={2}>
        <Divider variant='middle' sx={{ width: '481px' }} />
      </Box>
      <Stack direction='row' spacing={2}>
        <Button
          type='button'
          color='secondary'
          variant='contained'
          onClick={goRegister}
          fullWidth
        >
          {t('register')}
        </Button>
        <Button
          type='button'
          color='secondary'
          variant='outlined'
          onClick={toggleShowHelpMenuModal}
          fullWidth
        >
          {t('help')}
        </Button>
      </Stack>
    </InitialPagesLayout>
    </Stack>
  );
};
