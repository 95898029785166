import React from 'react';

export const IconRestoreSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
      <g data-name='Grupo 481'>
        <path fill='none' d='M0 0h32v32H0z' data-name='Rect\xE1ngulo 138' />
      </g>
      <g data-name='Grupo 485'>
        <g data-name='Grupo 482'>
          <path
            fill='#039f8d'
            d='M7.666 11.036a1 1 0 01-.709-.294 1 1 0 010-1.419L10.97 5.31a1 1 0 011.418 0 1 1 0 010 1.419l-4.013 4.013a1 1 0 01-.709.294z'
            data-name='Trazado 192'
          />
        </g>
        <g data-name='Grupo 483'>
          <path
            fill='#039f8d'
            d='M11.679 15.049a1 1 0 01-.709-.294l-4.013-4.013a1 1 0 010-1.419 1 1 0 011.418 0l4.013 4.013a1 1 0 010 1.419 1 1 0 01-.709.294z'
            data-name='Trazado 193'
          />
        </g>
        <g data-name='Grupo 484'>
          <path
            fill='#039f8d'
            d='M15.692 27.087a9.039 9.039 0 01-9.029-9.029 1 1 0 011-1 1 1 0 011 1 7.031 7.031 0 007.023 7.023 7.031 7.031 0 007.023-7.023 7.031 7.031 0 00-7.023-7.023H7.66a1 1 0 01-1-1 1 1 0 011-1h8.026a9.04 9.04 0 019.029 9.03 9.039 9.039 0 01-9.023 9.022z'
            data-name='Trazado 194'
          />
        </g>
      </g>
    </svg>
  );
};
