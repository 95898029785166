import { FC } from 'react';

type HtmlViewProps = {
  src: string;
  title: string;
};

export const HtmlViewer: FC<HtmlViewProps> = ({ src, title }): JSX.Element => (
  <iframe
    width='100%'
    height='100%'
    src={src}
    title={title}
    style={{ border: 0, overflow: 'hidden' }}
  />
);
