import { Box, Container } from '@mui/material';

import { BackdropLoading, FavoritesGrid } from '../components';
import { useFavoritePage } from '../hooks';

export const FavoritesPage = () => {
  const { loading } = useFavoritePage();

  return (
    <Container maxWidth='lg'>
      {loading && <BackdropLoading />}
      <Box padding='32px 105px'>
        <FavoritesGrid />
      </Box>
    </Container>
  );
};
