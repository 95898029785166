import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

import { useUserInfoFormManagement } from "../hooks";
import { FormTextField } from "../components";

const keyPrefix = "forms.UserInfoForm";

export const UserInfoForm: FC = (): JSX.Element => {
  const { t } = useTranslation("translation", { keyPrefix });

  // const [showErroMessage, setShowErroMessage] = useState<boolean>(false);
  const {
    // fieldsValidationErr, genericErr,logout,
    methods,
    submit,
  } = useUserInfoFormManagement();

  // useEffect(() => {
  //   !!genericErr && setShowErroMessage(true);
  // }, [genericErr]);

  return (
    <>
      {/* {showErroMessage && (
        <MessageModal
          message={genericErr!}
          okLabel={t('modals.genericErr.ok')}
          onClickOk={() => {
            setShowErroMessage(false);
          }}
          onClose={() => {
            setShowErroMessage(false);
          }}
        />
      )} */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Stack spacing={2} pt={2}>
              <FormTextField
                name="username"
                disabled={true}
                label={t("username.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="name"
                disabled={true}
                label={t("name.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="firstLastName"
                disabled={true}
                label={t("firstLastName.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="secondLastName"
                disabled={true}
                label={t("secondLastName.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="identificationType"
                disabled={true}
                label={t("identificationType.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="identificationValue"
                disabled={true}
                label={t("identificationValue.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="email"
                disabled={true}
                label={t("email.label")}
                inputProps={{ maxLength: 4 }}
              />

              <FormTextField
                name="region"
                disabled={true}
                label={t("region.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="department"
                disabled={true}
                label={t("department.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="province"
                disabled={true}
                label={t("province.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="district"
                disabled={true}
                label={t("district.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="channel"
                disabled={true}
                label={t("channel.label")}
                inputProps={{ maxLength: 4 }}
              />
              <FormTextField
                name="salePoint"
                disabled={true}
                label={t("salePoint.label")}
                inputProps={{ maxLength: 4 }}
              />
              {/* <Stack direction='row' pt={2} spacing={2}>
          {requireRestorePassword && (
            <Button
              type='button'
              color='secondary'
              variant='outlined'
              onClick={logout}
              fullWidth
            >
              {t('cancel.label')}
            </Button>
          )}
          <Button type='submit' variant='contained' fullWidth>
            {t('submit.label')}
          </Button>
        </Stack> */}
            </Stack>
          {/* {fieldsValidationErr && (
              <FieldsValidationErrorAlert error={fieldsValidationErr} />
            )} */}
        </form>
      </FormProvider>
    </>
  );
};
