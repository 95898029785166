import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // action: {
    //   disabled: '#ffa617',
    // },
    primary: {
      main: '#f22b26',
    },
    secondary: {
      main: '#1e878c',
    },
    warning: {
      main: '#ffa617',
    },
    success: {
      main: '#00c163',
    },
    background: {
      default: '#fbfbfd',
    },
    error: {
      main: '#d32f2f',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Catamaran',
      overflowWrap: 'break-word',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 32,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 40,
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 20,
    },
    body1: {
      fontSize: '20px',
    },
    body2: {
      fontSize: '12px',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          color: '#222222',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          borderBottom: '2px solid #f5f6f7',
          boxShadow: 'none',
          color: '#455d7a',
          height: 60,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 10,
          marginTop: '0 !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        disabled: {
          border: '1px solid red',
        },
        contained: {
          height: 52,
          textTransform: 'unset',
          fontSize: 21,
          fontWeight: 'bold',
          borderRadius: '7px',
          '&.Mui-disabled': {
            border: '2px solid #ec342f',
            color: '#ec342f',
            backgroundColor: 'transparent',
            opacity: 0.4,
          },
        },
        outlined: {
          border: '2px solid',
          height: 52,
          textTransform: 'unset',
          fontSize: 21,
          fontWeight: 'bold',
          borderRadius: '7px',
          '&:hover': {
            border: '2px solid',
          },
        },
        outlinedInfo: {
          color: '#00adb5',
          border: 'none',
          boxShadow: '2px 2px 5px rgb(0,0,0,0.2)',
          height: '70px',
          '&:hover': {
            backgroundColor: '#e3edeb',
            p: {
              fontWeight: 'bold',
            },
          },
        },
        text: {
          borderRadius: '11px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '40px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px 38px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          padding: '0 60px !important',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        fullWidth: {
          borderColor: '#f22b26',
          borderBottomWidth: '8px',
          margin: '26px 0',
        },
        middle: {
          borderColor: '#455d7a',
          borderBottomWidth: '3px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          width: '35px',
          height: '35px',
          padding: '3px',
          zIndex: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 18,
          padding: '15px 30px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#000000',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: '#FFFFFF',
        },
        icon: {
          color: '#f22b26',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          minHeight: 0,
        },
        root: {
          textTransform: 'capitalize',
          fontSize: 20,
          padding: '12px 34px',
        },
      },
    },
  },
});

export default theme;
