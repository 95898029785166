import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectContents } from '../redux';
import { Content } from '../types';

const newsId = 101;
const argumentId = 100;

export const useHomePage = () => {
  const contents = useSelector(selectContents);

  const [newsList, setNewsList] = useState<Content[]>([]);
  const [argumentList, setArgumentList] = useState<Content[]>([]);

  const getData = (): void => {
    if (contents.length === 0) return;

    const newNewsList: Content[] = [];
    const newArgumentList: Content[] = [];

    contents.forEach(content => {
      switch (content.contentType) {
        case newsId:
          newNewsList.push(content);
          break;
        case argumentId:
          newArgumentList.push(content);
          break;
        default:
          return;
      }
    });

    setNewsList(newNewsList);
    setArgumentList(newArgumentList);
  };

  useEffect(getData, [contents]);

  return { argumentList, newsList };
};
