import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IncentiveType } from '../../enums';
import {
  shiftIncentive,
  selectCurrentIncentive,
  useReportIncentiveMutation,
  selectSession,
  selectIncentiveAnswered,
  clearAnsweredIncentive,
  removeIncentives,
} from '../../redux';
import { PublicationViewer } from './publication';
import { SurveyViewer } from './survey';
import { TriviaViewer } from './trivia';

export const IncentiveViewer: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const currentIncentive = useSelector(selectCurrentIncentive);
  const incentiveAnswered = useSelector(selectIncentiveAnswered);
  const [type, setType] = useState<IncentiveType | null>(null);
  const [report] = useReportIncentiveMutation();

  const extractIncentiveType = () => {
    setType(currentIncentive != null ? currentIncentive.type! : null);
  };
  const reportIncentive = async () => {
    if (!!incentiveAnswered) {
      await report({ session, incentive: incentiveAnswered });
      //console.log('incentiveAnswered', incentiveAnswered);
      dispatch(clearAnsweredIncentive());
    }
  };

  const handleFinishIncentive = async () => {
    setType(null);
    dispatch(shiftIncentive());
  };

  const handleLeaveIncentives = async () => {
    setType(null);
    dispatch(removeIncentives());
  };

  useEffect(extractIncentiveType, [currentIncentive]);
  useEffect(() => {
    reportIncentive();
    // eslint-disable-next-line
  }, [incentiveAnswered]);

  return (
    <>
      {!!currentIncentive &&
        (() => {
          switch (type) {
            case IncentiveType.TRIVIA:
              return (
                <TriviaViewer
                  trivia={currentIncentive!}
                  onFinishTrivia={handleFinishIncentive}
                  onLeaveIncentives={handleLeaveIncentives}
                />
              );
            case IncentiveType.SURVEY:
              return (
                <SurveyViewer
                  survey={currentIncentive!}
                  onClickQuit={handleLeaveIncentives}
                  onFinishSurvey={handleFinishIncentive}
                />
              );
            case IncentiveType.PUBLICATION:
              return (
                <PublicationViewer
                  publication={currentIncentive!}
                  onClickQuit={handleLeaveIncentives}
                  onFinishPublication={handleFinishIncentive}
                />
              );
            default:
              return <></>;
          }
        })()}
    </>
  );
};
