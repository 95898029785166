import React from 'react';

export const IconMagazineSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <g data-name='Grupo 7233'>
        <path fill='none' d='M0 0h24v24H0z' data-name='Rect\xE1ngulo 4382' />
      </g>
      <g data-name='Grupo 7235'>
        <path
          fill='none'
          stroke='#1badb4'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M15 13.757a14.546 14.546 0 013-.639'
          data-name='Trazado 5659'
        />
        <path
          fill='none'
          stroke='#1badb4'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M6 13.118a14.55 14.55 0 013 .639'
          data-name='Trazado 5660'
        />
        <path
          fill='none'
          stroke='#1badb4'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M18 9.118a14.546 14.546 0 00-3 .639'
          data-name='Trazado 5661'
        />
        <path
          fill='none'
          stroke='#1badb4'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M9 9.757a14.55 14.55 0 00-3-.639'
          data-name='Trazado 5662'
        />
        <g data-name='Grupo 7234'>
          <path
            fill='none'
            stroke='#1badb4'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.5}
            d='M12 6.131a16.171 16.171 0 00-7.983-2.13 1 1 0 00-1.017 1v11.852a1 1 0 00.974 1A15.033 15.033 0 0112 20.001a15.033 15.033 0 018.026-2.148 1 1 0 00.974-1V5.001a1 1 0 00-1.017-1A16.171 16.171 0 0012 6.131z'
            data-name='Trazado 5663'
          />
        </g>
        <path
          fill='none'
          stroke='#1badb4'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M12 6.131V20'
          data-name='L\xEDnea 3'
        />
      </g>
    </svg>
  );
};
