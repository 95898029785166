import { FC } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { FullModalLayout } from '../layouts';
import { Media } from '../types';

type MediaModalProps = {
  media: Media;
  onClose: () => void;
};

export const MediaModal: FC<MediaModalProps> = ({ media, onClose }) => {
  const { mediaType, url } = media;

  return (
    <FullModalLayout onClose={onClose} withoutBackground>
      <Typography
        variant='h4'
        paddingTop={0}
        paddingLeft={8}
        sx={{ cursor: 'pointer' }}
        onClick={onClose}
      >
        <ArrowBackIcon
          fontSize='large'
          sx={{ paddingTop: '4px', marginRight: '12px', color: '#fff' }}
        />
      </Typography>
      <Box display='flex' padding={10} maxHeight='90vh'>
        {mediaType === 1 ? (
          <CardMedia
            component='img'
            src={url}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
            }}
          />
        ) : (
          <CardMedia
            component='video'
            controls={true}
            src={url}
            sx={{
              margin: '0 auto',
            }}
          />
        )}
      </Box>
    </FullModalLayout>
  );
};
