import React, { FC } from 'react';

type IconFavoriteSvgProps = {
  on: boolean;
};

export const IconFavoriteSvg: FC<
  React.SVGProps<SVGSVGElement> & IconFavoriteSvgProps
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 34' {...props}>
      <g data-name='Grupo 341'>
        <path fill='none' d='M0 0h34v34H0z' data-name='Rect\xE1ngulo 111' />
      </g>
      <g data-name='Grupo 342'>
        <path
          fill={props.on ? '#1e878c' : '#455d7a'}
          d='M23.729 28.827a2.689 2.689 0 01-1.215-.29l-5.264-2.656-5.263 2.656a2.679 2.679 0 01-2.747-.19 2.445 2.445 0 01-1.036-2.449l1-5.629-4.259-3.982a2.422 2.422 0 01-.661-2.567 2.569 2.569 0 012.106-1.706l5.885-.821 2.632-5.124a2.659 2.659 0 014.678 0l2.632 5.124 5.886.821a2.571 2.571 0 012.106 1.706 2.422 2.422 0 01-.663 2.567l-4.259 3.982 1.006 5.629a2.447 2.447 0 01-1.038 2.449 2.676 2.676 0 01-1.526.48zm-5.922-5l5.71 2.883a.45.45 0 00.476-.034.409.409 0 00.18-.424l-1.1-6.164a1.007 1.007 0 01.31-.915l4.666-4.365a.406.406 0 00.115-.444.433.433 0 00-.365-.3l-6.447-.9a1.076 1.076 0 01-.812-.566l-2.882-5.612a.463.463 0 00-.811 0l-2.882 5.612a1.074 1.074 0 01-.812.566l-6.446.9a.433.433 0 00-.365.3.4.4 0 00.114.444l4.666 4.365a1 1 0 01.31.915l-1.1 6.164a.411.411 0 00.18.424.448.448 0 00.477.034l5.71-2.883a1.1 1.1 0 01.553-.146 1.1 1.1 0 01.555.141z'
          data-name='Trazado 159'
        />
      </g>
    </svg>
  );
};
