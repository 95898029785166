import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import { IconCloseSvg, IconSearchSvg } from '../../assets';
import { searchContent, selectActivePage } from '../../redux';

const keyPrefix = 'components.menu.SearchContent';

export const SearchContent: FC = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const dispatch = useDispatch();
  const { searchingContent } = useSelector(selectActivePage);
  const [value, setValue] = useState<string>('');

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(value);
    dispatch(searchContent(value));
  };

  const handleClear = () => {
    setValue('');
    dispatch(searchContent(''));
  };

  useEffect(() => {
    !searchingContent && handleClear();
  }, [searchingContent])

  return (
    <TextField
      size='small'
      InputProps={{
        endAdornment: !!value ? (
          <InputAdornment position='end'>
            <IconButton size='small' onClick={handleClear}>
              <IconCloseSvg />
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
        startAdornment: <IconSearchSvg width={32} />,
        sx: { borderRadius: 22 },
      }}
      inputProps={{
        style: {
          paddingLeft: 12,
        },
      }}
      placeholder={t('placeholder')}
      sx={{ width: '294px' }}
      onChange={handleChange}
      value={value}
    />
  );
};
