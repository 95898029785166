import { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRecoverPasswordFormManagement } from '../hooks';
import {
  FormTextField,
  FieldsValidationErrorAlert,
  BackdropLoading,
} from '../components';
import { MessageModal } from '../modals';
import { useNavigate } from 'react-router-dom';

const keyPrefix = 'forms.RecoverPasswordForm';

export const RecoverPasswordForm: FC = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [showErroMessage, setShowErroMessage] = useState<boolean>(false);
  const navigation = useNavigate();
  const {
    fieldsValidationErr,
    genericErr,
    loading,
    methods,
    response,
    success,
    submit,
  } = useRecoverPasswordFormManagement();

  const goLogin = (): void => {
    navigation('/login');
  };

  useEffect(() => {
    !!genericErr && setShowErroMessage(true);
  }, [genericErr]);

  return (
    <>
      {!!response && (
        <MessageModal
          message={response}
          okLabel={t('modals.genericErr.ok')}
          title={success ? t('modals.success.title') : null}
          onClickOk={goLogin}
          onClose={() => {
            // setShowErroMessage(false);
          }}
        />
      )}
      {showErroMessage && (
        <MessageModal
          message={genericErr!}
          okLabel={t('modals.genericErr.ok')}
          onClickOk={() => {
            setShowErroMessage(false);
          }}
          onClose={() => {
            setShowErroMessage(false);
          }}
        />
      )}
      {loading && <BackdropLoading />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Stack spacing={2} pt={3}>
            {fieldsValidationErr && (
              <FieldsValidationErrorAlert error={fieldsValidationErr} />
            )}

            <FormTextField
              name='identificationValue'
              label={t('identificationValue.label')}
              inputProps={{ maxLength: 50 }}
            />
            <Stack direction='row' pt={4} spacing={2}>
              <Button
                type='button'
                color='secondary'
                variant='outlined'
                onClick={goLogin}
                fullWidth
              >
                {t('cancel.label')}
              </Button>
              <Button type='submit' variant='contained' fullWidth>
                {t('submit.label')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
