import { FC } from 'react';
import { ErrorAlert } from '../basics';

type Props = { error?: string | null };

export const FieldsValidationErrorAlert: FC<Props> = ({
  error,
}): JSX.Element => {
  if (!error) return <></>;

  return <ErrorAlert>{error}</ErrorAlert>;
};
