import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCategoryRelation, selectContents } from '../redux';
import { MenuItem } from '../types/components';

export const useProducts = () => {
  const categoryRelation = useSelector(selectCategoryRelation);
  const contents = useSelector(selectContents);
  const [productList, setProductList] = useState<MenuItem[]>([]);

  const parseRelation = (): void => {
    let idx: number = 0;
    let dictionary: { [idx: number]: MenuItem } = {};

    categoryRelation.forEach(({ idFather, nameF, idSon, nameS }) => {
      if (idFather >= 100) return;

      const esNewItem = !dictionary[idFather];
      const son = {
        id: idSon,
        label: nameS,
        sons: [],
        idx: 0,
        badge: 0,
      } as MenuItem;
      const canAddSon = verifySonContent(idSon);

      if (esNewItem) {
        const sons = canAddSon ? [son] : [];
        dictionary[idFather] = {
          id: idFather,
          label: nameF,
          sons,
          idx,
          badge: 0,
        };
        idx++;
      } else {
        const existedItem = dictionary[idFather];
        const sonIdx = existedItem.sons.length;
        const sons = canAddSon
          ? existedItem.sons.concat({ ...son, idx: sonIdx })
          : existedItem.sons;
        dictionary[idFather] = { ...existedItem, sons };
      }
    });

    createList(dictionary);
  };

  const createList = (dictionary: { [idx: number]: MenuItem }): void => {
    const list = new Array<MenuItem>();
    Object.entries(dictionary).forEach(([_, menuItem]) => {
      const idx = menuItem.idx!;
      list[idx] = menuItem;
    });
    setProductList(list);
  };

  const verifySonContent = (idSong: number): boolean => {
    const hasContent = contents.find(
      content => content.subContentType === idSong
    );
    return hasContent !== undefined;
  };

  useEffect(() => {
    categoryRelation.length > 0 && parseRelation();
    // eslint-disable-next-line
  }, [categoryRelation, contents]);

  // console.log(productList);

  return productList;
};
