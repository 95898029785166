import React from 'react';

export const Face8Svg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <g fill='none'>
        <path d='M0 0h24v24H0z' data-name='Rect\xE1ngulo 26' />
        <g
          stroke='#048970'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          data-name='Grupo 33'
          transform='translate(-667.189 -1343.731)'
        >
          <rect
            width={18}
            height={18}
            data-name='Rect\xE1ngulo 27'
            rx={9}
            transform='translate(670.189 1346.731)'
          />
          <path d='M683.189 1353.731l-5 5-3-3' data-name='Trazado 1' />
        </g>
      </g>
    </svg>
  );
};
