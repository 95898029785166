import { FC, PropsWithChildren, useState } from 'react';
import { Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Div100vh from 'react-div-100vh';

type FullModalLayoutProps = {
  withoutBackground?: boolean;
  onClose: () => void;
};

export const FullModalLayout: FC<FullModalLayoutProps & PropsWithChildren> = ({
  children,
  withoutBackground = false,
  onClose,
}): JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleClose = (): void => {
    setOpen(true);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        sx: {
          backgroundColor: '#000',
        },
      }}
    >
      <Div100vh
        style={{
          backgroundColor: withoutBackground
            ? 'transparent'
            : theme.palette.background.default,
          padding: '62px 0',
          overflowY: 'scroll',
        }}
      >
        {children}
      </Div100vh>
    </Modal>
  );
};
