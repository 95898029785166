import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';

import { useSuggestionsFormManagement } from '../hooks';
import { FormTextField, BackdropLoading } from '../components';
import { MessageModal } from '../modals';
import { changeActiveMenuItem, selectIsAuthenticated } from '../redux';

const keyPrefix = 'forms.SuggestionsForm';

export const SuggestionsForm: FC = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const isAuth = useSelector(selectIsAuthenticated);
  const navigation = useNavigate();

  const {
    canShowSendButton,
    genericErr,
    loading,
    methods,
    response,
    success,
    submit,
  } = useSuggestionsFormManagement();
  const dispatch = useDispatch();

  const goHome = (): void => {
    isAuth
      ? dispatch(changeActiveMenuItem({ activePage: 'home' }))
      : navigation('/login');
  };

  useEffect(() => {
    !!genericErr && setShowErrorMessage(true);
  }, [genericErr]);

  return (
    <>
      {!!response && (
        <MessageModal
          message={response}
          okLabel={t('modals.genericErr.ok')}
          title={success ? t('modals.success.title') : null}
          onClickOk={goHome}
          onClose={() => {
            setShowErrorMessage(false);
          }}
          showOkIcon
        />
      )}
      {showErrorMessage && (
        <MessageModal
          message={genericErr!}
          okLabel={t('modals.genericErr.ok')}
          onClickOk={() => {
            setShowErrorMessage(false);
          }}
          onClose={() => {
            setShowErrorMessage(false);
          }}
        />
      )}
      {loading && <BackdropLoading />}
      <Typography variant='h6' fontWeight={500}>
        {t('instructions')}
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Stack spacing={2} pt={3}>
            <FormTextField
              name='email'
              label={t('email.label')}
              inputProps={{ maxLength: 50 }}
            />
            <FormTextField
              name='comments'
              label={t('comments.label')}
              inputProps={{ maxLength: 50 }}
              isTextArea={true}
            />

            <Stack direction='row' pt={4} spacing={2}>
              <Button
                type='submit'
                variant='contained'
                disabled={!canShowSendButton}
                fullWidth
              >
                {t('submit.label')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
