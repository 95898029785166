import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, MessagePayload } from 'firebase/messaging';
import { useToken } from 'react-firebase-hooks/messaging';

import {
  selectSession,
  // useGetPromosMutation,
  useLazyGetPropertiesQuery,
  useLogoutMutation,
  useRegisterTokenMutation,
} from '../redux';
import { NotificationData, VendamosNotification } from '../types';
import { NotificationType } from '../enums';
import { useContentBadges } from './useContentBadges';

const firebaseConfig = {
  apiKey: 'AIzaSyDvizq-40midSdLXUYUvz3lY7tG-MO_F_0',
  authDomain: 'claro-promociones-d6823.firebaseapp.com',
  projectId: 'claro-promociones-d6823',
  storageBucket: 'claro-promociones-d6823.appspot.com',
  messagingSenderId: '129503756840',
  appId: '1:129503756840:web:11951eaa7e7abd2b9b2b1d',
};

const vapikey =
  'BAYUYrgPBFG-fQjtmqTqtwmITfeIf0O9ql0uezJRofd_1Nie3U5rSIYlg22ZYlW9X0BozIYBkrrmlph_UHW7s_g';

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const useNotification = () => {
  const [token, , error] = useToken(messaging, vapikey);
  const [notifications, setNotifications] = useState<VendamosNotification[]>(
    []
  );
  const [registerFcmToken] = useRegisterTokenMutation();
  const session = useSelector(selectSession);
  // const [getPromos] = useGetPromosMutation();
  const [getProperties] = useLazyGetPropertiesQuery();
  const [logoutMutation] = useLogoutMutation();
  const { updateBadgesWithTag } = useContentBadges();

  const readNotification = async ({
    contentId,
    contentType,
    imageUrl,
    message,
    notificationType,
    subContentType,
    title,
  }: NotificationData) => {
    switch (notificationType) {
      case NotificationType.CONTENT_UPDATED:
        const tag = `${contentType}-${subContentType}-${contentId}`;
        console.log('Content updated');
        // await getPromos(session);
        setNotifications(list => [
          ...list,
          {
            title,
            body: message,
            icon: imageUrl,
            image: imageUrl,
            tag,
          } as VendamosNotification,
        ]);
        await updateBadgesWithTag(tag);
        break;
      case NotificationType.CONTENT_DELETE:
        console.log('CONTENT_DELETE');
        // await getPromos(session);
        break;
      case NotificationType.APP_RESET:
        console.log('APP_RESET');
        await getProperties({});
        // await getPromos(session);
        break;
      case NotificationType.APP_UPDATE:
        console.log('APP_UPDATE');
        localStorage.removeItem('persist:root');
        localStorage.clear();
        await logoutMutation(session.username!);
        break;
    }
  };

  const onMessageListener = (): Promise<MessagePayload> =>
    new Promise(resolve => {
      onMessage(messaging, (payload: MessagePayload) => {
        resolve(payload);
      });
    });

  const refreshNotifications = async (data: NotificationData) => {
    // await getPromos(session);
    await readNotification(data);
  };

  const listening = () => {
    console.log('watting for new notification...');
    onMessageListener()
      .then(payload => {
        console.log('new notification received with payload', payload);
        // await getPromos(session);
        // readNotification(payload.data as unknown as NotificationData);
        refreshNotifications(payload.data as unknown as NotificationData);
      })
      .catch(err => console.error('failed to receive notification:', err))
      .finally(() => {
        listening();
      });
  };

  const updateFcmTokenInBackend = async () => {
    const { username } = session;
    console.log(
      `updating FCM Token wirh username ${username} with token ${token}`
    );
    registerFcmToken({ token: token!, username });
  };

  const removeNotification = (notif: VendamosNotification) => {
    setNotifications(list => list.filter(n => n !== notif));
  };

  useEffect(() => {
    if (!!token) {
      updateFcmTokenInBackend();
      listening();
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    !!error && console.error("Can't show notifications ", error);
  }, [error]);

  return {
    notifications,
    removeNotification,
  };
};
