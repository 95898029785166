import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  authApi,
  documentApi,
  favoritesApi,
  fcmTokensApi,
  helpApi,
  incentivesApi,
  localsApi,
  promosApi,
  propertiesApi,
  reactionsApi,
  recoveryPasswordApi,
  reportsApi,
  resetPasswordApi,
  userApi,
} from './services';
import {
  attemptReducer,
  authReducer,
  contentBadgesReducer,
  incentivesReducer,
  menuReducer,
  promosReducer,
  propertiesReducer,
  reportsReducer,
} from './features';

const reducer = combineReducers({
  attempt: attemptReducer,
  auth: authReducer,
  contentBadges: contentBadgesReducer,
  incentives: incentivesReducer,
  menu: menuReducer,
  promos: promosReducer,
  properties: propertiesReducer,
  reports: reportsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [fcmTokensApi.reducerPath]: fcmTokensApi.reducer,
  [helpApi.reducerPath]: helpApi.reducer,
  [incentivesApi.reducerPath]: incentivesApi.reducer,
  [localsApi.reducerPath]: localsApi.reducer,
  [promosApi.reducerPath]: promosApi.reducer,
  [propertiesApi.reducerPath]: propertiesApi.reducer,
  [reactionsApi.reducerPath]: reactionsApi.reducer,
  [recoveryPasswordApi.reducerPath]: recoveryPasswordApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['attempt'],
  },
  reducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(incentivesApi.middleware)
      .concat(favoritesApi.middleware)
      .concat(documentApi.middleware)
      .concat(localsApi.middleware)
      .concat(promosApi.middleware)
      .concat(propertiesApi.middleware)
      .concat(recoveryPasswordApi.middleware)
      .concat(fcmTokensApi.middleware)
      .concat(reactionsApi.middleware)
      .concat(reportsApi.middleware)
      .concat(resetPasswordApi.middleware)
      .concat(userApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
setupListeners(store.dispatch);
