import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { selectIsBlockedUser, selectLoginAttempts } from '../../redux';
import { ErrorAlert } from '../basics';

const keyPrefix = 'components.form-outputs.LoginFormErrorAlert';

export const LoginFormErrorAlert: FC = (): JSX.Element => {
  const loginAttempts = useSelector(selectLoginAttempts);

  return (
    <ErrorAlert>
          <strong>
            <Trans
              i18nKey={`${keyPrefix}.${loginAttempts! > 1
                  ? 'attemps'
                  : loginAttempts === 1
                    ? 'OneAttempt'
                    : 'ZeroAttempts'
                }`}
              values={{ attemptsLeft: loginAttempts }}
            />
          </strong>
    </ErrorAlert>
  );
};
