import moment from 'moment';
import 'moment/locale/es';

import { MediaType } from '../../enums';
import { Media } from '../../types';

const formatDateReceived = 'DD-MMM-YYYY';

export const parseValidity = (
  startDate: string,
  endDate: string,
  validityExpression: string
) => {
  const momentEndDate = moment(endDate, formatDateReceived);
  const endYear = momentEndDate.year();
  const endMonth = momentEndDate.format('MMM');

  const formattedMomentStartDate = moment(startDate, formatDateReceived)
    .format('ll')
    .replaceAll(' de', '')
    .replace(`${endMonth} ${endYear}`, '')
    .replace('.', '')
    .replace(` ${endYear}`, '');
  const formattedMomentEndDate = momentEndDate
    .format('ll')
    .replaceAll(' de', '')
    .replace('.', '');
  return validityExpression
    .replace('{initDate}', formattedMomentStartDate)
    .replace('{endDate}', formattedMomentEndDate);
};

export const getImageCoverURL = (mediaType: string, media: Media[]): string => {
  switch (mediaType) {
    // case MediaType.IMAGE:
    // case MediaType.MAGAZINE:
    //   return media[0].url;
    case MediaType.VIDEO:
      return media[0].urlVideoThumbnail;
    case MediaType.VIDEO_IMAGE:
      return media[1].url;
    default:
      return media[0].url;
  }
};

export const hasVideoThumbnail = (media: Media[]): boolean =>
  media.filter(({ urlVideoThumbnail }) => !!urlVideoThumbnail).length > 0;

export const hasMagazine = (media: Media[]): boolean =>
  media.filter(({ urlVideoThumbnail }) => !urlVideoThumbnail).length > 0;

export const getVideoData = (media: Media[]): [string, string] => {
  let videoUrl: string = '';
  let videoPoster: string = '';

  media.forEach(({ url, urlVideoThumbnail }) => {
    !!urlVideoThumbnail &&
      (videoUrl = url) &&
      (videoPoster = urlVideoThumbnail);
  });
  return [videoUrl, videoPoster];
};

export const getMediaImage = (
  mediaList: Media[],
  withPriority: number | null
): string | null => {
  let urlFound: string | null = null;
  if (!!mediaList && mediaList.length > 0) {
    mediaList.forEach(({ url, priority }) => {
      if (priority === withPriority) {
        urlFound = url;
        return;
      }
    });
  }

  return urlFound;
};

export const getMedia = (
  mediaList: Media[],
  withPriority: number | null
): Media | null => {
  let mediaFound: Media | null = null;
  if (!!mediaList && mediaList.length > 0) {
    mediaList.forEach(media => {
      const { priority } = media;
      if (priority === withPriority) {
        mediaFound = media;
        return;
      }
    });
  }

  return mediaFound;
};
