import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { RegisterParams } from '../../types';
import { getAppInfo } from './appInfo';

export const fcmTokensApi = createApi({
  reducerPath: 'fcmTokensApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    registerToken: builder.mutation({
      query: ({ username, token }: RegisterParams) => {
        return {
          url: 'v2/registertoken',
          method: 'POST',
          body: { appInfo: getAppInfo(), username, token },
        };
      },
    }),
  }),
});

export const { useRegisterTokenMutation } = fcmTokensApi;
