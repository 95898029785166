import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  // savePass,
  selectHashedPassword,
  selectSession,
  useLogoutMutation,
  useResetPasswordMutation,
} from '../../redux';
import sha256 from 'crypto-js/sha256';

export interface ResetPasswordFormFields {
  actualPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const keyPrefix = 'hooks.formManagements.useResetPasswordFormManagement';

const defaultValues: ResetPasswordFormFields = {
  actualPassword: '****',
  newPassword: '',
  confirmPassword: '',
};

export const useResetPasswordFormManagement = () => {
  const [fieldsValidationErr, setFieldsValidationErr] = useState<
    string | null
  >();
  const [genericErr, setGenericErr] = useState<string | null>();
  const { t } = useTranslation('translation', { keyPrefix });
  const session = useSelector(selectSession);
  const actualHashedPassword = useSelector(selectHashedPassword);

  const [resetPassword, { isError, error: requestErr, isSuccess }] =
    useResetPasswordMutation();

  const [logoutMutation] = useLogoutMutation();

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t('schema.newPassword.required'))
      .min(4, t('schema.newPassword.min')),
    confirmPassword: yup
      .string()
      .required(t('schema.confirmPassword.required'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('schema.confirmPassword.confirmation')
      ),
  });

  const methods = useForm<ResetPasswordFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (isError) {
      console.error(requestErr);
      setGenericErr(t('errors.apiError'));
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    const { formState: errors } = methods;
    setFieldsValidationErr(
      errors.errors.newPassword?.message ||
        errors.errors.confirmPassword?.message ||
        null
    );
    // eslint-disable-next-line
  }, [methods.formState.errors]);

  useEffect(() => {
    isSuccess && logoutMutation(session.username!);

    // eslint-disable-next-line
  }, [isSuccess]);

  const submit: SubmitHandler<ResetPasswordFormFields> = async fields => {
    clearErrorMessages();

    const newHashedPassword = sha256(fields.newPassword).toString();
    console.log('newHashedPassword', newHashedPassword);

    if (actualHashedPassword === newHashedPassword) {
      setGenericErr(t('errors.samePass'));
    } else {
      await resetPassword({ fields, session });
      // dispatch(savePass(fields.newPassword));
    }
  };

  const clearErrorMessages = (): void => {
    setGenericErr(null);
    setFieldsValidationErr(null);
  };

  const logout = async (): Promise<void> => {
    await logoutMutation(session.username!);
  };

  return {
    // loading,
    fieldsValidationErr,
    genericErr,
    methods,
    clearErrorMessages,
    logout,
    submit,
  };
};
