import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { ModalLayout } from '../../layouts';
import { useTranslation } from 'react-i18next';

type CancelIncentiveConfirmationModalProps = {
  onClickYes: () => void;
  onClickNo: () => void;
};

const keyPrefix = 'modals.CancelIncentiveConfirmationModal';

export const CancelIncentiveConfirmationModal: FC<
  CancelIncentiveConfirmationModalProps
> = ({ onClickNo, onClickYes }): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  const noop = () => {};

  return (
    <ModalLayout onClose={noop} keepOpened>
      <Stack justifyContent='center' mx='29px' rowGap={3}>
        <Typography variant='h3' textAlign='center'>
          {t('title')}
        </Typography>
        <Box width='370px' margin='0 auto'>
          <Button variant='contained' onClick={onClickYes} fullWidth>
            {t('yes')}
          </Button>
        </Box>
        <Box width='370px' margin='0 auto'>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClickNo}
            fullWidth
          >
            {t('cancel')}
          </Button>
        </Box>
      </Stack>
    </ModalLayout>
  );
};
