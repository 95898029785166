import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import MainRouter from './routes';
import { store } from './redux';
import { LoadFonts } from './fontConfig';
import './i18n';

let persistor = persistStore(store);

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <LoadFonts />
      <MainRouter />
    </PersistGate>
  </Provider>
);
export default App;
