import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { grey, teal } from '@mui/material/colors';

import { IconFavoriteSvg } from '../../assets';
import {
  selectSession,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} from '../../redux';
import { BackdropLoading } from '../basics';

const keyPrefix = 'components.content.FavoriteButton';

type FavoriteButtonProps = {
  idContent: number;
  justifyContent?: 'flex-start' | 'ceneter';
  initialState?: boolean;
};

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  idContent,
  initialState = false,
  justifyContent = 'center',
}): JSX.Element => {
  const [add, { isLoading: addLoading }] = useAddFavoriteMutation();
  const [remove, { isLoading: removeLoading }] = useRemoveFavoriteMutation();
  const { t } = useTranslation('translation', { keyPrefix });
  const session = useSelector(selectSession);
  const [saved, setSaved] = useState<boolean>(initialState);

  const handleClick = async () => {
    if (saved) await remove({ session, idContent });
    else await add({ session, idContent });

    setSaved(value => !value);
  };

  useEffect(() => {
    setSaved(initialState);
  }, [initialState]);

  return (
    <>
      {(addLoading || removeLoading) && <BackdropLoading />}
      <Button
        color='inherit'
        sx={{
          minWidth: '300px',
          textTransform: 'none',
          fontSize: '20px',
          color: !!saved ? teal[600] : grey[600],
          fontWeight: !!saved ? 'bold' : 'normal',
          justifyContent,
        }}
        onClick={handleClick}
        startIcon={<IconFavoriteSvg on={saved} width={34} />}
      >
        <Box sx={{ paddingTop: '6px' }}>{t(`label.${saved}`)}</Box>
      </Button>
    </>
  );
};
