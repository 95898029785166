import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { ResetPasswordFormFields } from '../../hooks';
import { getAppInfo } from './appInfo';
import { Session } from '../../types';

type Params = {
  fields: ResetPasswordFormFields;
  session: Session;
};

export const resetPasswordApi = createApi({
  reducerPath: 'resetPasswordApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    resetPassword: builder.mutation({
      query: ({
        fields: { newPassword },
        session: { sessionToken, userId },
      }: Params) => {
        return {
          url: 'resetpassword',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            newPassword,
          },
        };
      },
    }),
  }),
});

export const { useResetPasswordMutation } = resetPasswordApi;
