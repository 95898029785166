import { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { FavoriteItem } from './FavoriteItem';
import { useSelector } from 'react-redux';
import { selectFavorites } from '../../redux';

export const FavoritesGrid: FC = ({}): JSX.Element => {
  const favoriteList = useSelector(selectFavorites);

  return (
    <Stack spacing={3}>
      <Typography variant='h4'>Favoritos</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={2}>
          {favoriteList.map((favorite, key) => (
            <Grid item key={key} md={12}>
              <FavoriteItem {...favorite} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};
