import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { ValidDocumentParams } from '../../types';

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    validateDocument: builder.mutation({
      query: (identification: ValidDocumentParams) => {
        return {
          url: 'validdocument',
          method: 'POST',
          body: { identification },
        };
      },
    }),
  }),
});

export const { useValidateDocumentMutation } = documentApi;
