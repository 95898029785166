import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Question } from '../../../types';
// import { IndexIndicator } from '../IndexIndicator';
import { SurveyAnswersGrid } from './SurveyAnswersGrid';
import { SurveyComments } from './SurveyComments';

type SurveyQuestionSliderProps = {
  comments: string | null;
  questions: Question[];
  // onFinishSurvey: (answers: number[]) => void;
  onFinishSurvey: (
    answers: number[],
    openAnswers: string[],
    extraAnswers: string[][],
    comment: string | null
  ) => void;
};

const keyPrefix = 'components.incentives.SurveyQuestionSlider';

export const SurveyQuestionSlider: FC<SurveyQuestionSliderProps> = ({
  comments,
  questions,
  onFinishSurvey,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [idx, setIdx] = useState<number>(0);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [givenExtraAnswers, setGivenExtraAnswers] = useState<string[][]>([[]]);
  const [givenOpenAnswers, setGivenOpenAnswers] = useState<string[]>([]);
  const [givenAnswers, setGivenAnswers] = useState<number[]>(
    Array.from({ length: questions.length })
  );
  const [showComments, setShowComments] = useState<boolean>(false);

  const selectAnswer = (answerIdx: number, questionIdx: number): void => {
    setCanContinue(
      answerIdx > -1 &&
        (!questions[questionIdx].answers[answerIdx].extraQuestion ||
          !!givenExtraAnswers[answerIdx])
    );

    const newGivenAnswers = [...givenAnswers];
    newGivenAnswers[questionIdx] = answerIdx;
    setGivenAnswers(newGivenAnswers);
  };

  const onOpenQuestionValueChange = (newValue: string): void => {
    setCanContinue(newValue.trim().length > 0);
    const newGivenOpenAnswers = [...givenOpenAnswers];
    newGivenOpenAnswers[idx] = newValue;
    setGivenOpenAnswers(newGivenOpenAnswers);
  };

  const onExtraQuestionValueChange = (
    newValue: string,
    answerId: number
  ): void => {
    const newGivenExtraAnswers = [...givenExtraAnswers];
    newGivenExtraAnswers[idx] = [];
    newGivenExtraAnswers[idx][answerId] = newValue.trim();
    setGivenExtraAnswers(newGivenExtraAnswers);
    setCanContinue(newGivenExtraAnswers[idx].length > 0);
  };

  const finishSurvey = () => {
    if (!!comments && comments !== 'null') {
      setShowComments(true);
    } else {
      onFinishSurvey(givenAnswers, givenOpenAnswers, givenExtraAnswers, null);
    }
  };

  const finishComments = (givenComment: string) => {
    onFinishSurvey(
      givenAnswers,
      givenOpenAnswers,
      givenExtraAnswers,
      givenComment
    );
  };

  const onClickNext = () => {
    setCanContinue(false);
    const newIdx = idx + 1;
    idx === questions.length - 1 ? finishSurvey() : setIdx(newIdx);
  };

  return (
    <>
      {/* <IndexIndicator total={questions.length} idx={idx} /> */}
      {!!showComments ? (
        <SurveyComments comments={comments!} onClickSend={finishComments} />
      ) : (
        !!questions &&
        idx <= questions.length && (
          <Stack spacing={1}>
            <Typography variant='body1' pt={2} pb={4}>
              {questions[idx].question}
            </Typography>

            <Stack>
              {questions.map((question, questionIdx) => (
                <Box hidden={idx !== questionIdx}>
                  <SurveyAnswersGrid
                    answers={question.answers}
                    media={question.media}
                    onSelectAnswer={answerIdx => {
                      selectAnswer(answerIdx, idx);
                    }}
                    onOpenQuestionValueChange={onOpenQuestionValueChange}
                    onExtraQuestionValueChange={onExtraQuestionValueChange}
                  />
                </Box>
              ))}
              <Box width='370px' margin='32px auto'>
                <Button
                  variant='contained'
                  fullWidth
                  disabled={!canContinue}
                  onClick={onClickNext}
                  sx={{
                    '&.Mui-disabled': {
                      color: 'white',
                      backgroundColor: '#ec342f',
                      opacity: 0.4,
                    },
                  }}
                >
                  {t('next')}
                </Button>
              </Box>
            </Stack>
          </Stack>
        )
      )}
    </>
  );
};
