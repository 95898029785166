import { FC, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { ModalLayout } from "../layouts";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { selectLoginAttempts, selectPropertiesApp } from "../redux";
import { Trans } from "react-i18next";
import theme from "../theme";

const keyPrefix = "components.form-outputs.LoginFormErrorModal";

type MessageModalProps = {
  message: string;
  okLabel: string;
  timer?: boolean;
  showOkIcon?: boolean;
  title?: string | null;
  onClickOk: () => void;
  onClose: () => void;
};

export const MessageModalTimer: FC<MessageModalProps> = ({
  message,
  okLabel,
  timer = false,
  showOkIcon = false,
  onClose,
  onClickOk,
}): JSX.Element => {
  const properties = useSelector(selectPropertiesApp);
  const timerModal = parseInt(properties["login.modal"]);
  const [countdown, setCountdown] = useState(timerModal);

  useEffect(() => {
    let interval: NodeJS.Timer | NodeJS.Timeout | undefined;
    if (timer) {
      setCountdown(timerModal);
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  // const [countdown, setCountdown] = useState(timer);
  return (
    <ModalLayout onClose={onClose}>
      <Stack
        display="grid"
        direction="row"
        sx={{ gridTemplateRows: "1fr 60px" }}
        zIndex={90000}
      >
        {showOkIcon && (
          <CheckCircleOutlineIcon
            color="success"
            sx={{ fontSize: "54px", marginBottom: 4 }}
          />
        )}
        <Typography variant="h5" fontSize="28px" pb={1}>
          <Trans i18nKey={`${keyPrefix}.title`} />
        </Typography>
        <Typography fontSize="26px">{message}</Typography>
        {timer && (
          <Typography fontSize="22px" mt={14} mb={4} color={"#f22b26"}>
            Espera: {countdown}s
          </Typography>
        )}
        <Box
          display="flex"
          justifyContent="center"
          // justifyItems='flex-end'
          // alignItems='flex-ends'
          mt={timer ? 0 : 6}
          alignSelf="end"
        >
          <Button
            variant="contained"
            onClick={onClickOk}
            disabled={timer && countdown > 0}
            sx={{ minWidth: 275 }}
          >
            {okLabel}
          </Button>
        </Box>
      </Stack>
    </ModalLayout>
  );
};
