import React from 'react';

export const IconUtilSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
      <g data-name='Grupo 293'>
        <g data-name='Grupo 292'>
          <path
            fill='#fd8e02'
            d='M19.95 25.383h-8.026a1 1 0 01-1-1v-1.428a3.722 3.722 0 00-1.382-2.861 10.048 10.048 0 01-3.621-7.214 10.069 10.069 0 012.741-7.327 10.049 10.049 0 017.122-3.206 9.748 9.748 0 017.2 2.882 9.959 9.959 0 012.994 7.148 9.985 9.985 0 01-3.672 7.748 3.647 3.647 0 00-1.344 2.83v1.428a1 1 0 01-1.012 1zm-7.023-2.007h6.02v-.424a5.648 5.648 0 012.079-4.382 7.986 7.986 0 002.938-6.2 7.97 7.97 0 00-2.395-5.718 7.815 7.815 0 00-5.758-2.306 8.039 8.039 0 00-5.689 2.571 8.054 8.054 0 00-2.2 5.856 8.029 8.029 0 002.9 5.767 5.723 5.723 0 012.108 4.407z'
            data-name='Trazado 142'
          />
        </g>
      </g>
      <g data-name='Grupo 296'>
        <path fill='none' d='M.001 0h32v32h-32z' data-name='Rect\xE1ngulo 94' />
      </g>
      <g data-name='Grupo 401'>
        <g data-name='Grupo 291'>
          <path
            fill='#fd6c02'
            d='M19.95 29.094h-8.026a1 1 0 01-1-1 1 1 0 011-1h8.026a1 1 0 011 1 1 1 0 01-1 1z'
            data-name='Trazado 141'
          />
        </g>
        <g data-name='Grupo 294'>
          <path
            fill='#fd6c02'
            d='M20.55 21.67h-9.237a1 1 0 01-1-1 1 1 0 011-1h9.237a1 1 0 011 1 1 1 0 01-1 1z'
            data-name='Trazado 143'
          />
        </g>
        <path
          fill='#fd6c02'
          stroke='rgba(0,0,0,0)'
          strokeMiterlimit={10}
          d='M19.944 25.382h-8.021a1 1 0 01-1-1v-1.429a3.631 3.631 0 00-.579-1.952h2.23a5.581 5.581 0 01.352 1.952v.423h6.018v-.423a5.639 5.639 0 01.348-1.952h2.225a3.645 3.645 0 00-.564 1.952v1.427a1 1 0 01-1.009 1.002z'
          data-name='Sustracci\xF3n 2'
        />
      </g>
    </svg>
  );
};
