import { useDispatch } from "react-redux"
import { clearLoginData } from "../redux";

export const useClearLoginInfo = () => {
  const dispatch = useDispatch();
  const clearDataLogin = () => {
    dispatch(clearLoginData())
  }

  return {
    clearDataLogin
  }
}