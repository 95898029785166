import { useTranslation } from 'react-i18next';
import { Box, Container, Stack, Typography } from '@mui/material';
import { ResetPasswordForm } from '../forms';

const keyPrefix = 'pages.ChangePasswordPage';

export const ChangePasswordPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Container maxWidth='lg'>
      <Box padding='32px 105px'>
        <Stack alignItems='center'>
          <Box width='582px'>
            <Typography variant='h4' my={2}>
              {t('title')}
            </Typography>
            <Typography variant='body1'> {t('instructions')}</Typography>
          </Box>
          <Box width='582px'>
            <ResetPasswordForm />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
