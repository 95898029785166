import { FC, useEffect, useState } from "react";
import { Box, CardMedia, Fab } from "@mui/material";

import { IconLeftArrowSvg, IconRightArrowSvg } from "../../assets";
import { Media } from "../../types";
import { ModalViewerZoomLayout } from "../../layouts";
import { useDispatch } from "react-redux";
import { changeCurrentIndexImage } from "../../redux";

type MagazineViewProps = {
  media?: Media[];
  onMediaChange: (media: Media) => void;
};

export const MagazineViewer: FC<MagazineViewProps> = ({
  media,
  onMediaChange,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [idx, setIdx] = useState<number>(0);
  const [urls, setUrls] = useState<string[]>([]);

  useEffect(() => {
    if (!!media && media.length > 0) {
      const newUrls: string[] = [];
      media.forEach(({ url, urlVideoThumbnail }) => {
        !urlVideoThumbnail && newUrls.push(url);
      });

      setUrls(newUrls);
    }
  }, [media]);

  useEffect(() => {
    onMediaChange(media![idx]);

    return () => {
      dispatch(changeCurrentIndexImage(0))
    }
    // eslint-disable-next-line
  }, [idx]);

  const canBack = idx > 0;
  const canForward = idx < urls.length - 1;
  const showArrows = urls.length > 1;

  const back = (): void => {
    setIdx((idx) => (canBack ? idx - 1 : idx));
  };

  const next = (): void => {
    setIdx((idx) => (canForward ? idx + 1 : idx));
  };

  useEffect(() => {
    dispatch(changeCurrentIndexImage(idx))
  }, [idx])
  

  return (
    <>
      <Box position="relative">
        {showArrows && (
          <Fab
            aria-label="add"
            sx={{
              position: "absolute",
              top: "300px",
              left: "-75px",
              width: "50px",
              height: "50px",
              opacity: canBack ? 1 : 0.3,
              svg: {
                width: "44px",
                height: "44px",
              },
            }}
            disabled={!canBack}
            onClick={back}
          >
            <IconLeftArrowSvg />
          </Fab>
        )}
        {urls!.map((url, i) => (
          <CardMedia
            key={i}
            component="img"
            src={url}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
              display: idx === i ? "block" : "none",
              cursor: "pointer"
            }}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        ))}
        {showArrows && (
          <Fab
            aria-label="add"
            sx={{
              position: "absolute",
              top: "300px",
              right: "-75px",
              width: "50px",
              height: "50px",
              opacity: canForward ? 1 : 0.3,
              svg: {
                width: "44px",
                height: "44px",
              },
            }}
            onClick={next}
            disabled={!canForward}
          >
            <IconRightArrowSvg />
          </Fab>
        )}
      </Box>
      <ModalViewerZoomLayout
        show={openModal}
        data={urls}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};
