import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectContents } from '../redux';
import { Content } from '../types';

export const useProductPage = (contentId: number | undefined) => {
  const contents = useSelector(selectContents);
  const [productList, setProductList] = useState<Content[]>([]);

  const getData = (): void => {
    const newProductList: Content[] = contents.filter(
      ({ subContentType }) => subContentType === contentId
    );
    setProductList(newProductList);
  };

  useEffect(() => {
    !!contentId && contents.length > 0 && getData();
    // eslint-disable-next-line
  }, [contents, contentId]);

  return productList;
};
