import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  InputBaseProps,
  Radio,
  RadioGroup,
} from "@mui/material";

import { TextField } from "../basics";
import { ChangeEvent } from "react";

type Props = {
  items: TypeRadio[];
  label?: string;
  name: string;
};

export type TypeRadio = {
  label: string;
  value: string;
};

export const FormRadio: React.FC<Props> = ({
  items,
  name,
}): JSX.Element => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const newValue = event.target.value;
          
          if (newValue !== undefined) {
            !!onChange && onChange(newValue);
            setValue(name, newValue);
          }
        };

        return (
          <FormControl component="fieldset">
            <RadioGroup
              row
              name={name}
              value={value}
              onChange={handleOnChange}
            >
              {items.map(({ label, value }, index) => (
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
};
