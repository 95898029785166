import { useState } from 'react';
import {
  FormGroup,
  InputBaseProps,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';

import { LabelText } from './LabelText';

type ExtraProps = { onlyNumbers?: boolean; disabledErrors?: boolean };

type Props = TextFieldProps & InputBaseProps['inputProps'] & ExtraProps;

export const TextField: React.FC<Props> = (props): JSX.Element => {
  const { label, error, multiline, disabledErrors = false } = props;

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleFocus = (): void => setIsFocus(true);
  const handleOnblur = (): void => setIsFocus(false);

  return (
    <FormGroup sx={{ height: !!multiline ? '225px' : '128px' }}>
      {!!label && (
        <LabelText focus={isFocus} mb={1} error={!disabledErrors && error}>
          {label}
        </LabelText>
      )}
      <MuiTextField
        {...props}
        label=''
        variant='outlined'
        color='secondary'
        onFocus={handleFocus}
        onBlur={handleOnblur}
        error={!disabledErrors && error}
        {...(disabledErrors ? { helperText: '' } : {})}
      />
    </FormGroup>
  );
};
