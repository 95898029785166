import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import saveAs from 'file-saver';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';

import { IconDownloadSvg } from '../../assets';
import { selectSession, useReportContentDownloadedMutation } from '../../redux';

const keyPrefix = 'components.content.DownloadVideoButton';

type DownloadVideoButtonProps = {
  contentId: number;
  downloadUrl?: string;
};

export const DownloadVideoButton: FC<DownloadVideoButtonProps> = ({
  contentId,
  downloadUrl,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const session = useSelector(selectSession);
  const [reportDownloaded] = useReportContentDownloadedMutation();

  // console.log('downloadUrl', downloadUrl);

  const filenName =
    downloadUrl?.substring(
      downloadUrl?.lastIndexOf('/') + 1,
      downloadUrl!.length
    ) || 'file.mp4';

  const handleClick = async () => {
    await saveAs(downloadUrl!, filenName);
    reportDownloaded({ session, contentId });
  };

  return (
    <Button
      color='inherit'
      sx={{
        minWidth: '250px',
        textTransform: 'none',
        fontSize: '20px',
        color: grey[600],
      }}
      onClick={handleClick}
      startIcon={<IconDownloadSvg width={34} />}
    >
      <Box sx={{ paddingTop: '6px' }}>{t(`label`)}</Box>
    </Button>
  );
};
