import { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';

import { useResetPasswordFormManagement } from '../hooks';
import { FieldsValidationErrorAlert, FormTextField } from '../components';
import { MessageModal } from '../modals';
import { useSelector } from 'react-redux';
import { selectRequireResetPassword } from '../redux';

const keyPrefix = 'forms.ResetPasswordForm';

export const ResetPasswordForm: FC = (): JSX.Element => {
  const requireRestorePassword = useSelector(selectRequireResetPassword);
  const { t } = useTranslation('translation', { keyPrefix });
  const [showErroMessage, setShowErroMessage] = useState<boolean>(false);
  const { fieldsValidationErr, genericErr, methods, logout, submit } =
    useResetPasswordFormManagement();

  useEffect(() => {
    !!genericErr && setShowErroMessage(true);
  }, [genericErr]);

  return (
    <>
      {showErroMessage && (
        <MessageModal
          message={genericErr!}
          okLabel={t('modals.genericErr.ok')}
          onClickOk={() => {
            setShowErroMessage(false);
          }}
          onClose={() => {
            setShowErroMessage(false);
          }}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Stack spacing={2} pt={2}>
            {fieldsValidationErr && (
              <FieldsValidationErrorAlert error={fieldsValidationErr} />
            )}
            <FormTextField
              name='actualPassword'
              label={t('actualPassword.label')}
              disabled={true}
              inputProps={{ maxLength: 4 }}
            />
            <FormTextField
              name='newPassword'
              label={t('newPassword.label')}
              inputProps={{ maxLength: 4 }}
              type='password'
            />
            <FormTextField
              name='confirmPassword'
              label={t('confirmPassword.label')}
              inputProps={{ maxLength: 4 }}
              type='password'
            />
            <Stack direction='row' pt={2} spacing={2}>
              {requireRestorePassword && (
                <Button
                  type='button'
                  color='secondary'
                  variant='outlined'
                  onClick={logout}
                  fullWidth
                >
                  {t('cancel.label')}
                </Button>
              )}
              <Button type='submit' variant='contained' fullWidth>
                {t('submit.label')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
