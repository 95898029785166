import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '@mui/material/styles';

import { Question } from '../../../types';

type TriviaResponseCardProps = {
  backCoverTextButton?: string;
  givenAnswers: number[];
  questions: Question[];
  onClickOk: () => void;
};

const keyPrefix = 'components.incentives.TriviaResponseCard';

export const TriviaResponseCard: FC<TriviaResponseCardProps> = ({
  backCoverTextButton,
  givenAnswers,
  questions,
  onClickOk,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Card sx={{ width: 674, minHeight: 652 }}>
      <Stack justifyContent='space-between' height='100%' pb={7}>
        <CardContent>
          <Stack spacing={1}>
            <Typography variant='h5' py={2}>
              {t('title')}
            </Typography>
            <Stack gap={5}>
              {questions.map(({ orden, question, answers }, questionId) => {
                let wasAnsweredRight = false;
                const selectedAnswerId = givenAnswers[questionId];

                return (
                  <Stack gap={3} key={questionId}>
                    <Typography fontSize='24px'>{`${orden}. ${question}`}</Typography>
                    {answers.map(({ answer, correct }, answerId) => {
                      correct &&
                        selectedAnswerId === answerId &&
                        (wasAnsweredRight = true);

                      return correct ? (
                        <>
                          <GivenAnswer answer={answer} correct={true} />
                          {!wasAnsweredRight && (
                            <GivenAnswer
                              answer={answers[selectedAnswerId].answer}
                              correct={false}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </CardContent>
        <Box px={4} pt={4}>
          <Button variant='contained' fullWidth onClick={onClickOk}>
            {backCoverTextButton}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

type GivenAnswerProps = {
  answer: string;
  correct: boolean;
};

const GivenAnswer: FC<GivenAnswerProps> = ({
  answer,
  correct,
}): JSX.Element => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: '70px',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        border: `3px solid ${correct ? theme.palette.success.main : 'red'}`,
      }}
    >
      <Typography
        width='100%'
        textAlign='left'
        px='32px'
        fontWeight='bold'
        sx={{
          color: correct ? theme.palette.success.main : 'red',
        }}
      >
        <Stack direction='row' gap={1}>
          <CheckCircleOutlineIcon />
          {answer}
        </Stack>
      </Typography>
    </Card>
  );
};
