import { FC, PropsWithChildren, useState } from 'react';
import { Box, Modal } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 627,
  maxHeight: '90%',
  bgcolor: 'background.paper',
  borderRadius: '41px',
  boxShadow: 24,
  p: 4,
  padding: '46px 68px',
};

const expandedStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 627,
  maxHeight: '90%',
  bgcolor: 'background.paper',
  borderRadius: '41px',
  boxShadow: 24,
  p: 4,
  padding: '46px 32px',
};

type ModalLayoutProps = {
  keepOpened?: boolean;
  withExpandedStyle?: boolean;
  onClose?: () => void;
};

export const ModalLayout: FC<ModalLayoutProps & PropsWithChildren> = ({
  children,
  keepOpened = false,
  withExpandedStyle = false,
  onClose,
}): JSX.Element => {
  const [open, setOpen] = useState(true);

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown"): void => {
    if (reason === 'backdropClick') return;
    setOpen(keepOpened);
    !!onClose && onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus>
      <Box sx={withExpandedStyle ? expandedStyle : style} overflow={'auto'}>{children}</Box>
    </Modal>
  );
};
