import React from 'react';

export const IconVideoSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <g data-name='Grupo 7231' transform='translate(-1227.619 -9461.351)'>
        <rect
          width={16}
          height={14.239}
          fill='none'
          stroke='#ffa617'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.6}
          data-name='Rect\xE1ngulo 4380'
          rx={2}
          transform='rotate(-90 5356.925 4124.426)'
        />
        <path
          fill='none'
          stroke='#ffa617'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.469}
          d='M1246.738 9467.351h1.6a1.5 1.5 0 011.5 1.5v9a1.5 1.5 0 01-1.5 1.5h-1.6'
          data-name='Trazado 5656'
        />
        <path
          fill='none'
          stroke='#ffa617'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.469}
          d='M1232.5 9479.351h-1.6a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5h1.6'
          data-name='Trazado 5657'
        />
        <g data-name='Grupo 7230'>
          <g data-name='Grupo 7229'>
            <g data-name='Grupo 7228'>
              <path
                fill='none'
                stroke='#ffa617'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
                d='M1239.023 9471.187l2.771 1.64a.61.61 0 010 1.05l-2.771 1.639a.609.609 0 01-.92-.524v-3.278a.61.61 0 01.92-.527z'
                data-name='Trazado 5658'
              />
            </g>
          </g>
        </g>
      </g>
      <path fill='none' d='M0 0h24v24H0z' data-name='Rect\xE1ngulo 4381' />
    </svg>
  );
};
