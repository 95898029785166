import React from 'react';

export const IconTrashSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 34' {...props}>
      <g data-name='Grupo 7369'>
        <g data-name='Grupo 7362'>
          <path
            fill='#455d7a'
            d='M26.581 13.983H7.843a2.553 2.553 0 01-2.55-2.549v-1.356a2.554 2.554 0 012.55-2.55h18.738a2.554 2.554 0 012.55 2.55v1.356a2.553 2.553 0 01-2.55 2.549zm-18.738-4.3a.4.4 0 00-.4.4v1.356a.4.4 0 00.4.4h18.738a.4.4 0 00.4-.4v-1.356a.4.4 0 00-.4-.4z'
            data-name='Trazado 5688'
          />
        </g>
        <g data-name='Grupo 7364'>
          <g data-name='Grupo 7363'>
            <path
              fill='#455d7a'
              d='M21.745 31.195H12.68a3.966 3.966 0 01-3.93-3.616L7.534 12.998a1.076 1.076 0 012.144-.179L10.894 27.4a1.8 1.8 0 001.786 1.644h9.065a1.78 1.78 0 001.786-1.644l1.215-14.581a1.076 1.076 0 012.144.179l-1.215 14.581a3.966 3.966 0 01-3.93 3.616z'
              data-name='Trazado 5689'
            />
          </g>
        </g>
        <g data-name='Grupo 7365'>
          <path
            fill='#455d7a'
            d='M22.949 9.68a1.075 1.075 0 01-.962-.594l-1.756-3.51a.356.356 0 00-.32-.2h-5.4a.36.36 0 00-.321.2l-1.754 3.51a1.076 1.076 0 01-1.925-.962l1.756-3.51a2.5 2.5 0 012.245-1.388h5.4a2.5 2.5 0 012.245 1.387l1.756 3.511a1.076 1.076 0 01-.962 1.556z'
            data-name='Trazado 5690'
          />
        </g>
        <g data-name='Grupo 7366'>
          <path
            fill='#455d7a'
            d='M17.213 26.892a1.075 1.075 0 01-1.076-1.076v-8.605a1.076 1.076 0 012.151 0v8.606a1.075 1.075 0 01-1.075 1.075z'
            data-name='Trazado 5691'
          />
        </g>
        <g data-name='Grupo 7367'>
          <path
            fill='#455d7a'
            d='M20.931 26.893h-.075a1.074 1.074 0 01-1-1.146l.585-8.606a1.075 1.075 0 112.146.145l-.585 8.606a1.076 1.076 0 01-1.071 1.001z'
            data-name='Trazado 5692'
          />
        </g>
        <g data-name='Grupo 7368'>
          <path
            fill='#455d7a'
            d='M13.498 26.893a1.074 1.074 0 01-1.071-1l-.587-8.606a1.075 1.075 0 112.146-.145l.587 8.606a1.076 1.076 0 01-1 1.146z'
            data-name='Trazado 5693'
          />
        </g>
      </g>
      <g data-name='Grupo 7370'>
        <path fill='none' d='M0 0h34v34H0z' data-name='Rect\xE1ngulo 4408' />
      </g>
    </svg>
  );
};
