import React from 'react';

export const Face1Svg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 43' {...props}>
      <g fill='none'>
        <path d='M0 0h42v43H0z' data-name='Rect\xE1ngulo 35' />
        <g
          stroke='#3d7617'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
          data-name='Grupo 54'
        >
          <g data-name='Grupo 53'>
            <path
              d='M36.859 21.265a15.935 15.935 0 00-31.87 0'
              data-name='Trazado 11'
            />
            <path
              d='M4.989 21.265a15.935 15.935 0 1031.87 0'
              data-name='Trazado 12'
            />
          </g>
          <path
            d='M26.817 16.839a.442.442 0 10.444.442.441.441 0 00-.444-.442m-11.844 0a.442.442 0 10.444.442.44.44 0 00-.444-.442'
            data-name='Shape 3077'
          />
          <path
            d='M20.924 30.118a5.289 5.289 0 005.312-5.312H15.613a5.289 5.289 0 005.312 5.313z'
            data-name='Trazado 13'
          />
        </g>
      </g>
    </svg>
  );
};
