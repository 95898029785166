import { FC, PropsWithChildren } from 'react';
import Div100vh from 'react-div-100vh';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';

import completeLogo from '../assets/imgs/complete-logo.png';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
  title: string;
  instructions?: string;
  centerVertically?: boolean;
};

type Props = ComponentProps & PropsWithChildren;

const keyPrefix = 'layouts.InitialPagesLayout';

export const InitialPagesLayout: FC<Props> = (props): JSX.Element => {
  const { centerVertically } = props;
  const theme = useTheme();
  return (
    <Div100vh
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: centerVertically === false ? 'none' : '100%',
          marginTop: centerVertically === false ? '52px' : 0,
        }}
      >
        <Stack direction='row' width='100%' spacing={7}>
          <BrandComponent />
          <FormCard {...props} />
        </Stack>
      </Container>
    </Div100vh>
  );
};

const BrandComponent: FC = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  return (
    <Box
      maxWidth={400}
      display='flex'
      justifyContent='center'
      flexDirection='column'
    >
      <Image
        src={completeLogo}
        duration={0}
        width={400}
        height={140}
        showLoading
      />
      <Divider variant='fullWidth' />
      <Typography align='center' variant='h5'>
        {t('slogan')}
      </Typography>
    </Box>
  );
};

const FormCard: FC<Props> = ({
  children,
  instructions,
  title,
}): JSX.Element => (
  <Card sx={{ width: 674 }}>
    <CardContent>
      <Stack spacing={1}>
        <Typography variant='h5'>{title}</Typography>
        {!!instructions && (
          <Typography variant='body1'>{instructions}</Typography>
        )}
        {children}
      </Stack>
    </CardContent>
  </Card>
);
