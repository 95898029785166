import { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { ModalLayout } from '../layouts';
import { Stack } from '@mui/system';

type YesNoQuestionModalProps = {
  message: string;
  noLabel: string;
  yesLabel: string;
  onClickYes: () => void;
  onClickNo: () => void;
  onClose: () => void;
};

export const YesNoQuestionModal: FC<YesNoQuestionModalProps> = ({
  message,
  noLabel,
  yesLabel,
  onClose,
  onClickNo,
  onClickYes,
}): JSX.Element => {
  return (
    <ModalLayout onClose={onClose}>
      <Stack
        display='grid'
        direction='row'
        height='150px'
        sx={{ gridTemplateRows: '1fr 60px' }}
      >
        <Typography fontSize='26px'>{message}</Typography>
        <Stack
          direction='row'
          spacing={4}
          justifyContent='flex-end'
          justifyItems='flex-end'
          alignItems='flex-ends'
          alignSelf='end'
        >
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClickNo}
            fullWidth
          >
            {noLabel}
          </Button>
          <Button variant='contained' onClick={onClickYes} fullWidth>
            {yesLabel}
          </Button>
        </Stack>
      </Stack>
    </ModalLayout>
  );
};
