import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';

import { HtmlViewer } from '../components';
import { TC_URL, TC_URL_HEIGHT } from '../config';

const keyPrefix = 'pages.TCPage';

export const TCPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Container maxWidth='lg' sx={{ height: TC_URL_HEIGHT }}>
      <Typography variant='h4' mt={2} ml={2}>
        {t('title')}
      </Typography>
      <HtmlViewer src={TC_URL} title={t('title')} />
    </Container>
  );
};
