import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';

import { ContentGrid, IncentiveViewer } from '../components';
import { useHomePage } from '../hooks';

const keyPrefix = 'pages.HomePage';

type HomePageProps = {
  allowIncentive?: boolean;
};

export const HomePage: FC<HomePageProps> = ({
  allowIncentive = true,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { argumentList, newsList } = useHomePage();

  return (
    <>
      {allowIncentive && <IncentiveViewer />}
      <Container maxWidth='lg'>
        <Box padding='32px 105px'>
          <ContentGrid contentList={newsList} title={t('news')} />
        </Box>
      </Container>
      <Box sx={{ backgroundColor: 'white' }}>
        <Container maxWidth='lg'>
          <Box padding='32px 105px'>
            <ContentGrid contentList={argumentList} title={t('arguments')} />
          </Box>
        </Container>
      </Box>
    </>
  );
};
