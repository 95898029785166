import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CardMedia,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  changeActiveContent,
  changeActivePage,
  selectActivePage,
  selectFavorites,
  selectSession,
  useReportContentWatchedMutation,
} from '../redux';
import {
  DownloadVideoButton,
  FavoriteButton,
  hasMagazine,
  getVideoData,
  parseValidity,
  ReactionButton,
} from '../components';
import { MediaType } from '../enums';
import { useEffect, useState } from 'react';
import { useContentBadges } from '../hooks';

const keyPrefix = 'pages.VideoPage';

export const VideoPage = () => {
  const favorites = useSelector(selectFavorites);
  const { selectedContent, selectedMenuItem, prevPage } = useSelector(selectActivePage);
  console.log('selectedContent', selectedContent);
  console.log('selectedMenuItem', selectedMenuItem);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const { t } = useTranslation('translation', { keyPrefix });
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const { removeBadges } = useContentBadges();

  const [reportContentWatched] = useReportContentWatchedMutation();
  const title =
    prevPage === "home" && selectedContent?.contentType === 101
      ? t("news") //Extract from internationalization
      : prevPage === "home" && selectedContent?.contentType === 100
      ? t("arguments") //Extract from internationalization
      : prevPage === "favorites"
      ? t("favorites")
      : prevPage === "reactions"
      ? t("reactions")
      : selectedMenuItem?.label || "";

  // const media = selectedContent?.media[0];
  const canGoImage = selectedContent?.mediaType === MediaType.VIDEO_IMAGE;
  const canGoMagazine = hasMagazine(selectedContent?.media!);
  const [src, poster] = getVideoData(selectedContent?.media!);
  const urlContent = selectedContent?.urlContent;

  const goToMagazinePage = (): void => {
    // Remove next line to avoid notify same content when user change view to magazine detail
    // Also see 'watchVideo' method in MagazinePage component
    reportContentWatched({ session, contentId: selectedContent!.id });
    dispatch(
      changeActiveContent({
        activePage: 'magazine',
        selectedContent: selectedContent,
        prevPage
      })
    );
  };

  const goBack = (): void => {
    dispatch(
      changeActivePage({
        activePage: prevPage!
      })
    );
  };

  useEffect(() => {
    if (favorites.length > 0 && !!selectedContent) {
      setIsFavorite(
        () => !!favorites.find(({ id }) => id === selectedContent.id)
      );
    }
  }, [favorites, selectedContent]);

  useEffect(() => {
    !!selectedContent?.id && removeBadges(selectedContent.id);
    console.log("TITULO", prevPage);

    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth='lg'>
      <Typography
        variant='h4'
        paddingTop={4}
        sx={{ cursor: 'pointer' }}
        onClick={goBack}
      >
        <ArrowBackIcon
          fontSize='large'
          sx={{ paddingTop: '4px', marginRight: '12px' }}
        />
        {title}
      </Typography>
      <Box padding='32px 150px'>
        <Stack spacing={3}>
          <CardMedia
            component='video'
            autoPlay={true}
            controls={true}
            poster={poster}
            src={src}
            sx={{ paddingTop: 3, paddingBottom: 3 }}
          />
          <Stack
            direction='row'
            justifyContent='space-between'
            paddingBottom={2}
          >
            <ReactionButton idContent={selectedContent?.id!} />
            <FavoriteButton
              idContent={selectedContent?.id!}
              initialState={isFavorite}
            />
            <DownloadVideoButton
              downloadUrl={src!}
              contentId={selectedContent?.id!}
            />
          </Stack>
          <Typography variant='h6'>{selectedContent?.name}</Typography>
          <Typography variant='body1'>
            {selectedContent?.details[0].description}
          </Typography>
          <Typography variant='body2'  sx={{ fontSize: '16px' }}>
            {parseValidity(
              selectedContent?.effectiveStartDate!,
              selectedContent?.effectiveEndDate!,
              t('validityExpression')
            )}
          </Typography>
          {!!urlContent && urlContent !== 'null' && (
            <Link
              variant='body2'
              color='secondary'
              href={urlContent}
              underline='hover'
              target='_blank'
              sx={{ fontSize: '16px' }}
            >
              {t('contentLink')}
            </Link>
          )}
        </Stack>
      </Box>
      {(canGoMagazine || canGoImage) && (
        <Box
          display='flex'
          justifyContent='center'
          paddingTop={8}
          paddingBottom={8}
        >
          <Button
            variant='contained'
            color='warning'
            sx={{ color: 'white', minWidth: 400 }}
            onClick={goToMagazinePage}
          >
            {`${canGoImage ? 'Ver imagen' : 'ver revista'}`}
          </Button>
        </Box>
      )}
    </Container>
  );
};
