import { FC } from 'react';
import {
  Box,
  Button,
  CardMedia,
  Container,
  Stack,
  Typography,
} from '@mui/material';

import { FullModalLayout } from '../../layouts';
import { useTranslation } from 'react-i18next';
import { TriviaQuestionSlider } from '../../components';
import { Incentive } from '../../types';

type TriviaQuestionsModalProps = {
  trivia: Incentive;
  onClickQuit: () => void;
  onFinishTriviaQuestions: (answers: number[]) => void;
};

const keyPrefix = 'modals.TriviaQuestionsModal';

export const TriviaQuestionsModal: FC<TriviaQuestionsModalProps> = ({
  trivia,
  onClickQuit,
  onFinishTriviaQuestions,
}): JSX.Element => {
  const { coverTitle, coverDescription, media, questions } = trivia;
  const coverImg = !!media ? media[0].url : null;

  const noop = () => {};

  return (
    <FullModalLayout onClose={noop}>
      <Container maxWidth='lg'>
        <Stack direction='row' width='100%' spacing={5} alignItems='flex-start'>
          <TriviaInfo
            coverTitle={coverTitle}
            coverDescription={coverDescription}
            coverImg={coverImg}
            onClickQuit={onClickQuit}
          />
          <TriviaQuestionSlider
            questions={questions!}
            onFinishTriviaQuestions={onFinishTriviaQuestions}
          />
        </Stack>
      </Container>
    </FullModalLayout>
  );
};

type TriviaInfoProps = {
  coverTitle?: string;
  coverDescription?: string;
  coverImg: string;
  onClickQuit: () => void;
};

const TriviaInfo: FC<TriviaInfoProps> = ({
  coverTitle,
  coverDescription,
  coverImg,
  onClickQuit,
}): JSX.Element => {
  const { t } = useTranslation('translation', {
    keyPrefix: `${keyPrefix}.TriviaInfoProps`,
  });
  return (
    <Stack
      width={372}
      justifyContent='center'
      flexDirection='column'
      rowGap={3}
    >
      <Typography variant='h3' textAlign='center'>
        {coverTitle}
      </Typography>
      <Typography variant='body1' textAlign='center' lineHeight='33px'>
        {coverDescription}
      </Typography>
      {!!coverImg && (
        <CardMedia
          component='img'
          image={coverImg}
          sx={{
            borderRadius: '12px !important',
            cursor: 'pointer',
            maxWidth: '328px',
            margin: '0 auto',
          }}
        />
      )}
      <Box width='370px' margin='0 auto' pt={3}>
        <Button
          variant='outlined'
          color='secondary'
          onClick={onClickQuit}
          fullWidth
        >
          {t('cancel')}
        </Button>
      </Box>
    </Stack>
  );
};
