import { osName as os, osVersion as versionOs } from 'react-device-detect';

import packageJson from '../../../package.json';
import { AppInfo } from '../../types';
import { SKU_APP } from '../../config';

export const getAppInfo = (sessionToken?: string | null): AppInfo => ({
  // versionCode: 74,
  versionOs,
  os,
  versionApp: packageJson.version,
  skuApp: SKU_APP,
  ...(!!sessionToken ? { sessionToken } : {}),
});
