import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import {
  Answer,
  Incentive,
  IncentivesApiResponse,
  SurveyIncentiveParsePayload,
  TriviaIncentiveParsePayload,
} from '../../types';
import { incentivesApi } from '../services';
import { RootState } from '../store';

export interface incentivesState {
  list: Incentive[];
  incentiveAnswered: Incentive | null;
}

const initialState: incentivesState = {
  list: [],
  incentiveAnswered: null,
};

export const incentivesSlice = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    parseTriviaAnswers: (
      state,
      action: PayloadAction<TriviaIncentiveParsePayload>
    ) => {
      const { givenAnswers, score } = action.payload;

      state.incentiveAnswered = state.list[0];
      state.incentiveAnswered.score = score;
      givenAnswers.forEach((answerId, questionId) => {
        state.incentiveAnswered!.questions![questionId].answers = [
          state.list[0].questions![questionId].answers![answerId],
        ];
      });
    },
    removeIncentives: state => {
      state.list = [];
    },
    shiftIncentive: state => {
      state.list.shift();
    },
    clearAnsweredIncentive: state => {
      state.incentiveAnswered = null;
    },
    parseSurveyIncentive: (
      state,
      action: PayloadAction<SurveyIncentiveParsePayload>
    ) => {
      const {
        surveyAnswers,
        surveyExtraAnswers,
        surveyOpenAnswers,
        surveyComment,
      } = action.payload;
      state.incentiveAnswered = state.list[0];

      // console.log('surveyAnswers', surveyAnswers);
      // console.log('surveyExtraAnswers', surveyExtraAnswers);
      // console.log('surveyOpenAnswers', surveyOpenAnswers);
      // console.log('surveyComment', surveyComment);

      surveyAnswers.forEach((answerId, questionId) => {
        if (answerId === undefined) {
          state.incentiveAnswered!.questions![questionId].answers[0] = {
            correct: false,
            questionId: state.incentiveAnswered!.questions![questionId].id,
            iconId: 0,
            answer: surveyOpenAnswers[questionId],
            id: 0,
          } as Answer;
        } else {
          state.incentiveAnswered!.questions![questionId].answers = [
            state.list[0].questions![questionId].answers![answerId],
          ];

          if (
            !!surveyExtraAnswers[questionId] &&
            surveyExtraAnswers[questionId].length > answerId
          ) {
            const extraAnswers = [
              {
                questionId: state.incentiveAnswered!.questions![questionId].id,
                correct: false,
                iconId: 0,
                answer: surveyExtraAnswers[questionId][answerId],
                id: 0,
              } as Answer,
            ];

            state.incentiveAnswered!.questions![
              questionId
            ].answers[0].extraQuestion.answers = extraAnswers;
          }
        }
      });

      if (!!surveyComment) state.incentiveAnswered.userComments = surveyComment;

      console.log(current(state));
    },
    parseTriviaExtraSurveyIncentive: (
      state,
      action: PayloadAction<SurveyIncentiveParsePayload>
    ) => {
      const {
        surveyAnswers,
        surveyExtraAnswers,
        surveyOpenAnswers,
        surveyComment,
      } = action.payload;
      surveyAnswers.forEach((answerId, questionId) => {
        if (answerId === undefined) {
          state.incentiveAnswered!.extraIncentive!.questions![
            questionId
          ].answers[0] = {
            questionId:
              state.incentiveAnswered!.extraIncentive!.questions![questionId]
                .id,
            correct: false,
            iconId: 0,
            answer: surveyOpenAnswers[questionId],
            id: 0,
          } as Answer;
        } else {
          state.incentiveAnswered!.extraIncentive!.questions![
            questionId
          ].answers = [
            state.list[0].extraIncentive!.questions![questionId].answers![
              answerId
            ],
          ];

          if (
            !!surveyExtraAnswers[questionId] &&
            surveyExtraAnswers[questionId].length > answerId
          ) {
            const extraAnswers = [
              {
                questionId:
                  state.incentiveAnswered!.extraIncentive!.questions![
                    questionId
                  ].id,
                correct: false,
                iconId: 0,
                answer: surveyExtraAnswers[questionId][answerId],
                id: 0,
              } as Answer,
            ];

            state.incentiveAnswered!.extraIncentive!.questions![
              questionId
            ].answers[0].extraQuestion.answers = extraAnswers;
          }
        }
      });

      if (!!surveyComment)
        state.incentiveAnswered!.extraIncentive!.userComments = surveyComment;
    },
    parsePublicationIncentive: state => {
      state.incentiveAnswered = state.list[0];
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      incentivesApi.endpoints.getIncentives.matchFulfilled,
      (state, action: PayloadAction<IncentivesApiResponse>) => {
        const { responseCode } = action.payload.result;
        if (responseCode === 200) {
          const {
            payload: { incentives },
          } = action;
          state.list = incentives || [];
        }
      }
    );
  },
});

export const {
  clearAnsweredIncentive,
  parsePublicationIncentive,
  parseTriviaAnswers,
  parseTriviaExtraSurveyIncentive,
  parseSurveyIncentive,
  removeIncentives,
  shiftIncentive,
} = incentivesSlice.actions;

export const incentivesReducer = incentivesSlice.reducer;

export const selectCurrentIncentive = (state: RootState) =>
  state.incentives.list.length > 0 ? state.incentives.list[0] : null;

export const selectIncentiveAnswered = (state: RootState) =>
  state.incentives.incentiveAnswered;
