import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { LoginFormFields } from '../../hooks';
import { getAppInfo } from './appInfo';
import { AppInfo } from '../../types';

type Params = {
  fields: LoginFormFields;
  url: string;
};

type BodyLogin = {
  action: number
  appInfo: AppInfo,
  password: string,
  type: string,
  username: string | null,
  identificationValue: string | null,
}

export const authApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery(),
  endpoints: builder => ({
    login: builder.mutation({
      query: ({
        fields: { password, username, typeLogin },
        url,
      }: Params) => {

        let body: BodyLogin = {
          action: 1,
          appInfo: getAppInfo(),
          password,
          type: typeLogin,
          username: null,
          identificationValue: null,
        };
        if (typeLogin === '1') {
          body.username = username;
        } else if (typeLogin === '2') {
          body.identificationValue = username;
        }
        return {
          url,
          method: 'POST',
          body
        };
      },
    }),
    logout: builder.mutation({
      query: (username: string) => {
        return {
          url: 'v5/login',
          method: 'POST',
          body: {
            action: 2,
            appInfo: getAppInfo(),
            username,
          },
        };
      },
    }),
  }),
});

export const { 
  useLoginMutation, useLogoutMutation } = authApi;
