import React from 'react';

export const IconSearchSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
      <g fill='none'>
        <path d='M0 0h24v24H0z' data-name='Rect\xE1ngulo 4415' />
        <g
          stroke='#455d7a'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          data-name='Grupo 7651'
          transform='translate(-331.189 -3023.731)'
        >
          <circle
            cx={6.276}
            cy={6.276}
            r={6.276}
            data-name='Elipse 43'
            transform='translate(336.189 3028.731)'
          />
          <path d='m350.189 3042.731-3.286-3.286' data-name='L\xEDnea 7' />
        </g>
      </g>
    </svg>
  );
};
