import { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { ContentItem } from './ContentItem';
import { Content } from '../../types';

type ContentGridProps = {
  contentList: Content[];
  title: string;
};

export const ContentGrid: FC<ContentGridProps> = ({
  contentList,
  title,
}): JSX.Element => {
  return (
    <Stack spacing={3}>
      <Typography variant='h4'>{title}</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={2}>
          {contentList.map((content, key) => (
            <Grid item key={key} md={4}>
              <ContentItem {...content} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};
