import { FC, useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReactionItem } from "./ReactionItem";
import { selectContents, selectReactions } from "../../redux";
import { Content } from "../../types";
import { IconCloseSvg, IconSearchSvg } from "../../assets";

const keyPrefix = "pages.ReactionsPage";

export const ReactionsGrid: FC = ({}): JSX.Element => {
  const reactions = useSelector(selectReactions);
  const contents = useSelector(selectContents);
  const [filterReaction, setFilterReaction] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [contentsByReactions, setContentsByReactions] = useState<Content[]>([]);
  const listReactions = [0, 1, 2, 3];
  const { t } = useTranslation("translation", { keyPrefix });

  useEffect(() => {
    let contentsFilter = [];
    if (filterReaction === 0) {
      const reactionIds = reactions.map((reaction) => reaction.idContent);
      contentsFilter = contents.filter((content) =>
        reactionIds.includes(content.id)
      );
    } else {
      const reactionIds = reactions
        .filter((reaction) => reaction.reactionType === filterReaction)
        .map((reaction) => reaction.idContent);
      contentsFilter = contents.filter((content) =>
        reactionIds.includes(content.id)
      );
    }

    const filteredContentBySearch = contentsFilter.filter(
      ({ name, details }) =>
        name.toLowerCase().includes(searchText.toLowerCase()) ||
        details[0].description.toLowerCase().includes(searchText.toLowerCase())
    );

    setContentsByReactions(filteredContentBySearch);
  }, [filterReaction, searchText]);

  useEffect(() => {
    const reactionIds = reactions.map((reaction) => reaction.idContent);
    const contentsFilter = contents.filter((content) =>
      reactionIds.includes(content.id)
    );
    setContentsByReactions(contentsFilter);
  }, []);

  return (
    <Stack spacing={3}>
      <Typography variant="h4">Reacciones</Typography>

      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        gap={4}
      >
        <Stack
          display="flex"
          direction="column"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">Que contenga el texto:</Typography>
          <TextField
            size="small"
            InputProps={{
              endAdornment: !!searchText ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchText("");
                    }}
                  >
                    <IconCloseSvg />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
              startAdornment: <IconSearchSvg width={32} />,
            }}
            inputProps={{
              style: {
                paddingLeft: 12,
                paddingTop: 17,
                paddingBottom: 17,
              },
            }}
            value={searchText}
            placeholder="Buscar..."
            sx={{ width: "100%" }}
            onChange={({ target: { value } }) => {
              setSearchText(value);
            }}
          />
        </Stack>
        <Stack sx={{ width: "300px" }}>
          <Typography variant="h6">Tipo de Reacción:</Typography>
          <Select
            label=""
            variant="outlined"
            color="secondary"
            value={filterReaction}
            onChange={({ target: { value } }) => {
              const reactionSelected = value as number;
              setFilterReaction(reactionSelected);
            }}
            // onBlur={handleOnblur}
          >
            {listReactions.map((reaction, idx) => (
              <MenuItem value={reaction} key={idx}>
                {t(`ReactionButton.label.${reaction}`)}
              </MenuItem>
            ))}
          </Select>
          {/* {listReactions.map((reaction) => (
            <IconButton
              key={reaction}
              size="small"
              onClick={() => setFilterReaction(reaction)}
              sx={{
                backgroundColor:
                  filterReaction === reaction? "#F9F9F9" : "#FFFFFF",
                color: filterReaction === reaction? "#333333" : "#666666",
                borderRadius: 8,
                padding: "8px 16px",
                marginLeft: 8,
              }}
            >
              {reaction === 0? "Todos" : `Reacción ${reaction}`}
            </IconButton>
          ))} */}
        </Stack>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={2}>
          {contentsByReactions.map((content, key) => (
            <Grid item key={key} md={12}>
              <ReactionItem {...content} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};
