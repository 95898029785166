import React from 'react';

export const Face2Svg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 43' {...props}>
      <g fill='none'>
        <path d='M0 0h42v43H0z' data-name='Rect\xE1ngulo 36' />
        <g
          stroke='#809300'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
          data-name='Grupo 57'
        >
          <g data-name='Grupo 56'>
            <path
              d='M4.902 21.265a15.935 15.935 0 0131.87 0'
              data-name='Trazado 14'
            />
            <path
              d='M36.772 21.265a15.935 15.935 0 11-31.87 0'
              data-name='Trazado 15'
            />
          </g>
          <path d='M14.64 15.953v1.771' data-name='L\xEDnea 6' />
          <path d='M27.034 15.953v1.771' data-name='L\xEDnea 7' />
          <path
            d='M27.034 26.024a9.43 9.43 0 01-12.394 0'
            data-name='Trazado 16'
          />
        </g>
      </g>
    </svg>
  );
};
