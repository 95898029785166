import { useTranslation } from 'react-i18next';

import { RecoverPasswordForm } from '../forms';
import { InitialPagesLayout } from '../layouts/InitialPagesLayout';

const keyPrefix = 'pages.RecoverPasswordPage';

export const RecoverPasswordPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <InitialPagesLayout title={t('title')} instructions={t('instructions')}>
      <RecoverPasswordForm />
    </InitialPagesLayout>
  );
};
