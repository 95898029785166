import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Link, Stack, Typography } from '@mui/material';

import { Answer, ExtraQuestion, Media } from '../../../types';
import { SurveyAnswerButton } from './SurveyAnswerButton';
import { MediaModal } from '../../../modals';
import { OpenQuestion } from './OpenQuestion';

type SurveyAnswersGridProps = {
  answers: Answer[];
  media: Media | undefined;
  onSelectAnswer: (idx: number, more: string | null) => void;
  onOpenQuestionValueChange: (newValue: string, answerId: number) => void;
  onExtraQuestionValueChange: (newValue: string, answerId: number) => void;
};
const keyPrefix = 'components.incentives.SurveyAnswersGrid';

export const SurveyAnswersGrid: FC<SurveyAnswersGridProps> = ({
  answers,
  media,
  onSelectAnswer,
  onOpenQuestionValueChange,
  onExtraQuestionValueChange,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [selectedAnswer, setSelectedAnswer] = useState<number>(-1);
  const [showMedia, setShowMedia] = useState<boolean>(false);
  const [extraQuestion, setExtraQuestion] = useState<ExtraQuestion | null>(
    null
  );

  const clickAnswer = (idx: number): void => {
    const newSelectedAnswer = selectedAnswer === idx ? -1 : idx;
    setSelectedAnswer(newSelectedAnswer);
    onSelectAnswer(newSelectedAnswer, null);
    setExtraQuestion(
      newSelectedAnswer > -1 ? answers[newSelectedAnswer].extraQuestion : null
    );
  };

  const toggleMedia = (): void => {
    setShowMedia(value => !value);
  };

  const resetAnswers = (): void => {
    setSelectedAnswer(-1);
    setShowMedia(false);
    setExtraQuestion(null);
  };

  useEffect(resetAnswers, [answers]);

  return (
    <Box justifyContent='center'>
      <Stack direction='row' gap={2} justifyContent='center'>
        {answers.map((answer, idx) => (
          <SurveyAnswerButton
            key={idx}
            answer={answer}
            selected={selectedAnswer === idx}
            onClick={() => {
              clickAnswer(idx);
            }}
          />
        ))}
      </Stack>
      {!!media && (
        <Box mt={5}>
          <Link
            width='100%'
            onClick={toggleMedia}
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            <Typography textAlign='center' variant='h5' color='secondary'>
              {t('detail')}
            </Typography>
          </Link>
        </Box>
      )}
      {answers.length === 0 ? (
        <OpenQuestion
          onChange={newValue => {
            onOpenQuestionValueChange(newValue, selectedAnswer);
          }}
        />
      ) : (
        <Collapse in={!!extraQuestion}>
          <OpenQuestion
            from={extraQuestion!}
            onChange={newValue => {
              onExtraQuestionValueChange(newValue, selectedAnswer);
            }}
          />
        </Collapse>
      )}

      {!!media && showMedia && (
        <MediaModal media={media} onClose={toggleMedia} />
      )}
    </Box>
  );
};
