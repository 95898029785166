import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  savePass,
  selectLoginAttempts,
  selectPropertiesApp,
  useLoginMutation,
} from "../../redux";
import { attemptLogin, RootState } from "../../redux";
import { useClearLoginInfo } from "../useClearLoginInfo";

export interface LoginFormFields {
  username: string;
  password: string;
  typeLogin: string;
}

const keyPrefix = "hooks.formManagements.useLoginFormManagement";

const defaultValues: LoginFormFields = {
  username: "",
  password: "",
  typeLogin: "1",
};

export const useLoginFormManagement = () => {
  const [fieldsValidationErr, setFieldsValidationErr] = useState<
    string | null
  >();
  const [genericErr, setGenericErr] = useState<string | null>();
  const { t } = useTranslation("translation", { keyPrefix });
  const dispatch = useDispatch();

  const [login, { isError, isLoading, error: requestErr }] = useLoginMutation();
  const properties = useSelector(selectPropertiesApp);
  const urlLogin = properties["url.service.loginV6"];
  const { clearDataLogin } = useClearLoginInfo();

  const schema = yup.object().shape({
    username: yup.string().required(t("schema.username.required")),
    password: yup.string().required(t("schema.password.required")),
  });

  const methods = useForm<LoginFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (isError) {
      console.error(requestErr);
      setGenericErr(t("errors.apiError"));
      dispatch(savePass(null));
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    const { formState: errors } = methods;
    setFieldsValidationErr(
      errors.errors.username?.message || errors.errors.password?.message || null
    );
    // eslint-disable-next-line
  }, [methods.formState.errors]);

  const submit: SubmitHandler<LoginFormFields> = async (fields) => {
    clearDataLogin();
    clearErrorMessages();
    await login({ fields, url: urlLogin.replace(/^http:/, "https:") });
    dispatch(savePass(fields.password));
  };

  const clearErrorMessages = (): void => {
    setGenericErr(null);
    setFieldsValidationErr(null);
  };

  return {
    clearErrorMessages,
    loading: isLoading,
    defaultValues,
    fieldsValidationErr,
    genericErr,
    methods,
    submit,
  };
};
