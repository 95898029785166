import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { selectSession, useMakeSuggestionMutation } from '../../redux';
import { HelpParams } from '../../types';

export interface SuggestionsFormFields {
  email: string;
  comments: string;
}

const keyPrefix = 'hooks.formManagements.useSuggestionsFormManagement';

const defaultValues: SuggestionsFormFields = {
  email: '',
  comments: '',
};

export const useSuggestionsFormManagement = () => {
  const [canShowSendButton, setCanShowSendButton] = useState<boolean>(false);
  const [genericErr, setGenericErr] = useState<string | null>();
  const [success, setSuccess] = useState<boolean | null>(null);
  const [response, setResponse] = useState<string | null>(null);
  const { t } = useTranslation('translation', { keyPrefix });

  const session = useSelector(selectSession);
  const [send, { isError, isLoading, error: requestErr, data }] =
    useMakeSuggestionMutation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('schema.email.format'))
      .required(t('schema.email.required')),
    comments: yup.string().required(t('schema.comments.required')),
  });

  const methods = useForm<SuggestionsFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const validateFieldsAreDirty = (): boolean => {
    const {
      formState: { dirtyFields },
    } = methods;

    return !!dirtyFields['email'] || !!dirtyFields['comments'];
  };

  useEffect(() => {
    if (isError) {
      console.error(requestErr);
      setGenericErr(t('errors.apiError'));
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if (!data) return;

    const {
      result: { responseCode, responseMessage },
    } = data;

    setSuccess(responseCode === 200);
    setResponse(responseMessage);
  }, [data]);

  useEffect(() => {
    const fieldsAreDirty = validateFieldsAreDirty();
    setCanShowSendButton(fieldsAreDirty);
    // eslint-disable-next-line
  }, [methods.formState.dirtyFields]);

  const submit: SubmitHandler<SuggestionsFormFields> = async ({
    email,
    comments,
  }) => {
    clearErrorMessages();

    const reportIssueData = {
      session,
      email,
      comments,
    } as HelpParams;

    // console.log('reportIssueData object', reportIssueData);
    await send(reportIssueData);
  };

  const clearErrorMessages = (): void => {
    setGenericErr(null);
    setSuccess(null);
    setResponse(null);
  };

  return {
    canShowSendButton,
    genericErr,
    loading: isLoading,
    methods,
    response,
    success,
    clearErrorMessages,
    submit,
  };
};
