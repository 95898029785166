import React from 'react';

export const Face3Svg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 43' {...props}>
      <g fill='none'>
        <path d='M0 0h42v43H0z' data-name='Rect\xE1ngulo 34' />
        <g
          stroke='#fa7e02'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
          data-name='Grupo 51'
        >
          <g data-name='Grupo 50'>
            <path
              d='M36.685 21.265a15.935 15.935 0 00-31.87 0'
              data-name='Trazado 9'
            />
            <path
              d='M4.815 21.265a15.935 15.935 0 1031.87 0'
              data-name='Trazado 10'
            />
          </g>
          <path
            d='M26.643 16.873a.442.442 0 10.444.442.441.441 0 00-.444-.442'
            data-name='Shape 3077'
          />
          <path d='M15 27.001h11' data-name='L\xEDnea 5' />
          <path
            d='M14.799 16.839a.442.442 0 10.444.442.44.44 0 00-.444-.442'
            data-name='Shape 3077'
          />
        </g>
      </g>
    </svg>
  );
};
