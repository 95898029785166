import GoogleFontLoader from 'react-google-font';

export const LoadFonts = () => {
  return (
    <GoogleFontLoader
      fonts={[
        {
          font: 'Catamaran',
          weights: [900, 800, 700, 600, 500, 400, 300, 200, 100, '400i'],
        },
      ]}
      // subsets={['cyrillic-ext', 'greek']}
    />
  );
};
