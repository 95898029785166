import { FC } from 'react';
import { AppBar, Container, Stack, Toolbar } from '@mui/material';
import Image from 'mui-image';

import completeLogo from '../../assets/imgs/complete-logo.png';
import { MenuItems } from './MenuItems';
import { SearchContent } from './SearchContent';
import { AnonymousMenuItems } from './AnonymousMenuItems';

type MenuProps = {
  isAnonymous?: boolean;
};

export const Menu: FC<MenuProps> = ({ isAnonymous = false }): JSX.Element => {
  return (
    <AppBar position='static'>
      <Toolbar>
        <Container maxWidth='lg' sx={{ padding: 0 }}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Image
              src={completeLogo}
              duration={0}
              height={37}
              width={105}
              showLoading
            />
            {!isAnonymous ? (
              <>
                <SearchContent />
                <MenuItems />
              </>
            ) : (
              <AnonymousMenuItems />
            )}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
