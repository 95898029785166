import React, { FC } from 'react';

type IconCloseSvgProps = {
  green?: boolean;
};

export const IconCloseSvg: FC<
  React.SVGProps<SVGSVGElement> & IconCloseSvgProps
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <g data-name='Grupo 7254'>
        <path fill='none' d='M0 0h32v32H0z' data-name='Rect\xE1ngulo 4389' />
      </g>
      <g data-name='Grupo 7257'>
        <g data-name='Grupo 7255'>
          <path
            fill={props.green ? '#1e878c' : '#455d7a'}
            d='M21.543 22.552a1 1 0 0 1-.714-.3L10.058 11.481a1.008 1.008 0 0 1 0-1.427 1.009 1.009 0 0 1 1.427 0l10.771 10.771a1.008 1.008 0 0 1 0 1.427 1 1 0 0 1-.713.3Z'
            data-name='Trazado 5669'
          />
        </g>
        <g data-name='Grupo 7256'>
          <path
            fill='#455d7a'
            // fill='#1e878c'
            d='M10.772 22.552a1 1 0 0 1-.714-.3 1.008 1.008 0 0 1 0-1.427l10.771-10.771a1.009 1.009 0 0 1 1.427 0 1.008 1.008 0 0 1 0 1.427L11.485 22.252a1 1 0 0 1-.713.3Z'
            data-name='Trazado 5670'
          />
        </g>
      </g>
    </svg>
  );
};
