import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { Answer } from '../../../types';

type TriviaAnswersGridProps = {
  answers: Answer[];
  onSelectAnswer: (answerIdx: number) => void;
};

export const TriviaAnswersGrid: FC<TriviaAnswersGridProps> = ({
  answers,
  onSelectAnswer,
}): JSX.Element => {
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);

  const resetIndex = () => {
    setSelectedIdx(-1);
  };

  useEffect(resetIndex, [answers]);

  const selectAnswer = (answerIdx: number) => {
    onSelectAnswer(answerIdx);
    setSelectedIdx(answerIdx);
  };

  return (
    <Stack gap={4}>
      {answers.map((answer, idx) => (
        <TriviaAnswersElement
          key={idx}
          text={answer.answer}
          onClick={() => selectAnswer(idx)}
          selected={idx === selectedIdx}
        />
      ))}
    </Stack>
  );
};

type TriviaAnswersElementProps = {
  text: string;
  selected?: boolean;
  onClick: () => void;
};

const TriviaAnswersElement: FC<TriviaAnswersElementProps> = ({
  text,
  selected = false,
  onClick,
}): JSX.Element => {
  return (
    <Button
      variant='outlined'
      color='info'
      onClick={onClick}
      sx={{
        border: selected ? '1px solid' : '',
        backgroundColor: selected ? '#e3edeb' : '',
      }}
    >
      <Typography
        width='100%'
        textAlign='left'
        px='32px'
        color='black'
        fontWeight={selected ? 'bold' : 'normal'}
      >
        {text}
      </Typography>
    </Button>
  );
};
