console.log('process.env.API_BASE_URL', process.env.REACT_APP_API_BASE_URL);

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  'http://testambiente.bn.clarovendamos.com.pe/services';

export const SKU_APP = process.env.REACT_APP_SKU_APP || 'ven-web-app';

export const MAX_ATTEMPTS = parseInt(process.env.REACT_APP_MAX_ATTEMPTS || '4');

export const FAQ_URL =
  process.env.REACT_APP_FAQ_URL ||
  'https://clarovendamos.com.pe/puntoventa/mkt_web_preguntas_frecuentes.html';

export const FAQ_URL_HEIGHT = process.env.REACT_APP_FAQ_URL_HEIGHT || 1450;

export const TC_URL =
  process.env.REACT_APP_TC_URL ||
  'https://clarovendamos.com.pe/puntoventa/mkt_web_terminosCondiciones.html';

export const TC_URL_HEIGHT = process.env.REACT_APP_TC_URL_HEIGHT || 2900;
