import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../services';

export interface AttemptState {
  value: { [user: string]: number };
  activeUser: string | null;
}

const initialState: AttemptState = { value: {}, activeUser: null };

export const attemptSlice = createSlice({
  name: 'attempt',
  initialState,
  reducers: {
    attemptLogin: (state, action: PayloadAction<string>) => {
      state.activeUser = action.payload;
      const actualAttempt = state.value[action.payload] || 1;
      state.value[action.payload] = actualAttempt + 1;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        const { responseCode } = action.payload.result;
        if (responseCode === 200) {
          const {
            user: { username },
          } = action.payload;
          state.value[username] = 0;
        }
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const {
          result: { responseCode },
        } = action.payload;
        if (responseCode === 200) {
          const {
            user: { username },
          } = action.payload;
          state.value[username] = 0;
        }
      });
  },
});

export const { attemptLogin } = attemptSlice.actions;

export const attemptReducer = attemptSlice.reducer;
