import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { promosApi } from '../services';
import { PromosApiResponse } from '../../types';

export interface contentBadgesState {
  newContentIds: string[];
  subContentTypesBadges: { [id: string]: number };
  contentTypesBadges: { [id: string]: number };
}

const initialState: contentBadgesState = {
  newContentIds: [],
  subContentTypesBadges: {},
  contentTypesBadges: {},
};

export const contentBadgesSlice = createSlice({
  name: 'contentBadges',
  initialState,
  reducers: {
    updateBagesByTags: (state, action: PayloadAction<string[]>) => {
      const tags = action.payload;

      // console.log('updateBagesByTags', tags);

      let contentTypes: { [id: string]: number } = {};
      let subContentTypes: { [id: string]: number } = {};
      let contentIds: string[] = [];

      tags.forEach(tag => {
        const [contentType, subContentType, contentId] = tag?.split('-');
        contentTypes[contentType] = (contentTypes[contentType] || 0) + 1;
        subContentTypes[subContentType] =
          (subContentTypes[subContentType] || 0) + 1;

        contentIds.push(contentId);
      });

      state.newContentIds = contentIds;
      state.contentTypesBadges = contentTypes;
      state.subContentTypesBadges = subContentTypes;
    },
  },
  // extraReducers: builder => {
  //   builder.addMatcher(
  //     promosApi.endpoints.getPromos.matchFulfilled,
  //     (state, action: PayloadAction<PromosApiResponse>) => {
  //       const { responseCode } = action.payload.result;
  //       if (responseCode === 200) {
  //         const { payload } = action;

  //         console.log('contentBadgesSlice - extraReducers', payload);
  //         console.log(current(state));
  //         // Substract badge if necesarry

  //       }
  //     }
  // );
  // },
});

export const { updateBagesByTags } = contentBadgesSlice.actions;

export const contentBadgesReducer = contentBadgesSlice.reducer;

export const selectContentTypeBadges = (state: RootState) =>
  state.contentBadges.contentTypesBadges;
export const selectSubContentTypeBadges = (state: RootState) =>
  state.contentBadges.subContentTypesBadges;
export const selectNewContent = (state: RootState) =>
  state.contentBadges.newContentIds;
