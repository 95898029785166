import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { GetLocalsParams } from '../../types';

export const localsApi = createApi({
  reducerPath: 'localsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getLocals: builder.mutation({
      query: (params: GetLocalsParams) => {
        return {
          url: 'getlocals',
          method: 'POST',
          body: { ...params },
        };
      },
    }),
  }),
});

export const { useGetLocalsMutation } = localsApi;
