import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { RegisterUserParams } from '../../types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    registerUser: builder.mutation({
      query: (params: RegisterUserParams) => {
        return {
          url: 'register',
          method: 'POST',
          body: { ...params },
        };
      },
    }),
  }),
});

export const { useRegisterUserMutation } = userApi;
