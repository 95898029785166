import { Alert } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ErrorAlert: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  return (
    <Alert severity='error' icon={false}>
      {children}
    </Alert>
  );
};
