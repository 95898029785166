import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SurveyCoverModal } from '../../../modals';
import { CancelIncentiveConfirmationModal } from '../../../modals/incentives/CancelIncentiveConfirmationModal';
import { parseSurveyIncentive } from '../../../redux';
import { Incentive } from '../../../types';

type SurveyViewProps = {
  isExtraIncentive?: boolean;
  survey: Incentive;
  onClickQuit: () => void;
  onFinishSurvey: (
    answers: number[],
    openAnswers: string[],
    extraAnswers: string[][],
    comment: string | null
  ) => void;
};

export const SurveyViewer: FC<SurveyViewProps> = ({
  isExtraIncentive = false,
  survey,
  onClickQuit,
  onFinishSurvey,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [showSurvey, setShowSurvey] = useState<boolean>(true);
  const [showCancelConfirmationIncentive, setShowCancelConfirmationIncentive] =
    useState<boolean>(false);

  const finishIncentive = async (
    surveyAnswers: number[],
    surveyOpenAnswers: string[],
    surveyExtraAnswers: string[][],
    surveyComment: string | null
  ) => {
    if (!isExtraIncentive) {
      dispatch(
        parseSurveyIncentive({
          surveyAnswers,
          surveyExtraAnswers,
          surveyOpenAnswers,
          surveyComment,
        })
      );
      setShowSurvey(false);
    }

    onFinishSurvey(
      surveyAnswers,
      surveyOpenAnswers,
      surveyExtraAnswers,
      surveyComment
    );
  };

  const clickQuit = () => {
    setShowSurvey(false);
    setShowCancelConfirmationIncentive(true);
  };
  const close = () => {
    setShowSurvey(false);
    setShowCancelConfirmationIncentive(true);
  };

  const continueIncentive = () => {
    setShowSurvey(true);
    setShowCancelConfirmationIncentive(false);
  };

  const handleLeaveIncentiveConfirmationModal = () => {
    setShowSurvey(false);
    setShowCancelConfirmationIncentive(false);
    onClickQuit();
  };

  return (
    <>
      {showSurvey && (
        <SurveyCoverModal
          survey={survey}
          onClickQuit={clickQuit}
          onClose={close}
          onFinishSurvey={finishIncentive}
        />
      )}
      {showCancelConfirmationIncentive && (
        <CancelIncentiveConfirmationModal
          onClickNo={continueIncentive}
          onClickYes={handleLeaveIncentiveConfirmationModal}
        />
      )}
    </>
  );
};
