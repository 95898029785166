import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';

import { MediaType } from '../../enums';
import {
  changeActiveContent,
  selectSession,
  useReportContentWatchedMutation,
} from '../../redux';
import { Content } from '../../types';
import { CallToActionButton } from './CallToActionButton';
import { hasVideoThumbnail } from './Util';

export const ContentItemCallToAction: FC<Content> = (content): JSX.Element => {
  const { media, mediaType } = content;
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const [reportContentWatched] = useReportContentWatchedMutation();

  const goToDetail = (type: string) => {
    reportContentWatched({ session, contentId: content.id });
    dispatch(
      changeActiveContent({ activePage: type, selectedContent: content })
    );
  };

  const handleMagazineClick = (): void => goToDetail('magazine');
  const handleVideoClick = (): void => goToDetail('video');

  switch (mediaType) {
    case MediaType.MAGAZINE:
      return hasVideoThumbnail(media) ? (
        <Stack spacing={2}>
          <CallToActionButton type='video' onClick={handleVideoClick} />
          <CallToActionButton type='magazine' onClick={handleMagazineClick} />
        </Stack>
      ) : (
        <CallToActionButton type='magazine' onClick={handleMagazineClick} />
      );
    case MediaType.VIDEO:
      return <CallToActionButton type='video' onClick={handleVideoClick} />;
    case MediaType.VIDEO_IMAGE:
      return (
        <Stack spacing={2}>
          <CallToActionButton type='video' onClick={handleVideoClick} />
          <CallToActionButton type='magazine' onClick={handleMagazineClick} />
        </Stack>
      );
    default:
      return <></>;
  }
};
