import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';

import { Question } from '../../../types';
import { TriviaAnswersGrid } from './TriviaAnswersGrid';
import { IndexIndicator } from '../IndexIndicator';

type TriviaQuestionSliderProps = {
  questions: Question[];
  onFinishTriviaQuestions: (answers: number[]) => void;
};

const keyPrefix = 'components.incentives.TriviaQuestionSlider';

export const TriviaQuestionSlider: FC<TriviaQuestionSliderProps> = ({
  questions,
  onFinishTriviaQuestions,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [idx, setIdx] = useState<number>(0);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [givenAnswers, setGivenAnswers] = useState<number[]>([]);

  const selectAnswer = (answerIdx: number, questionIdx: number) => {
    setCanContinue(true);

    const newGivenAnswers = [...givenAnswers];
    newGivenAnswers[questionIdx] = answerIdx;
    setGivenAnswers(newGivenAnswers);
  };

  const onClickNext = () => {
    setCanContinue(false);
    const newIdx = idx + 1;
    idx === questions.length - 1
      ? onFinishTriviaQuestions(givenAnswers)
      : setIdx(newIdx);
  };

  return (
    <Card sx={{ width: 674, height: 698 }}>
      <Stack justifyContent='space-between' height='100%' pb={7}>
        <CardContent>
          <Stack spacing={1}>
            <IndexIndicator total={questions.length} idx={idx} />
            <Typography variant='h5' py={2}>{`${idx + 1}. ${
              questions[idx].question
            }`}</Typography>
            <TriviaAnswersGrid
              answers={questions[idx].answers}
              onSelectAnswer={answerIdx => {
                selectAnswer(answerIdx, idx);
              }}
            />
          </Stack>
        </CardContent>
        <Box px={4}>
          <Button
            variant='contained'
            fullWidth
            disabled={!canContinue}
            onClick={onClickNext}
            sx={{
              '&.Mui-disabled': {
                color: 'white',
                backgroundColor: '#ec342f',
                opacity: 0.4,
              },
            }}
          >
            {t('next')}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};
