import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { ModalLayout } from '../../layouts';
import { Incentive } from '../../types';
import { useTranslation } from 'react-i18next';
import { SurveyQuestionSlider } from '../../components';

type SurveyCoverModalProps = {
  survey: Incentive;
  onClickQuit: () => void;
  onClose: () => void;
  onFinishSurvey: (
    answers: number[],
    openAnswers: string[],
    extraAnswers: string[][],
    comment: string | null
  ) => void;
};

const keyPrefix = 'modals.SurveyCoverModal';

export const SurveyCoverModal: FC<SurveyCoverModalProps> = ({
  survey,
  onClose,
  onClickQuit,
  onFinishSurvey,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  const { coverTitle, questions, comments } = survey;

  return (
    <ModalLayout onClose={onClose} keepOpened withExpandedStyle>
      <Stack justifyContent='center' mx='0' rowGap={1}>
        <Typography variant='h3' textAlign='center'>
          {coverTitle}
        </Typography>
        <Box>
          <SurveyQuestionSlider
            questions={questions!}
            comments={comments || ''}
            onFinishSurvey={onFinishSurvey}
          />
        </Box>
        <Box width='370px' margin='0 auto'>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClickQuit}
            fullWidth
          >
            {t('cancel')}
          </Button>
        </Box>
      </Stack>
    </ModalLayout>
  );
};
