import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectSession,
  useGetFavoritesMutation,
  useGetPromosMutation,
} from '../redux';

export const useFavoritePage = () => {
  const session = useSelector(selectSession);

  const [loading, setLoading] = useState<boolean>(false);

  const [getPromos, { isLoading: loadingPromos, data: promosData }] =
    useGetPromosMutation();
  const [getFavorites, { isLoading: loadingFavorites }] =
    useGetFavoritesMutation();

  useEffect(() => {
    setLoading(loadingPromos || loadingFavorites);
  }, [loadingPromos, loadingFavorites]);

  // const fetchPromos = useCallback(async () => {
  const fetchPromos = async () => {
    await getPromos(session);
    // eslint-disable-next-line
  };
  // }, [session, getPromos]);

  // const fetchFavorites = useCallback(async () => {
  const fetchFavorites = async () => {
    await getFavorites(session);
    // eslint-disable-next-line
  };
  // }, [session, getPromos]);

  useEffect(() => {
    fetchPromos(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchFavorites();
    // eslint-disable-next-line
  }, [promosData]);

  return { loading };
};
