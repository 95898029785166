import { FC, useEffect, useState } from "react";
import { Box, Fab, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageZoom from "react-image-zooom";

import { IconLeftArrowSvg, IconRightArrowSvg } from "../assets";
import { useSelector } from "react-redux";
import { selectActivePage } from "../redux";
import "./../css/styles.css";

type ModalViewerZoomLayoutProps = {
  show: boolean;
  data: string[];
  withExpandedStyle?: boolean;
  onClose: () => void;
};

export const ModalViewerZoomLayout: FC<ModalViewerZoomLayoutProps> = ({
  show,
  data,
  onClose,
}): JSX.Element => {
  const [aspectRatio, setAspectRatio] = useState(1);
  const [imageOrientation, setImageOrientation] = useState("horizontal");
  const { currentIndexImage } = useSelector(selectActivePage);
  const [idx, setIdx] = useState<number>(0);

  const canBack = idx > 0;
  const canForward = idx < data.length - 1;
  const showArrows = data.length > 1;

  const back = (): void => {
    setIdx((idx) => (canBack ? idx - 1 : idx));
    // dispatch(changeCurrentIndexImage(canBack ? idx - 1 : idx))
  };

  const next = (): void => {
    setIdx((idx) => (canForward ? idx + 1 : idx));
    // dispatch(changeCurrentIndexImage(canForward ? idx + 1 : idx))
  };

  useEffect(() => {
    currentIndexImage !== undefined && setIdx(currentIndexImage);
  }, [currentIndexImage]);

  useEffect(() => {
    const img = new Image();
    img.src = data[idx];
    img.onload = () => {
      const ratio = img.naturalWidth / img.naturalHeight;
      setAspectRatio(ratio);
    };
  }, [data, idx]);

  return (
    <Modal open={show} sx={{ backgroundColor: "#000000B3" }} disableAutoFocus>
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 4,
          paddingRight: 4,
          gap: 4
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10, color: "white" }}
          onClick={() => {
            setIdx(currentIndexImage !== undefined ? currentIndexImage : 0);
            onClose();
          }}
        >
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>
        {showArrows && (
          <Fab
            aria-label="add"
            sx={{
              width: "50px",
              height: "50px",
              opacity: canBack ? 1 : 0.3,
              svg: {
                width: "44px",
                height: "44px",
              },
            }}
            disabled={!canBack}
            onClick={back}
          >
            <IconLeftArrowSvg />
          </Fab>
        )}
        <Box
          sx={{
            width: aspectRatio >= 1 ? "100%" : `${aspectRatio * 100}vh`,
            height: aspectRatio < 1 ? "100%" : `${100 / aspectRatio}vw`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <ImageZoom src={data[idx]} zoom={300} width="100%" height="100%" />
        </Box>

        {showArrows && (
          <Fab
            aria-label="add"
            sx={{
              width: "50px",
              height: "50px",
              opacity: canForward ? 1 : 0.3,
              svg: {
                width: "44px",
                height: "44px",
              },
            }}
            disabled={!canForward}
            onClick={next}
          >
            <IconRightArrowSvg />
          </Fab>
        )}
      </Box>
      {/* <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          gap: 4,
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10, color: "white" }}
          onClick={() => {
            setIdx(currentIndexImage !== undefined ? currentIndexImage : 0);
            onClose();
          }}
        >
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>

        {showArrows && (
          <Fab
            aria-label="add"
            sx={{
              width: "50px",
              height: "50px",
              opacity: canBack ? 1 : 0.3,
              svg: {
                width: "44px",
                height: "44px",
              },
            }}
            disabled={!canBack}
            onClick={back}
          >
            <IconLeftArrowSvg />
          </Fab>
        )}
        <Box
          sx={{
            // maxWidth: "100%",
            // maxHeight: "100%",
            // width: "100%",
            // height: "100%",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // overflow: "hidden",
          }}
        >
          <ImageZoom
            src={data[idx]}
            zoom={300}
            // className="responsive-image"
            // width="auto"
            // height="100hv"
          />
        </Box>

        {showArrows && (
          <Fab
            aria-label="add"
            sx={{
              width: "50px",
              height: "50px",
              opacity: canForward ? 1 : 0.3,
              svg: {
                width: "44px",
                height: "44px",
              },
            }}
            disabled={!canForward}
            onClick={next}
          >
            <IconRightArrowSvg />
          </Fab>
        )}
      </Box> */}
    </Modal>
  );
};
