import React from 'react';

export const IconNewSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 86.21 86.21'
      {...props}
    >
      <g data-name='Capa 2'>
        <path d='M52.34 0H0l86.21 86.21V33.87L52.34 0z' fill='#d52b1e' />
        <path
          d='M38.89 25.65L37.22 24l2.08-8.6-5.3 5.32L32.28 19l8.13-8.12 1.67 1.67L40 21.21l5.36-5.36L47 17.52zm15.74-.51l-5.35 5.35a3.87 3.87 0 01-3 1.27 4.55 4.55 0 01-3-1.5 4.61 4.61 0 01-1.51-3A3.8 3.8 0 0143 24.32l5.41-5.41 1.67 1.67-5.36 5.36a2.22 2.22 0 00-.78 1.55 2.06 2.06 0 00.69 1.42 1.84 1.84 0 002.92-.05l5.45-5.4zM56.3 36l-3.22-3.21L50.89 35l3.78 3.78-1.35 1.34-5.44-5.45L56 26.51 61.44 32l-1.36 1.3-3.76-3.76-1.94 1.93 3.22 3.22zm3.02 6.05l7.95-4.27 1.87 1.87-11 5.29-1.74-1.75 5.3-10.94 1.86 1.86zm12.94 9.21a7 7 0 01-2.53 1.68 4.51 4.51 0 01-2.61.18 5.08 5.08 0 01-3.62-3.61 4.59 4.59 0 01.16-2.61 6.89 6.89 0 011.63-2.52l.4-.4a6.89 6.89 0 012.55-1.68 4.55 4.55 0 012.62-.18 5 5 0 013.61 3.61 4.58 4.58 0 01-.18 2.63 6.71 6.71 0 01-1.67 2.54zm-1.33-2.07a4.78 4.78 0 001.48-2.39 2.28 2.28 0 00-2.59-2.62 4.76 4.76 0 00-2.39 1.46L67 46a4.84 4.84 0 00-1.47 2.39 2.26 2.26 0 002.59 2.63 4.84 4.84 0 002.38-1.47z'
          fill='#fff'
        />
      </g>
    </svg>
  );
};
