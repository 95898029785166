import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClickAwayListener,
  MenuList,
  MenuItem as MUIMenuItem,
  Paper,
  Grow,
  Popper,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import { MenuItem } from '../../types';
import { Stack } from '@mui/system';

import {
  changeActivePage,
  selectSession,
  useLogoutMutation,
} from '../../redux';
import { BackdropLoading } from '../basics';
import { YesNoQuestionModal } from '../../modals';

type DropdownMenuHelpProps = {
  anchorRef: RefObject<HTMLAnchorElement>;
  open: boolean;
  setOpen(open: boolean): void;
};

const keyPrefix = 'components.menu.DropdownMenuHelp';

export const DropdownMenuHelp: FC<DropdownMenuHelpProps> = ({
  anchorRef,
  open,
  setOpen,
}): JSX.Element => {
  const dispatch = useDispatch();
  const prevOpen = useRef(open);
  const session = useSelector(selectSession);

  const { t } = useTranslation('translation', { keyPrefix });

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const goToPage = (pageName: string) => {
    setOpen(false);
    dispatch(changeActivePage({ activePage: pageName }));
  };

  const goFAQ = (): void => goToPage('faq');
  const goReport = (): void => goToPage('report');
  const goSuggestions = (): void => goToPage('suggest');
  const goTC = (): void => goToPage('tc');
  const goAbout = (): void => goToPage('about');

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
    // eslint-disable-next-line
  }, [open]);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      sx={{ zIndex: 1 }}
      placement='bottom-start'
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper
            sx={{
              minWidth: 339,
              padding: '0 6px',
              marginTop: 1,
              borderRadius: '11px',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                <MUIMenuItem sx={{ width: '100%' }} onClick={goFAQ}>
                  <ItemLabel text={t('faq')} />
                </MUIMenuItem>
                <MUIMenuItem sx={{ width: '100%' }} onClick={goReport}>
                  <ItemLabel text={t('report')} />
                </MUIMenuItem>
                <MUIMenuItem sx={{ width: '100%' }} onClick={goSuggestions}>
                  <ItemLabel text={t('suggestions')} />
                </MUIMenuItem>
                <MUIMenuItem sx={{ width: '100%' }} onClick={goTC}>
                  <ItemLabel text={t('tc')} />
                </MUIMenuItem>
                <MUIMenuItem sx={{ width: '100%' }} onClick={goAbout}>
                  <ItemLabel text={t('about')} />
                </MUIMenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export const ItemLabel: FC<{ text: string }> = ({ text }) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      paddingLeft={0}
    >
      {text}
      <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
    </Stack>
  );
};
