import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  TriviaCoverModal,
  TriviaQuestionsModal,
  TriviaResultsModal,
} from '../../../modals';
import { CancelIncentiveConfirmationModal } from '../../../modals/incentives/CancelIncentiveConfirmationModal';
import {
  parseTriviaAnswers,
  parseTriviaExtraSurveyIncentive,
} from '../../../redux';
import { Incentive } from '../../../types';
import { SurveyViewer } from '../survey';

type TriviaViewerProps = {
  trivia: Incentive;
  onFinishTrivia: () => void;
  onLeaveIncentives: () => void;
};

export const TriviaViewer: FC<TriviaViewerProps> = ({
  trivia,
  onFinishTrivia,
  onLeaveIncentives,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [showTrivia, setShowTrivia] = useState<boolean>(true);
  const [showTriviaQuestions, setShowTriviaQuestions] =
    useState<boolean>(false);
  const [showCancelConfirmationTrivia, setShowCancelConfirmationTrivia] =
    useState<boolean>(false);
  const [showTriviaResults, setShowTriviaResults] = useState<boolean>(false);
  const [showAssociatedSurvey, setShowAssociatedSurvey] =
    useState<boolean>(false);
  const [givenAnswers, setGivenAnswers] = useState<number[]>([]);
  const [score, setScore] = useState<number>(0);

  const noop = () => {};

  const nextIncentive = () => {
    const associatedSurvey = trivia.extraIncentive;
    if (!!associatedSurvey) {
      setShowAssociatedSurvey(true);
    } else {
      setShowAssociatedSurvey(false);
      finishIncentive();
    }
  };

  const closeAssociatedModal = () => {
    setShowCancelConfirmationTrivia(true);
  };

  const closeTriviaModal = () => {
    setShowTrivia(false);
    setShowCancelConfirmationTrivia(true);
  };

  const leaveIncentivesConfirmationModel = () => {
    setShowCancelConfirmationTrivia(false);
    setShowAssociatedSurvey(false);
    setShowTriviaResults(false);
    setShowTriviaQuestions(false);
    onLeaveIncentives();
  };

  const closeTriviaQuestionsModal = () => {
    setShowTrivia(false);
    setShowCancelConfirmationTrivia(true);
  };

  const participate = () => {
    setShowTrivia(false);
    setShowCancelConfirmationTrivia(false);
    !showAssociatedSurvey && setShowTriviaQuestions(true);
  };

  const onFinishTriviaQuestions = (answers: number[]) => {
    setShowTriviaQuestions(false);
    setGivenAnswers(answers);
    setShowTriviaResults(true);
  };

  const finishIncentive = () => {
    dispatch(parseTriviaAnswers({ givenAnswers, score }));
    setShowAssociatedSurvey(false);
    setShowTriviaResults(false);
    onFinishTrivia();
  };

  const finishIncentiveWithExtraIncentive = async (
    surveyAnswers: number[],
    surveyOpenAnswers: string[],
    surveyExtraAnswers: string[][],
    surveyComment: string | null
  ) => {
    // console.log('given answers', givenAnswers);
    // console.log('surveyAnswers', surveyAnswers);
    // console.log('surveyOpenAnswers', surveyOpenAnswers);
    // console.log('surveyExtraAnswers', surveyExtraAnswers);
    // console.log('surveyComment', surveyComment);
    dispatch(parseTriviaAnswers({ givenAnswers, score }));
    dispatch(
      parseTriviaExtraSurveyIncentive({
        surveyAnswers,
        surveyExtraAnswers,
        surveyOpenAnswers,
        surveyComment,
      })
    );
    setShowAssociatedSurvey(false);
    setShowTriviaResults(false);
    // console.log('calling shitf');
    onFinishTrivia();
  };

  const onCalculateScore = (score: number) => {
    setScore(score);
  };

  useEffect(() => {
    setShowTrivia(true);
  }, [trivia]);

  // useEffect(validateTrivia, [triviaList]);

  return (
    <>
      {showAssociatedSurvey && (
        // <Box
        //   sx={{
        //     display: hideSurveyViewer ? 'none' : 'block',
        //   }}
        // >
        <SurveyViewer
          survey={trivia.extraIncentive!}
          onClickQuit={closeAssociatedModal}
          onFinishSurvey={finishIncentiveWithExtraIncentive}
          isExtraIncentive
        />
        // </Box>
      )}
      {showTrivia && (
        <TriviaCoverModal
          trivia={trivia}
          onClickYes={participate}
          onClickQuit={closeTriviaModal}
          onClose={noop}
        />
      )}
      {showCancelConfirmationTrivia && (
        <CancelIncentiveConfirmationModal
          onClickNo={participate}
          onClickYes={leaveIncentivesConfirmationModel}
        />
      )}
      {showTriviaQuestions && (
        <TriviaQuestionsModal
          trivia={trivia}
          onClickQuit={closeTriviaQuestionsModal}
          onFinishTriviaQuestions={onFinishTriviaQuestions}
        />
      )}
      {showTriviaResults && (
        <TriviaResultsModal
          trivia={trivia}
          givenAnswers={givenAnswers}
          onClickOk={nextIncentive}
          onCalculateScore={onCalculateScore}
        />
      )}
    </>
  );
};
