import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';

import { ContentGrid } from '../components';
import { useProductPage } from '../hooks';
import { selectActivePage } from '../redux';

export const ProductPage = () => {
  const { selectedMenuItem } = useSelector(selectActivePage);
  const productList = useProductPage(selectedMenuItem?.id);

  return (
    <Container maxWidth='lg'>
      <Box padding='32px 105px'>
        <ContentGrid
          contentList={productList}
          title={selectedMenuItem!.label}
        />
      </Box>
    </Container>
  );
};
