import { useSelector } from "react-redux";
import { Box, Container } from "@mui/material";

import { ContentGrid } from "../components";
import { selectActivePage, selectContents } from "../redux";
import { useEffect, useState } from "react";
import { Content } from "../types";
import { useTranslation } from "react-i18next";

const keyPrefix = "pages.SearchPage";

export const SearchPage = () => {
  const [filterContentList, setFilterContentList] = useState<Content[]>([]);
  const { searchingContent } = useSelector(selectActivePage);
  const contents = useSelector(selectContents);
  const { t } = useTranslation("translation", { keyPrefix });

  useEffect(() => {
    const filteredContent = contents.filter(
      ({ name, details }: Content) =>
        name.toLowerCase().includes(searchingContent!.toLowerCase()) ||
        details[0].description
          .toLowerCase()
          .includes(searchingContent!.toLowerCase())
    );

    setFilterContentList(filteredContent);
    // eslint-disable-next-line
  }, [searchingContent]);

  return (
    <Container maxWidth="lg">
      <Box padding="32px 105px">
        <ContentGrid
          contentList={filterContentList}
          title={t("title").replace("{searchingContent}", searchingContent!)}
        />
      </Box>
    </Container>
  );
};
