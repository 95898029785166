import { useTranslation } from 'react-i18next';
import Image from 'mui-image';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from '@mui/material';

import packageJson from '../../package.json';
import completeLogo from '../assets/imgs/complete-logo.png';

const keyPrefix = 'pages.AboutPage';

export const AboutPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        marginTop: '-64px',
      }}
    >
      <Card sx={{ width: 934, height: 468 }}>
        <CardContent>
          <Box
            mt='51px'
            width='100%'
            display='flex'
            alignItems='center'
            flexDirection='column'
          >
            <Image
              src={completeLogo}
              duration={0}
              width={270}
              height={95}
              showLoading
            />
            <Divider
              sx={{
                height: 8,
                width: '100%',
                marginTop: '75px',
                marginBottom: '41px',
              }}
            />
            <ul>
              <li>
                <Typography variant='h5' fontSize='26px'>
                  {`${t('version')} ${packageJson.version}`}
                </Typography>
              </li>
            </ul>
            <Typography variant='h6' fontWeight='500'>
              {t('copy')}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
