import { useFormContext, Controller } from 'react-hook-form';
import { InputBaseProps } from '@mui/material';

import { SelectField } from '../basics';
import { useEffect } from 'react';

type Props = {
  inputProps?: InputBaseProps['inputProps'];
  defaultValue?: string | number | null;
  disabled?: boolean;
  label?: string;
  name: string;
  type?: string;
  values: { value: string | number; label: string }[];
  onSelectValue?: (selectedValue: string | number) => void;
};

export const FormSelectField: React.FC<Props> = ({
  inputProps,
  defaultValue,
  disabled,
  label,
  name,
  type,
  values,
  onSelectValue,
}): JSX.Element => {
  const { control, clearErrors, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue !== undefined ? defaultValue : null);
    // eslint-disable-next-line
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        const handleOnChange = (event: any) => {
          const newValue = event.target.value;

          clearErrors(name);
          !!onChange && onChange(newValue);
          !!onSelectValue && onSelectValue(newValue);
          setValue(name, newValue);
        };

        return (
          <SelectField
            disabled={disabled}
            error={!!error}
            helperText={!!error ? error.message : null}
            label={label}
            inputProps={inputProps}
            type={type}
            value={value}
            onChange={handleOnChange}
            values={values}
          />
        );
      }}
    />
  );
};
