import React from 'react';

export const IconRightArrowSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='#455d7a'
        d='M7.583 3.2a1.341 1.341 0 011.471.293l7.552 7.554a1.337 1.337 0 01.4.953 1.342 1.342 0 01-.4.956l-7.548 7.55a1.345 1.345 0 01-.953.4 1.345 1.345 0 01-.518-.1 1.347 1.347 0 01-.833-1.247V4.454A1.346 1.346 0 017.583 3.2z'
        data-name='Trazado 5675'
      />
      <g data-name='Grupo 7286'>
        <path fill='none' d='M0 0h24v24H0z' data-name='Rect\xE1ngulo 4399' />
      </g>
    </svg>
  );
};
