import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { ExtraQuestion } from '../../../types';
import { TextField } from '../../basics';

type OpenQuestionProps = {
  from?: ExtraQuestion;
  onChange: (newValue: string) => void;
};

const keyPrefix = 'components.incentives.OpenQuestion';

export const OpenQuestion: FC<OpenQuestionProps> = ({
  from,
  onChange,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  const [value, setValue] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box my={4}>
      {!!from && (
        <Typography variant='body1' py={2}>
          {from.question}
        </Typography>
      )}
      <Box
        sx={{
          div: {
            display: 'flex',
            flexDirection: 'row',
          },
        }}
      >
        <TextField
          label={t('instructions')}
          inputProps={{ maxLength: 280 }}
          rows={5}
          onChange={handleChange}
          value={value ? value : ''}
          fullWidth
          multiline
        />
      </Box>
    </Box>
  );
};
