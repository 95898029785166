import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActivity,
  selectPropertiesApp,
  selectSession,
  startActive,
  startInactive,
  useGetFavoritesMutation,
  useGetIncentivesMutation,
  useGetPromosMutation,
  useGetReactionListMutation,
  useLazyGetPropertiesQuery,
  useReportActivityMutation,
} from '../redux';
import { useContentBadges } from './useContentBadges';
import { API_BASE_URL } from '../config';

export const useLoadPropertiesAndPromos = () => {
  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const activity = useSelector(selectActivity);
  const properties = useSelector(selectPropertiesApp);
  const propertiesUrl =
    !!properties && !!properties["url.service.properties"]
      ? properties["url.service.properties"].replace(/^http:/, 'https:')
      : `${API_BASE_URL}/v5/properties`;

  const { updateBadges } = useContentBadges();

  const [getProperties, { isLoading: loadingProps }] =
    useLazyGetPropertiesQuery();
  const [getPromos, { isLoading: loadingPromos }] = useGetPromosMutation();
  const [getFavorites, { isLoading: loadingFavorites }] =
    useGetFavoritesMutation();
  const [getReactions] = useGetReactionListMutation();
  const [getIncentives] = useGetIncentivesMutation();
  const [reportActivity] = useReportActivityMutation();

  const [loading, setLoading] = useState<boolean>(false);

  // const loadData = useCallback(async () => {
  const loadData = async () => {
    console.log('loading data...');
    console.log('properties', propertiesUrl);
    
    await getProperties(propertiesUrl);
    await getPromos(session);
    // await updateBadges();
    await getFavorites(session);
    await getReactions(session);
    // eslint-disable-next-line
  };
  // }, [session, getPromos, getProperties]);

  const sendActivityList = () => {
    // console.log('activity', activity);
    reportActivity({ session, activity });
  };

  useEffect(() => {
    setLoading(loadingPromos || loadingProps || loadingFavorites);
  }, [loadingPromos, loadingProps, loadingFavorites]);

  useEffect(() => {
    const handleFocus = () => {
      // console.log('focus');
      dispatch(startActive());
      loadData();
    };

    const handleBlur = () => {
      // console.log('Tab lost focus');
      dispatch(startInactive());
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    loadData();
    //To call it just once
    getIncentives(session);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    sendActivityList, // eslint-disable-next-line
    [activity]
  );

  return { loading };
};
