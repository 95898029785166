import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';

import { IconDownloadSvg, IconPDFSvg } from '../../assets';
import { selectSession, useReportContentDownloadedMutation } from '../../redux';

type DownloadContentButtonProps = {
  contentId: number;
  downloadUrl?: string | string[];
  isPDF?: boolean;
};

const keyPrefix = 'components.content.DownloadContentButton';

export const DownloadContentButton: FC<DownloadContentButtonProps> = ({
  contentId,
  downloadUrl,
  isPDF = false,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const session = useSelector(selectSession);
  const [reportDownloaded] = useReportContentDownloadedMutation();

  const getFileName = (url: string | undefined) =>
    url?.substring(url?.lastIndexOf('/') + 1, url!.length) || 'file.pdf';

  const handleClick = () => {
    // console.log('downloadUrl', downloadUrl);
    // console.log('fileName', fileName);

    if (Array.isArray(downloadUrl)) {
      downloadUrl.forEach(url => {
        saveAs(url, getFileName(url));
      });
    } else {
      saveAs(downloadUrl!, getFileName(downloadUrl));
    }

    reportDownloaded({ session, contentId });

    // const blob = await fetch(downloadUrl!).then(r => r.blob());
    // console.log('blob', blob);
    // const url = window.URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'file.pdf');
    // document.body.appendChild(link);
    // link.click();
  };

  // console.log('downloadUrl', downloadUrl);
  // console.log('fileName', fileName);

  return (
    <Button
      color='inherit'
      sx={{
        minWidth: '250px',
        textTransform: 'none',
        fontSize: '20px',
        color: grey[600],
        justifyContent: 'flex-start',
      }}
      onClick={handleClick}
      startIcon={
        isPDF ? <IconDownloadSvg width={34} /> : <IconPDFSvg width={34} />
      }
    >
      <Box sx={{ paddingTop: '6px' }}>{t(`label`)}</Box>
    </Button>
  );
};
