import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';

export const recoveryPasswordApi = createApi({
  reducerPath: 'recoveryPasswordApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    recoveryPassword: builder.mutation({
      query: (identificationValue: string) => {
        return {
          url: 'v4/recoverypassword',
          method: 'POST',
          body: { identificationValue },
        };
      },
    }),
  }),
});

export const { useRecoveryPasswordMutation } = recoveryPasswordApi;
