import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { selectAuthError, selectIsBlockedUser, selectLoginAttempts, selectPropertiesApp } from "../redux";
import { useClearLoginInfo, useLoginFormManagement } from "../hooks";
import {
  LoginFormErrorAlert,
  FormTextField,
  FieldsValidationErrorAlert,
  BackdropLoading,
  TypeRadio,
  FormRadio,
} from "../components";
import { MessageModal, MessageModalTimer } from "../modals";

const keyPrefix = "forms.LoginForm";

export const LoginForm: FC = (): JSX.Element => {
  const { t } = useTranslation("translation", { keyPrefix });
  const loginAttempts = useSelector(selectLoginAttempts);
  const [showErroMessage, setShowErroMessage] = useState<boolean>(false);
  const [showAuthErroMessage, setShowAuthErroMessage] = useState<boolean>(false);
  const {
    defaultValues,
    fieldsValidationErr,
    genericErr,
    loading,
    methods,
    submit,
  } = useLoginFormManagement();
  const { clearDataLogin } = useClearLoginInfo();

  const authError = useSelector(selectAuthError);
  const isBlocked = useSelector(selectIsBlockedUser);
  const properties = useSelector(selectPropertiesApp);
  const typeLoginValue = methods.watch("typeLogin");
  const attempts = parseInt(properties['login.attempts']);

  const optionsLogin: TypeRadio[] = [
    {
      label: t("typeLogin.1"),
      value: "1",
    },
    {
      label: t("typeLogin.2"),
      value: "2",
    },
  ];

  useEffect(() => {
    console.log(authError);
    (!!authError) && setShowAuthErroMessage(true)
  }, [authError, loginAttempts]);

  useEffect(() => {
    console.log("Intentos restantes", loginAttempts);
    console.log("Intentos", attempts);
    
  }, [loginAttempts])
  

  return (
    <>
      {showAuthErroMessage && (
        <MessageModalTimer
          message={authError!}
          okLabel={t("modals.genericErr.ok")}
          timer={(loginAttempts !== null && loginAttempts <= 1) || isBlocked}
          onClickOk={() => {
            setShowAuthErroMessage(false);
          }}
          onClose={() => {
            setShowAuthErroMessage(false);
          }}
        />
      )}
      {showErroMessage && (
        <MessageModal
          message={authError!}
          okLabel={t("modals.genericErr.ok")}
          onClickOk={() => {
            setShowErroMessage(false);
          }}
          onClose={() => {
            setShowErroMessage(false);
          }}
        />
      )}
      {loading && <BackdropLoading />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Stack spacing={2} pt={2}>
            {fieldsValidationErr && (
              <FieldsValidationErrorAlert error={fieldsValidationErr} />
            )}
            {((loginAttempts !== null && loginAttempts >= 0) && !!authError) && (
              <LoginFormErrorAlert />
            )}
            <FormTextField
              name="username"
              label={t(`user.${typeLoginValue}`)}
              inputProps={{ maxLength: 50 }}
              disabledErrors={true}
            />
            <FormTextField
              name="password"
              label={t("pass.label")}
              inputProps={{ maxLength: 8 }}
              type="password"
              disabledErrors
            />
            <FormRadio name="typeLogin" items={optionsLogin} />
            <Box pt={2}>
              <Button type="submit" variant="contained" fullWidth>
                {t("submit.label")}
              </Button>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
