import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Content } from '../../types';
import { getImageCoverURL, parseValidity } from './Util';
import { ContentItemCallToAction } from './ContentItemCallToAction';
import { MediaType } from '../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveMenuContent, selectActivePage } from '../../redux';
import { DropdownFavoriteMenu } from '../menu';
import grey from '@mui/material/colors/grey';
// import { IconNewSvg } from '../../assets';

const keyPrefix = 'components.content.ContentItem';

export const FavoriteItem: FC<Content> = (favorite): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const optionsAnchorRef = useRef<HTMLAnchorElement>(null);
  const { activePage } = useSelector(selectActivePage);
  const [openOptions, setOpenOptions] = useState(false);
  const dispatch = useDispatch();
  // const newContentIds = useSelector(selectNewContent);

  const { effectiveEndDate, media, mediaType, effectiveStartDate, name } =
    favorite;

  const goToDetail = (): void => {
    dispatch(
      changeActiveMenuContent({
        activePage: mediaType === MediaType.VIDEO ? 'video' : 'magazine',
        selectedContent: favorite,
        selectedMenuItem: undefined,
        prevPage: activePage
      })
    );
  };

  // const isNew = newContentIds.includes(content.id.toString());

  return (
    <>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: '276px 1fr',
          columnGap: 2,
        }}
      >
        <CardMedia
          component='img'
          image={getImageCoverURL(mediaType, media)}
          sx={{ borderRadius: 0, cursor: 'pointer' }}
          height={156}
          onClick={goToDetail}
        />
        <CardContent sx={{ padding: '32px 18px', backgroundColor: 'white' }}>
          <Grid container spacing={1} height={100}>
            <Grid item md={1} textAlign='center' paddingX={6}>
              <ContentItemCallToAction {...favorite} />
            </Grid>
            <Grid item md={9.5}>
              <Stack spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  {name}
                </Typography>
                <Typography variant='body2'>
                  {parseValidity(
                    effectiveStartDate,
                    effectiveEndDate,
                    t('validityExpression')
                  )}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              md={1}
              display='center'
              justifyContent='center'
              alignItems='center'
            >
              <Link
                sx={{ cursor: 'pointer' }}
                color={grey[600]}
                ref={optionsAnchorRef}
                onClick={() => {
                  setOpenOptions(prevOpen => !prevOpen);
                }}
              >
                <MoreVertIcon />
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DropdownFavoriteMenu
        content={favorite}
        anchorRef={optionsAnchorRef}
        open={openOptions}
        setOpen={setOpenOptions}
      />
    </>
  );
};
