import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { AddFavoriteParams, Session } from '../../types';
import { getAppInfo } from './appInfo';

export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getFavorites: builder.mutation({
      query: ({ sessionToken, userId }: Session) => {
        return {
          url: 'v1/favorites',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            processType: 0,
          },
        };
      },
    }),
    addFavorite: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        idContent,
      }: AddFavoriteParams) => {
        return {
          url: 'v1/favorites',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            idContent,
            processType: 1,
          },
        };
      },
    }),
    removeFavorite: builder.mutation({
      query: ({
        session: { sessionToken, userId },
        idContent,
      }: AddFavoriteParams) => {
        return {
          url: 'v1/favorites',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
            idContent,
            processType: 2,
          },
        };
      },
    }),
  }),
});

export const {
  useGetFavoritesMutation,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} = favoritesApi;
