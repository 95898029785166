import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, BrowserRouter, Route } from "react-router-dom";

import {
  LoginPage,
  NotFoundPage,
  ResetPasswordPage,
  RecoverPasswordPage,
  SignUpPage,
} from "../pages";
import {
  selectPropertiesApp,
  selectRequireResetPassword,
  useLazyGetPropertiesQuery,
} from "../redux";
import { AnonymousLayout, AppLayout } from "../layouts";
import PrivateOutlet from "./PrivateOutlet";
import PublicOutlet from "./PublicOutlet";
import { API_BASE_URL } from "../config";

const MainRouter: FC = (): JSX.Element => {
  const requireRestorePassword = useSelector(selectRequireResetPassword);
  const properties = useSelector(selectPropertiesApp);
  const propertiesUrl =
    !!properties && !!properties["url.service.properties"]
      ? properties["url.service.properties"].replace(/^http:/, 'https:')
      : `${API_BASE_URL}/v5/properties`;
  const [getProperties] = useLazyGetPropertiesQuery();

  useEffect(() => {
    const load = async () => {
      try {
        await getProperties(propertiesUrl);
      } catch (e) {
        console.error(e);
      }
    };
    load();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateOutlet />}>
          <Route
            index
            element={
              requireRestorePassword ? <ResetPasswordPage /> : <AppLayout />
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <PublicOutlet>
              <LoginPage />
            </PublicOutlet>
          }
        />
        <Route
          path="recoverPassword"
          element={
            <PublicOutlet>
              <RecoverPasswordPage />
            </PublicOutlet>
          }
        />
        <Route
          path="signUp"
          element={
            <PublicOutlet>
              <SignUpPage />
            </PublicOutlet>
          }
        />
        <Route
          path="help"
          element={
            <PublicOutlet>
              <AnonymousLayout />
            </PublicOutlet>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
