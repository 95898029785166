import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CategoryRelation,
  Channel,
  Department,
  District,
  Locals,
  LocalsApiResponse,
  ModeSale,
  ModeSeller,
  PropertiesApiResponse,
  Province,
  Region,
  TypeSeller,
  Properties
} from '../../types';

import { localsApi, propertiesApi } from '../services';
import { RootState } from '../store';

export interface propertiesState {
  categoryRelation: CategoryRelation[];
  channel: Channel[];
  department: Department[];
  district: District[];
  province: Province[];
  region: Region[];
  modeSale: ModeSale[];
  typeSeller: TypeSeller[];
  modeSeller: ModeSeller[];
  locals: Locals[];
  properties: Properties
}

const initialState: propertiesState = {
  categoryRelation: [],
  channel: [],
  department: [],
  district: [],
  province: [],
  region: [],
  modeSale: [],
  typeSeller: [],
  modeSeller: [],
  locals: [],
  properties: {},
};

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        propertiesApi.endpoints.getProperties.matchFulfilled,
        (state, action: PayloadAction<PropertiesApiResponse>) => {
          const { responseCode } = action.payload.result;
          console.log('payload', action.payload.properties);

          if (responseCode === 200) {
            const { payload } = action;
            state.categoryRelation = payload.categoryRelation;
            state.channel = payload.channel;
            state.department = payload.department;
            state.district = payload.district;
            state.modeSale = payload.modeSale;
            state.modeSeller = payload.modeSeller;
            state.province = payload.province;
            state.region = payload.region;
            state.typeSeller = payload.typeSeller;
            state.properties = payload.properties;
          }
        }
      )
      .addMatcher(
        localsApi.endpoints.getLocals.matchFulfilled,
        (state, action: PayloadAction<LocalsApiResponse>) => {
          const { responseCode } = action.payload.result;
          // console.log('payload', action.payload);

          if (responseCode === 200) {
            const { payload } = action;
            state.locals = payload.locals;
          }
        }
      );
  },
});

// export const { logout } = propertiesSlice.actions;

export const propertiesReducer = propertiesSlice.reducer;
export const selectCategoryRelation = (state: RootState) =>
  state.properties.categoryRelation;
export const selectPropertiesApp = (state: RootState) => state.properties.properties;
export const selectProperties = (state: RootState) => ({
  regions: state.properties.region,
  departments: state.properties.department,
  provinces: state.properties.province,
  districts: state.properties.district,
  channels: state.properties.channel,
  locals: state.properties.locals,
  modeSales: state.properties.modeSale,
  sellerModes: state.properties.modeSeller,
  sellerTypes: state.properties.typeSeller,
});

// export const selectIspropertiesenticated = (state: RootState) => state.properties.isproperties;
// export const selectpropertiesError = (state: RootState) => state.properties.propertiesError;
