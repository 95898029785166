import { FC, useState } from 'react';
import { Box, Button, CardMedia, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { ModalLayout } from '../../layouts';
import { Incentive } from '../../types';
import { useTranslation } from 'react-i18next';
import { MediaModal } from '../MediaModal';
import { getMedia, getMediaImage } from '../../components';

type PublicationCoverModalProps = {
  publication: Incentive;
  onClickQuit: () => void;
  onFinishPublication: () => void;
};

const keyPrefix = 'modals.PublicationCoverModal';

export const PublicationCoverModal: FC<PublicationCoverModalProps> = ({
  publication,
  onClickQuit,
  onFinishPublication,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const [showMedia, setShowMedia] = useState<boolean>(false);
  const { coverTitle, coverDescription, coverTextButton, media } = publication;

  const coverImg = getMediaImage(media!, 1);
  const detailMedia = getMedia(media!, 2);

  const toggleMedia = (): void => {
    setShowMedia(value => !value);
  };

  return (
    <ModalLayout onClose={onClickQuit} keepOpened withExpandedStyle>
      <Stack justifyContent='center' mx='0' rowGap={1}>
        <Typography variant='h3' textAlign='center'>
          {coverTitle}
        </Typography>
        <Typography variant='body1' textAlign='center' lineHeight='33px'>
          {coverDescription}
        </Typography>
        {!!coverImg && (
          <CardMedia
            component='img'
            image={coverImg}
            sx={{
              borderRadius: '12px !important',
              cursor: 'pointer',
              maxWidth: '328px',
              margin: '0 auto',
            }}
          />
        )}
        {!!detailMedia && (
          <Box mt={5}>
            <Link
              width='100%'
              onClick={toggleMedia}
              sx={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              <Typography textAlign='center' variant='h5' color='secondary'>
                {t('detail')}
              </Typography>
            </Link>
          </Box>
        )}
        <Box width='370px' margin='18px auto'>
          <Button variant='contained' onClick={onFinishPublication} fullWidth>
            {coverTextButton}
          </Button>
        </Box>

        <Box width='370px' margin='0 auto'>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClickQuit}
            fullWidth
          >
            {t('cancel')}
          </Button>
        </Box>
      </Stack>
      {!!detailMedia && showMedia && (
        <MediaModal media={detailMedia} onClose={toggleMedia} />
      )}
    </ModalLayout>
  );
};
