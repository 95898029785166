import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { Content } from '../../types';
import { getImageCoverURL, parseValidity } from './Util';
import { ContentItemCallToAction } from './ContentItemCallToAction';
import { MediaType } from '../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveMenuContent, selectActivePage } from '../../redux';

const keyPrefix = 'components.content.ContentItem';

export const ReactionItem: FC<Content> = (favorite): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { activePage } = useSelector(selectActivePage);
  const dispatch = useDispatch();

  const { effectiveEndDate, media, mediaType, effectiveStartDate, name } =
    favorite;

  const goToDetail = (): void => {
    dispatch(
      changeActiveMenuContent({
        activePage: mediaType === MediaType.VIDEO ? 'video' : 'magazine',
        selectedContent: favorite,
        selectedMenuItem: undefined,
        prevPage: activePage
      })
    );
  };

  return (
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: '276px 1fr',
          columnGap: 2,
        }}
      >
        <CardMedia
          component='img'
          image={getImageCoverURL(mediaType, media)}
          sx={{ borderRadius: 0, cursor: 'pointer' }}
          height={156}
          onClick={goToDetail}
        />
        <CardContent sx={{ padding: '32px 18px', backgroundColor: 'white' }}>
          <Grid container spacing={1} height={100}>
            <Grid item md={1} textAlign='center' paddingX={6}>
              <ContentItemCallToAction {...favorite} />
            </Grid>
            <Grid item md={9.5}>
              <Stack spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  {name}
                </Typography>
                <Typography variant='body2'>
                  {parseValidity(
                    effectiveStartDate,
                    effectiveEndDate,
                    t('validityExpression')
                  )}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
};
