import { useState } from 'react';
import {
  FormGroup,
  FormHelperText,
  InputBaseProps,
  MenuItem,
  Select as MuiSelectField,
  SelectProps,
} from '@mui/material';

import { LabelText } from './LabelText';

type ComponentProps = {
  values: { value: string | number; label: string }[];
};

type Props = SelectProps & InputBaseProps['inputProps'] & ComponentProps;

export const SelectField: React.FC<Props> = (props): JSX.Element => {
  const { error, helperText, label, values } = props;

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleFocus = (): void => setIsFocus(true);
  const handleOnblur = (): void => setIsFocus(false);

  return (
    <FormGroup sx={{ minHeight: '86px' }}>
      {!!label && (
        <LabelText focus={isFocus} mb={2} error={error}>
          {label}
        </LabelText>
      )}
      <MuiSelectField
        {...props}
        label=''
        variant='outlined'
        color='secondary'
        onFocus={handleFocus}
        onBlur={handleOnblur}
      >
        {values.map(({ label, value }, idx) => (
          <MenuItem value={value} key={idx}>
            {label}
          </MenuItem>
        ))}
      </MuiSelectField>
      <FormHelperText error>{!!error && helperText}</FormHelperText>
    </FormGroup>
  );
};
