import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from '../../config';
import { PromosApiResponse, Session } from '../../types';
import { getAppInfo } from './appInfo';

export const promosApi = createApi({
  reducerPath: 'promosApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getPromos: builder.mutation<PromosApiResponse, Session>({
      query: ({ sessionToken, userId }) => {
        return {
          url: 'v6/getPromos',
          method: 'POST',
          body: {
            appInfo: getAppInfo(sessionToken),
            userId,
          },
        };
      },
    }),
  }),
});

export const { useGetPromosMutation } = promosApi;
