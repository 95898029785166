import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectIsAuthenticated } from '../redux';

const PrivateOutlet: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const isAuth = useSelector(selectIsAuthenticated);

  return isAuth ? (
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' replace />
  );
};

export default PrivateOutlet;
