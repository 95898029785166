import { Box, Container } from '@mui/material';

import { ReactionsGrid } from '../components';

export const ReactionsPage = () => {

  return (
    <Container maxWidth='lg'>
      <Box padding='32px 105px'>
        <ReactionsGrid />
      </Box>
    </Container>
  );
};
