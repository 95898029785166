import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  changeActiveContent,
  changeActivePage,
  selectActivePage,
  selectFavorites,
  selectSession,
  useReportContentWatchedMutation,
} from "../redux";
import {
  DownloadContentButton,
  FavoriteButton,
  hasVideoThumbnail,
  MagazineViewer,
  parseValidity,
  ReactionButton,
} from "../components";
import { Media } from "../types";
import { useEffect, useState } from "react";
import { useContentBadges } from "../hooks";

const keyPrefix = "pages.MagazinePage";

export const MagazinePage = () => {
  const favorites = useSelector(selectFavorites);
  const { selectedContent, selectedMenuItem, prevPage } =
    useSelector(selectActivePage);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string | string[] | null>(
    selectedContent!.urlPDF
  );

  const [hrefLink, setHrefLink] = useState<string | undefined>(
    selectedContent?.urlContent
  );

  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const { t } = useTranslation("translation", { keyPrefix });
  const { removeBadges } = useContentBadges();

  const [reportContentWatched] = useReportContentWatchedMutation();

  const title =
    prevPage === "home" && selectedContent?.contentType === 101
      ? t("news") //Extract from internationalization
      : prevPage === "home" && selectedContent?.contentType === 100
      ? t("arguments") //Extract from internationalization
      : prevPage === "favorites"
      ? t("favorites")
      : prevPage === "reactions"
      ? t("reactions")
      : selectedMenuItem?.label || "";

  const watchVideo = (): void => {
    // Remove next line to avoid notify same content when user change view to video detail
    // Also see 'goToMagazinePage' method in VideoPage component
    reportContentWatched({ session, contentId: selectedContent!.id });
    dispatch(
      changeActiveContent({
        activePage: "video",
        selectedContent: selectedContent,
        prevPage
      })
    );
  };

  const media = selectedContent?.media;

  const handleMediaChange = ({ urlSource, url }: Media): void => {
    !!urlSource && !!urlSource.trim() && setHrefLink(urlSource);
    // NOTE: Uncomment to download just the image which is visualizing & do 106
    // !selectedContent!.urlPDF && setDownloadUrl(url);
  };

  const goBack = (): void => {
    dispatch(
      changeActivePage({
        activePage: prevPage!,
      })
    );
  };

  useEffect(() => {
    if (favorites.length > 0 && !!selectedContent) {
      setIsFavorite(
        () => !!favorites.find(({ id }) => id === selectedContent.id)
      );
    }
  }, [favorites, selectedContent]);

  useEffect(() => {
    !!selectedContent?.id && removeBadges(selectedContent.id);
    //NOTE: Comment to to download just the image which is visualizing and do 80
    if (!selectedContent!.urlPDF) {
      const urls: string[] = [];
      media?.forEach(({ url, urlVideoThumbnail }) => {
        !urlVideoThumbnail && urls.push(url);
      });
      setDownloadUrl(urls);
    }
    console.log("TITULO", prevPage);

    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        paddingTop={4}
        sx={{ cursor: "pointer" }}
        onClick={goBack}
      >
        <ArrowBackIcon
          fontSize="large"
          sx={{ paddingTop: "4px", marginRight: "12px" }}
        />
        {title}
      </Typography>
      <Grid container paddingTop={1} spacing={0}>
        <Grid item md={6}>
          <Box maxWidth={432}>
            <MagazineViewer media={media} onMediaChange={handleMediaChange} />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={2.5}
          >
            <Typography variant="h4" sx={{ fontSize: "30px" }}>
              {selectedContent?.name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "18px" }}>
              {selectedContent?.details[0].description}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              {parseValidity(
                selectedContent?.effectiveStartDate!,
                selectedContent?.effectiveEndDate!,
                t("validityExpression")
              )}
            </Typography>
            {!!hrefLink && hrefLink !== "null" && (
              <Link
                variant="body2"
                color="secondary"
                href={hrefLink}
                underline="hover"
                target="_blank"
                sx={{ fontSize: "16px" }}
              >
                {t(
                  `${
                    selectedContent?.urlContent === undefined
                      ? "contentLink"
                      : "pageLink"
                  }`
                )}
              </Link>
            )}
          </Stack>
          <Stack direction="column" paddingTop={1} spacing={1} marginLeft={5}>
            <ReactionButton
              justifyContent="flex-start"
              idContent={selectedContent?.id!}
            />
            <FavoriteButton
              justifyContent="flex-start"
              idContent={selectedContent?.id!}
              initialState={isFavorite}
            />
            <DownloadContentButton
              contentId={selectedContent?.id!}
              downloadUrl={downloadUrl!}
              isPDF={!selectedContent!.urlPDF}
            />
          </Stack>
          {hasVideoThumbnail(media!) && (
            <Box display="flex" justifyContent="center" paddingTop={3}>
              <Button
                variant="contained"
                color="warning"
                sx={{ color: "white", minWidth: 400 }}
                onClick={watchVideo}
              >
                {t("watchVideo")}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
