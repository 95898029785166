import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useRecoveryPasswordMutation } from '../../redux';

export interface RecoverPasswordFormFields {
  identificationValue: string;
}

const keyPrefix = 'hooks.formManagements.useRecoverPasswordFormManagement';

const defaultValues: RecoverPasswordFormFields = { identificationValue: '' };

export const useRecoverPasswordFormManagement = () => {
  const [fieldsValidationErr, setFieldsValidationErr] = useState<
    string | null
  >();
  const [genericErr, setGenericErr] = useState<string | null>();
  const [success, setSuccess] = useState<boolean | null>(null);
  const [response, setResponse] = useState<string | null>(null);
  const { t } = useTranslation('translation', { keyPrefix });

  const [recovery, { isError, isLoading, error: requestErr, data }] =
    useRecoveryPasswordMutation();

  const schema = yup.object().shape({
    identificationValue: yup
      .string()
      .required(t('schema.identificationValue.required')),
    // .min(4, t('schema.identificationValue.min')),
  });

  const methods = useForm<RecoverPasswordFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (isError) {
      console.error(requestErr);
      setGenericErr(t('errors.apiError'));
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    const { formState: errors } = methods;
    setFieldsValidationErr(errors.errors.identificationValue?.message || null);
    // eslint-disable-next-line
  }, [methods.formState.errors]);

  useEffect(() => {
    if (!data) return;

    console.log('data', data);

    const {
      result: { responseCode, responseMessage },
    } = data;

    setSuccess(responseCode === 200);
    setResponse(responseMessage);
  }, [data]);

  const submit: SubmitHandler<RecoverPasswordFormFields> = async ({
    identificationValue,
  }) => {
    clearErrorMessages();
    await recovery(identificationValue);
  };

  const clearErrorMessages = (): void => {
    setGenericErr(null);
    setFieldsValidationErr(null);
    setSuccess(null);
    setResponse(null);
  };

  return {
    fieldsValidationErr,
    genericErr,
    loading: isLoading,
    methods,
    response,
    success,
    clearErrorMessages,
    submit,
  };
};
