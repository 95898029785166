export enum MediaType {
  IMAGE = '1',
  MAGAZINE = '2',
  VIDEO = '3',
  VIDEO_IMAGE = '4',

  // Back never return next media types

  // MAGAZINE_URL = '5',
  // MAGAZINE_VIDEO_URL = '6',
  // PDF_URL = '7',
  // PDF_VIDEO_URL = '8',
}

export enum ReactionType {
  LIKE = 1,
  UTIL = 2,
  RECOMMENDED = 3,
}

export enum NotificationType {
  CONTENT_UPDATED = '1',
  CONTENT_DELETE = '2',
  APP_RESET = '3',
  APP_UPDATE = '4',
}

export enum IncentiveType {
  TRIVIA = 1,
  SURVEY = 2,
  PUBLICATION = 3,
}

export enum ActivityStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}
