import { useFormContext, Controller } from 'react-hook-form';
import { InputBaseProps } from '@mui/material';

import { TextField } from '../basics';
import { ChangeEvent } from 'react';

type Props = {
  inputProps?: InputBaseProps['inputProps'];
  disabled?: boolean;
  disabledErrors?: boolean;
  label?: string;
  name: string;
  onlyNumbers?: boolean;
  isTextArea?: boolean;
  type?: string;
};

const filterNumbers = (val: string) => {
  const re = /^[0-9\b]+$/;
  if (val === '' || re.test(val)) {
    return val;
  }
};

export const FormTextField: React.FC<Props> = ({
  inputProps,
  disabled,
  disabledErrors,
  label,
  name,
  onlyNumbers,
  isTextArea = false,
  type,
}): JSX.Element => {
  const { control, clearErrors, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const newValue = event.target.value;
          const filteredValue = onlyNumbers
            ? filterNumbers(newValue)
            : newValue;

          if (filteredValue !== undefined) {
            clearErrors(name);
            !!onChange && onChange(filteredValue);
            setValue(name, filteredValue);
          }
        };

        return (
          <TextField
            disabled={disabled}
            disabledErrors={disabledErrors}
            error={!!error}
            helperText={!!error ? error.message : null}
            label={label}
            inputProps={inputProps}
            type={type}
            value={value ? value : ''}
            onlyNumbers={onlyNumbers}
            onChange={handleOnChange}
            multiline={isTextArea}
            rows={isTextArea ? 4 : 1}
          />
        );
      }}
    />
  );
};
