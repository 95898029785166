import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { DropdownMenuContent } from './DropdownMenuContent';
import { useContentBadges } from '../../hooks';
import { changeActiveMenuItem, selectActivePage } from '../../redux';
import { DropdownMenuProfile } from './DropdownMenuProfile';
import { DropdownMenuHelp } from './DropdownMenuHelp';

const keyPrefix = 'components.MenuItems';

export const MenuItems: FC = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { searchingContent, activePage } = useSelector(selectActivePage);

  const dispatch = useDispatch();
  const { updateBadges } = useContentBadges();

  const [value, setValue] = useState(() => {
    switch (activePage) {
      case 'products':
        return 1;
      case 'about':
      case 'faq':
      case 'report':
      case 'suggest':
      case 'tc':
        return 2;
      case 'favorites':
        return 3;
      case 'reactions':
        return 4;
      default:
        return 0;
    }
  });

  const productsAnchorRef = useRef<HTMLAnchorElement>(null);
  const halpAnchorRef = useRef<HTMLAnchorElement>(null);
  const profileAnchorRef = useRef<HTMLAnchorElement>(null);

  const [openProducts, setOpenProducts] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const showHomePage = (): void => {
    dispatch(changeActiveMenuItem({ activePage: 'home' }));
  };

  return (
    <Box width='600px' display='flex' justifyContent='flex-end'>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor='secondary'
        indicatorColor='secondary'
        sx={{ display: !!searchingContent ? 'none' : 'block' }}
      >
        <Tab label={t('items.home.label')} onClick={showHomePage} />
        <Tab
          component='a'
          ref={productsAnchorRef}
          label={t('items.products.label')}
          onClick={async () => {
            await updateBadges();
            setOpenProducts(prevOpen => !prevOpen);
          }}
        />
        <Tab
          component='a'
          ref={halpAnchorRef}
          label={t('items.help.label')}
          onClick={() => {
            setOpenHelp(prevOpen => !prevOpen);
          }}
        />
        <Tab
          component='a'
          ref={profileAnchorRef}
          label={t('items.profile.label')}
          icon={<AccountCircleOutlinedIcon fontSize='small' />}
          iconPosition='start'
          onClick={() => {
            setOpenProfile(prevOpen => !prevOpen);
          }}
        />
      </Tabs>
      <DropdownMenuContent
        anchorRef={productsAnchorRef}
        // menuItemList={productList}
        open={openProducts}
        setOpen={setOpenProducts}
      />
      <DropdownMenuHelp
        anchorRef={halpAnchorRef}
        open={openHelp}
        setOpen={setOpenHelp}
      />
      <DropdownMenuProfile
        anchorRef={profileAnchorRef}
        open={openProfile}
        setOpen={setOpenProfile}
      />
    </Box>
  );
};
