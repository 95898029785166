import React from 'react';

export const Face5Svg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' {...props}>
      <g fill='none'>
        <path d='M0 0h43v43H0z' data-name='Rect\xE1ngulo 42' />
        <g
          stroke='#ef1600'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
          data-name='Grupo 59'
          transform='translate(5.641 5.33)'
        >
          <path
            d='M9.738 23.018a9.421 9.421 0 0112.394 0'
            data-name='Trazado 17'
          />
          <circle
            cx={16}
            cy={16}
            r={16}
            data-name='Elipse 11'
            transform='translate(.359 -.329)'
          />
          <path
            d='M7.082 12.394a3.443 3.443 0 012.656-1.346 3.484 3.484 0 012.656 1.346'
            data-name='Trazado 18'
          />
          <path
            d='M19.476 12.394a3.443 3.443 0 012.656-1.346 3.485 3.485 0 012.656 1.346'
            data-name='Trazado 19'
          />
        </g>
      </g>
    </svg>
  );
};
