import { useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@mui/material';
import { SuggestionsForm } from '../forms';

const keyPrefix = 'pages.SuggestionsPage';

export const SuggestionsPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Container maxWidth='lg'>
      <Box padding='32px 105px'>
        <Typography variant='h4' my={2}>
          {t('title')}
        </Typography>
        <Box display='flex' justifyContent='center'>
          <Box width='582px'>
            <SuggestionsForm />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
