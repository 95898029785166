import React from 'react';

export const Face6Svg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' {...props}>
      <g fill='none'>
        <path d='M0 0h43v43H0z' data-name='Rect\xE1ngulo 33' />
        <g
          stroke='#1e878c'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
          data-name='Grupo 48'
        >
          <g data-name='Grupo 47'>
            <path
              d='M37.598 21.265a15.935 15.935 0 00-31.87 0'
              data-name='Trazado 5'
            />
            <path
              d='M21.663 37.201a15.954 15.954 0 0015.935-15.936'
              data-name='Trazado 6'
            />
            <path
              d='M5.728 21.265a15.953 15.953 0 0015.935 15.936'
              data-name='Trazado 7'
            />
          </g>
          <path
            d='M27.556 16.873a.442.442 0 10.444.442.441.441 0 00-.444-.442m-11.843-.034a.442.442 0 10.444.442.44.44 0 00-.444-.442'
            data-name='Shape 3077'
          />
          <path
            d='M15.466 28.347a9.423 9.423 0 0112.394 0'
            data-name='Trazado 8'
          />
        </g>
      </g>
    </svg>
  );
};
