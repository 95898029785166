import { useTranslation } from 'react-i18next';

import { SignUpForm } from '../forms';
import { InitialPagesLayout } from '../layouts/InitialPagesLayout';

const keyPrefix = 'pages.SignUpPage';

export const SignUpPage = () => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <InitialPagesLayout
      title={t('title')}
      instructions={t('instructions')}
      centerVertically={false}
    >
      <SignUpForm />
    </InitialPagesLayout>
  );
};
