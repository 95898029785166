import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { Content } from '../../types';
import { getImageCoverURL, parseValidity } from './Util';
import { ContentItemCallToAction } from './ContentItemCallToAction';
import { MediaType } from '../../enums';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeActiveContent,
  selectActivePage,
  selectNewContent,
  selectSession,
  useReportContentWatchedMutation,
} from '../../redux';
import { IconNewSvg } from '../../assets';

const keyPrefix = 'components.content.ContentItem';

export const ContentItem: FC<Content> = (content): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const { activePage } = useSelector(selectActivePage);
  const newContentIds = useSelector(selectNewContent);
  const [reportContentWatched] = useReportContentWatchedMutation();

  const {
    effectiveEndDate,
    media,
    mediaType,
    effectiveStartDate,
    name,
    details,
  } = content;

  const goToDetail = (): void => {
    reportContentWatched({ session, contentId: content.id });
    dispatch(
      changeActiveContent({
        activePage: mediaType === MediaType.VIDEO ? 'video' : 'magazine',
        selectedContent: content,
        prevPage: activePage
      })
    );
  };

  const isNew = newContentIds.includes(content.id.toString());

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        position: 'relative',
      }}
    >
      {isNew && (
        <IconNewSvg
          style={{ position: 'absolute', width: '100px', right: 0 }}
        />
      )}

      <CardMedia
        component='img'
        image={getImageCoverURL(mediaType, media)}
        sx={{ borderRadius: 0, cursor: 'pointer' }}
        height={303}
        // height='auto'
        onClick={goToDetail}
      />
      <CardContent sx={{ padding: '16px 10px' }}>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <ContentItemCallToAction {...content} />
          </Grid>
          <Grid item md={10}>
            <Stack spacing={1}>
              <Typography
                variant='subtitle1'
                fontWeight='bold'
                style={{ fontSize: '1.1rem' }}
              >
                {name}
              </Typography>
              <Typography
                variant='subtitle2'
                style={{ lineHeight: '1.1' }}
                fontWeight={300}
              >
                {details[0].description}
              </Typography>
              <Typography variant='body2'>
                {parseValidity(
                  effectiveStartDate,
                  effectiveEndDate,
                  t('validityExpression')
                )}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
