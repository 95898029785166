import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Content, MenuItem } from '../../types';
import { authApi } from '../services';
import { RootState } from '../store';

export interface NavigationState {
  activePage: string;
  // activeSection?: string;
  selectedMenuItem?: MenuItem;
  selectedContent?: Content;
  searchingContent?: string | null;
  currentIndexImage?: number;
  prevPage?: string
}

const initialState: NavigationState = { activePage: 'home', currentIndexImage: 0 };

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    changeActiveMenuItem: (state, action: PayloadAction<NavigationState>) => {
      const { activePage: name, selectedMenuItem: item } = action.payload;
      state.activePage = name;
      state.selectedMenuItem = item;
    },
    changeActiveContent: (state, action: PayloadAction<NavigationState>) => {
      const { activePage: name, selectedContent: content, prevPage: prev } = action.payload;
      state.activePage = name;
      state.selectedContent = content;
      state.prevPage = prev;
      state.searchingContent = null;
    },
    changeActiveMenuContent: (
      state,
      action: PayloadAction<NavigationState>
    ) => {
      const {
        activePage: name,
        selectedMenuItem: item,
        selectedContent: content,
        prevPage: prev
      } = action.payload;
      state.activePage = name;
      state.selectedMenuItem = item;
      state.selectedContent = content;
      state.prevPage = prev;
    },
    changeActivePage: (state, action: PayloadAction<NavigationState>) => {
      const { activePage: name } = action.payload;
      state.activePage = name;
    },
    searchContent: (state, action: PayloadAction<string>) => {
      state.searchingContent = action.payload.trim();
    },
    changeCurrentIndexImage: (state, action: PayloadAction<number>) => {
      console.log("CAMBIA EL INDEX A", action.payload);
      state.currentIndexImage = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, state => {
      state.activePage = 'home';
    });
  },
});

export const {
  changeActiveMenuItem,
  changeActiveContent,
  changeActiveMenuContent,
  changeActivePage,
  searchContent,
  changeCurrentIndexImage
} = menuSlice.actions;

export const menuReducer = menuSlice.reducer;

export const selectActivePage = (state: RootState) => state.menu;
